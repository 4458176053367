import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Info from "../../../assets/userScreenSvg/info.svg";
import "./index.css";

const CustomInput = (props) => {
  const {
    label,
    onChange,
    placeholder,
    type,
    labelClass,
    className,
    defaultValue,
    disabled
 } = props
  return (
    <div>
      <label className={labelClass ? labelClass : "input-label"}>
        {label}
      </label>
      <input
        defaultValue={defaultValue ? defaultValue : ""}
        onChange={onChange}
        className={className ? className `input-field` : "input-field"}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

export default CustomInput;
