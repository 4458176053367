import { React, useEffect, useMemo, useState } from "react";
import { commonInputs, getMeasurements, NodeHeader, Plus, StartNode, VariablePick } from "../../Common";
import { generateId } from "../../../../../utils/store";
import {
  Handle,
  Position,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
import Icons from '../../../../../assets/imageUrl';
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader"
import { useNodeData } from "../../../../store/selector";
import {AddButton,DeleteButton} from '../../../../CommonButtons';
import * as Yup from "yup";


const operators = ["and", "or"];

const options = [
  "Equal",
  "NotEqual",
  "GreaterThan",
  "LessThan",
  "GreaterThanorEqual",
  "LessThanorEqual",
];

const SymbolMap = {
  Equal: "=",
  NotEqual: "!=",
  GreaterThan: ">",
  LessThan: "<",
  GreaterThanorEqual: ">=",
  LessThanorEqual: "=<",
};

export const inputs = [
  ...commonInputs,
  {
    name: "Conditions",
    value: "conditions",
    defaultValue: [],
    type: "listItems",
    props:{
      deleteButton: true,
      children: [
        {
          name: "Varaible",
          value: "variable",
          defaultValue: "",
          type: "textInput",
          helpingText:"helping Text for condition node",


        },
        {
          name: "Operator",
          value: "condition",
          defaultValue: "Equal",
          type: "select",

          props:{
            options: Object.keys(SymbolMap).map(key => ({
              name: key,
              value: key
            }))
          }
        },
        {
          name: "Value",
          value: "value",
          defaultValue: "",
          type: "textInput",
        },
      ]
    }
  },
  {
    name: "Operators",
    value: "operator",
    defaultValue: operators[0],
    type: "select",
    style: {
      "marginTop": "10px"
    },
    helpingText:'Operator helping text',
    props:{
      options: [
        {
          name: "and",
          value: "and"
        },
        {
          name:"or",
          value: "or"
        }
      ]
    }
  },
]

export const ConditionPanel = ({ id,  type = "Condition", vars = [] }) => {
  const [data, setData] = useNodeData(id);
  const conditions = data.conditions || [];
  const setConditions = (conditions) => {
    setData({ ...data, conditions: conditions });
  };

  if (typeof conditions === "undefined") {
    setConditions([]);
  }
function deleteConditions(condition){
  setConditions(conditions.filter((c) => condition.id !== c.id))
}
  const conditionOptions = conditions.map((condition,i) => {
    return (
      <li >
      <div className="commonDeleteSection">
       <label >

         </label>
      <DeleteButton callFunction={deleteConditions} comId={condition}/>
       </div>

         <div>
          <label className="nodePanel-labels mt-3">Value 1</label>
         <VariablePick input={condition.variable} setInput={(value) => {
            setConditions(
              conditions.map((cond) => {
                if (cond.id === condition.id) {
                  return {
                    ...cond,
                    variable: value,
                  };
                }
                return cond;
              })
            );
          }} vars={vars} />
         </div>


        <div>
        <label className="nodePanel-labels mt-3">Operator</label>
        <div className="labelInputFull">
        <select

onChange={(e) =>
  setConditions(
    conditions.map((cond) => {
      if (cond.id === condition.id) {
        return {
          ...cond,
          condition: options[e.target.selectedIndex - 1],
        };
      }
      return cond;
    })
  )
}>
<option>Select Operator</option>
{options.map((option) =>{
return (
  <option selected={option === condition.condition}>
    {SymbolMap[option]}
  </option>
)
} )}
</select>
        </div>
        </div>
          <div>
          <label className="nodePanel-labels mt-3">Value 2</label>
            <VariablePick input={condition.value} setInput={(value) => {
              setConditions(
                conditions.map((cond) => {
                  if (cond.id === condition.id) {
                    return {
                      ...cond,
                      value: value,
                    };
                  }
                  return cond;
                })
              );
            }} vars={vars} />
          </div>
        <div >
        </div>
      </li>
    );
  });
function callAddCondition(){
  setConditions([
    ...conditions,
    { id: generateId(), value: "", variable: "" },
  ])
}
  return (

    <div className="right_panel_workflow">
      <SidePanelHeader nodeName="Condition" />
      <section className="sideInnerParent">
      <div>
        <label className="nodePanel-labels">{type + " Name"}</label>
<div className="labelInputFull">
<input
          type="text"
          value={data?.name || ""}
          maxLength={15}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          placeholder={type + " Name"}
        />
</div>
      </div>
          <div >
            {
              conditions && conditions.length >=1 ?

              <ul className="commonBackGray">{conditionOptions}</ul>
                :<></>
            }
                <div className="addConditions">

              <AddButton
                callFunction={callAddCondition}
                opName='Add Conditions'
                />
            </div>
          </div>
          {conditions && conditions.length >1 && (
<div>
  <div>
    <label htmlFor="url" className="nodePanel-labels">
      Operator
    </label>
  </div>

  <div className="labelInputFull">
    <select
      onChange={(e) =>
        setData({ ...data, operator: e.target.value })
      }>
      <option>Select Operator</option>
      {operators.map((operator) => (
        <option selected={operator === data.operator}>
          {operator}
        </option>
      ))}
    </select>
  </div>
</div>
)}
      </section>
    </div>
  );
};

const Condition = ({ id, data, selected }) => {
  const store = useStoreApi();
  const updateNodeInternals = useUpdateNodeInternals();
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  updateNodeInternals(id);

  const [, height] = getMeasurements(id, store);

  return (
    <>
      <div
        style={{ backgroundColor: "#ffecec", border: selected ? `solid 2px${"#FF9900"}` : "" }}
        className={
          "set_msgcard " +
          // (data.isStartNode ? "start_node " : "") +
          // (!data.isStartNode && selected ? "selected_node " : "") +
          "commonHeader"
        }>

        <NodeHeader
          name={"Condition"}
          icon={Icons.conditionNodeLogo}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        />
        <div className="conditionNodeStyle">
          <div >
            <p>
              Yes
            </p>
            <Handle
              id="yes"
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
          <div >
            <p>
              No
            </p>
            <Handle
              id="no"
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>

      </div>

    </>
  );
};

export default Condition;
