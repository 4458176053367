import React from "react";
import { redirect, useNavigate } from 'react-router-dom';
import Icons, {
  customCodeIcon,
  sidePanelAPICall,
  sidePanelAWSBucket,
  sidePanelButton,
  sidePanelChat,
  sidePanelCondition,
  sidePanelConversion,
  sidePanelEmail,
  sidePanelList,
  sidePanelMatrix,
  sidePanelMessage,
  sidePanelNotification,
  sidePanelPage,
  sidePanelQuestion,
  sidePanelSMS,
  sidePanelTimeDelay,
  sidePanelTimeRandom,
  sidePanelUpdate,
  sidePanelWebhook
} from "../assets/imageUrl";

export const commonNodes = [
  {
    name: "Condition",
    imageLink: sidePanelCondition,
    type: "Condition"
  },
  {
    name: "API Call",
    imageLink: sidePanelAPICall,
    type: "APICall"
  },
  {
    name: "Update",
    imageLink: sidePanelUpdate,
    type: "UpdateVariable"
  },
  // {
  //   name: "Zapier",
  //   imageLink:sidePanelZapier,
  //   type: "Trigger"
  // },
  {
    name: "Conversion",
    imageLink: sidePanelConversion,
    type: "Conversion"
  },
  {
    name: "Matrix",
    imageLink: sidePanelMatrix,
    type: "Matrix"
  },
  {
    name: "AWS S3",
    imageLink: sidePanelAWSBucket,
    type: "AwsS3",
  },
  {
    name: "Random",
    imageLink: sidePanelTimeRandom,
    type: "Random",
  },
  {
    name: "Time Delay",
    imageLink: sidePanelTimeDelay,
    type: "TimeDelay",
  },
  {
    name: "Switch Node",
    imageLink: sidePanelTimeDelay,
    type: "SwitchNode",
  },
  {
    name: "Custom Code",
    imageLink: customCodeIcon,
    type: "Code",
  },
  // {
  //   name: "Create Node",
  //   imageLink: customCodeIcon,
  //   type: "CreateNode",
  // }
];

export const communicationNodes = [
  {
    name: "Chat",
    imageLink: sidePanelChat,
    type: "Novu",
    subType: "Chat"
  },
  {
    name: "Email",
    imageLink: sidePanelEmail,
    type: "Novu",
    subType: "Email"
  },
  {
    name: "Notification",
    imageLink: sidePanelNotification,
    type: "Novu",
    subType: "Notification"
  },
  {
    name: "SMS",
    imageLink: sidePanelSMS,
    type: "Novu",
    subType: "SMS"
  }
];

const webNodes = [
  {
    name: "Page",
    imageLink: sidePanelPage,
    type: "Page"
  }
];

const apiNodes = [
  {
    name: "Webhook",
    imageLink: sidePanelWebhook,
    type: "Webhook"
  },
  {
    name: "Scheduler",
    imageLink: sidePanelAPICall,
    type: "Scheduler"
  }
];

const whatsappNodes = [
  {
    name: "Message",
    // imageLink: "bx bx-message-rounded",
    imageLink: sidePanelMessage,
    type: "Message"
  },
  {
    name: "Question",
    // imageLink: "bx bx-question-mark",
    imageLink: sidePanelQuestion,
    type: "Question"
  },
  {
    name: "Buttons",
    // imageLink: "bx bx-joystick-button",
    imageLink: sidePanelButton,
    type: "InteractiveButtons"
  },
  {
    name: "List",
    // imageLink: "bx bx-list-ul",
    imageLink: sidePanelList,
    type: "InteractiveList"
  }
];

export const Node = ({ node }) => {

  const navigate = useNavigate();

  const handleRedirectNode = () => { 
    return navigate("create-node");
   }

    // if (node.type == "CreateNode") {
    //   return (
    //     <div className="col-lg-6 col-sm-6 p-0 cursor-pointer"
    //       draggable={false}
    //       onClick={handleRedirectNode}>
    //       <div className="node-start node-border">
    //         <img src={node?.imageLink || Icons?.backendApi} className="node-img" alt="Node" />
    //         <p className="node-name mb-2">{node?.name}</p>
    //       </div>
    //     </div>
    //   )
    // } else {
        return (
        <div className="col-lg-6 col-sm-6 p-0"
        draggable={true}
        onDragStart={(event) => {
          event.dataTransfer.setData("type", node.type);
          if (node.subType) {
            event.dataTransfer.setData("subType", node.subType);
          }
          if (node.id) {
            event.dataTransfer.setData("nodeId", node.id);
          }
          event.dataTransfer.effectAllowed = "move";
        }}>
        <div className="node-start node-border"  onClick={() => redirect("/home/nodes/create")}>
          <img src={node?.imageLink || Icons?.backendApi} className="node-img" alt="Node" />
          <p className="node-name mb-2">{node?.name}</p>
        </div>
        </div>
      );
    // }
};


export const defaultNodeItems = type => type === "api" ?
  apiNodes : type === "web" ? webNodes : whatsappNodes;


