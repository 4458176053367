import React from 'react'
import Icons from '../assets/imageUrl'
import { deleteModelImg } from "../assets/imageUrl";
export const AddButton = ({opName,callFunction}) => {
  return (
    <button onClick={callFunction} className='addButtonNode'>
<img src={Icons.plusCircle} alt="PlusCircle" />
<p>{opName}</p>
    </button>
  )

}

export const DeleteButton = ({callFunction,comId}) => {
  return  <img className='deleteGrayPanel' onClick={()=>{
    callFunction(comId)
  }} src={deleteModelImg} alt="img"/>
}



