import { useEffect, useState } from "react";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
    PageContext, PageUpdateContext, useZustandPage as usePage
} from "components";

// This exports helps to `register components
// If you want to add new component
// add an export from that module
// import {Link, ButtonNode, Container, ImageNode, InputNode, FormNode, CheckBoxNode, RadioNode, OtpNode, LogoNode,
// DropDown, DividerNode, MainContainer, TimerButtonNode, FileUploadNode, CameraUploadNode, ImageCropNode} from "components";


import { useParams } from "react-router-dom";

import useFlow from "../WorkFlowBuilder/store/flowStore";


const PageType = "Page"
const useCurrentPage = () => {
    const params = useParams();
    const pageId = params.pageId;
    const nodes = useFlow(flow => flow.nodes);
    const setPage = usePage(page => page.setPage);
    const setToDefault = usePage(page => page.setToDefault);

    useEffect(() => {
        if (pageId) {
            const node = nodes.find(node => node.id === pageId)
            if (node) {
                setPage(node.data);
            } else {
                setToDefault();
            }
        } else {
            setToDefault()
        }
    }, [pageId, nodes])

    return pageId
}


export const Editor = ({ children }) => {
    const pageId = useCurrentPage();
    const page = usePage(page => page);
    const setPage = usePage(page => page.setPage)
    const [activeComponent, setActiveComponent] = useState(undefined);

    return <>
        <DndProvider backend={HTML5Backend}>
            <PageContext.Provider value={{ page, activeComponent, mode: "editor" }}>
                <PageUpdateContext.Provider value={{ setPage, setActiveComponent }}>
                    {children}
                </PageUpdateContext.Provider>
            </PageContext.Provider>
        </DndProvider>
    </>
}