import React, { useEffect, useMemo, useRef, useState } from "react";

import { Alignment, emptyProps, FullContainer, Node, Style } from "../common";
import { registerComponent } from "../components";
import { useForm, useIsEditor } from "../../../src/stores/context";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { useFonts, useTheme } from "../../../src/stores/selectors";
import { nameInput } from "./input";
import * as Yup from "yup";
import { removeBasePath } from "../../../src/stores/util";

const type = "FileUpload";

export const inputs = [
  nameInput,
  {
    name: "Label",
    value: "label",
    type: "textInput",
    requried: true,
    autoFocus: true,
    placeHolder: "Enter ...!"
  },
  {
    name: "Label Color",
    value: "textColor",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Text",
    value: "text",
    type: "textInput",
    required: true,
    autoFocus: true,
    placeHolder: "Button Name",
    defaultValue: "Click Me"
  },
  {
    name: "Background Color",
    value: "bgcolor",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Color",
    value: "color",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Width",
    value: "width",
    type: "progressInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Height",
    value: "height",
    type: "progressInput",
    required: true,
  },
  {
    name: "Border Radius",
    value: "borderRad",
    type: "progressInput",
    required: true,
    defaultValue: 0
  },
  {
    name: "Horizonal Padding",
    value: "hPadding",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Vertical Padding",
    value: "vPadding",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  FullContainer,
  Alignment,
  Style,
  {
    name: "Required",
    value: "required",
    type: "toggle",
    defaultValue: true
  },
];

export const FileUpload = {
  name: "FileUpload",
  type,
  props: emptyProps
};

export const FileUploadNode = () => {
  return (
    <Node type={type}>
      <div className="page-side-node br-right">
        <img src={PageBuilderAssets?.fileUploadIcon} className="node-img" />
        <p className="node-name mb-2">File Upload</p>
      </div>
    </Node>
  );
};

export const FileUploadPlay = ({
  id,
  name = "file",
  label = "Upload",
  textColor = "",
  style = "subtitle2",
  hPadding = "16",
  vPadding = "10",
  alignment = "center",
  bgcolor = "",
  color = "",
  width = "100",
  height = 40,
  borderRad = "4px",
  required=true,

}) => {
  const editor = useIsEditor();
  const fonts = useFonts();
  const { formik, isForm, registerValidator } = useForm();
  const font = useMemo(() => fonts[style], [fonts, style]);
  const [file, setFile] = useState(undefined)

  const theme = useTheme();
  const defaultColor = theme === "dark" ? "#f5f5f5" : "#212427";
  const uploadtextColor = theme === "dark" ? "#212427" : "#f5f5f5";
  const finalLabelColor = textColor || defaultColor;
  const defaultBgColor = theme === "dark" ? "#f5f5f5" : "#12201e";
  const finalColor = color || uploadtextColor;
  const finalBgcolor = bgcolor || defaultBgColor;
  const fileInputRef = useRef(null);

  const onUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!editor && isForm) {
      fileInputRef.current.click();
    }
  };




  return (
    <>
      <div
        className="PBmainheadform"
      >
        {/* uploaded file */}
        {file ? <div style={{
          height: '48px',
          background: '#fff',
          textAlign: 'end',
          position: "relative"
        }}><p className="IdflowFileName">{file}</p>
          <button className="upload-button"
            disabled={editor}
            style={{
              fontWeight: 400,
              // margin: "10px auto",
              color: finalColor,
              background: finalBgcolor,
              borderRadius: '8px',
              border: "none",
              width: '20%',
              height: '48px',
              padding: `${vPadding}px ${hPadding}px`,
              fontSize: font.size,
              position: "relative"

            }} onClick={onUpload}>
            <img src="" alt="uploadIcon" onClick={onUpload} /> <label ></label>
          </button>


        </div> : <>
          <label htmlFor="name" className="ml-1"
            style={{
              fontSize: font.size,
              fontWeight: font.weight,
              fontFamily: font.family,
              color: finalLabelColor,
              paddingBottom: "5px",
              float: "left"
            }}
          >
            {label}
          </label>
          <div>
            <button className="upload-button"
              disabled={editor}
              style={{
                fontWeight: 400,
                margin: "10px auto",
                color: finalColor,
                background: finalBgcolor,
                borderRadius: borderRad,
                border: "none",
                width: `${width}%`,
                height: `${height}px`,
                padding: `${vPadding}px ${hPadding}px`,
                fontSize: font.size,
                position: "relative"

              }} onClick={onUpload}>
              <img alt="uploadIcon" onClick={onUpload} /> <label style={{}}>Upload</label>
            </button>
            <input
              type="file"
              ref={fileInputRef}
              disabled={editor}
              onChange={(e) => {
                if (editor) {
                  return;
                }
                const file = fileInputRef.current.files[0];

                if (file) {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    const base64String = event.target.result;
                    if (isForm) {
                      setFile(file.name)
                      formik.setFieldValue(name, removeBasePath(base64String));
                    }
                  };
                  reader.readAsDataURL(file);
                }
              }}
              style={{ display: "none" }}
            />
          </div></>}
        </div>
    </>
  );
};

registerComponent(type, {
  node: FileUploadNode,
  play: FileUploadPlay,
  item: FileUpload,
  inputs,
  isInput: true,
  category: "Images"
});