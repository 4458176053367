import plusIcon from "../../../../../assets/plus-icon-black.svg";
import optionIcon from "../../../../../assets/versionOptionsIcon.svg";
import restoreIcon from "../../../../../assets/restore-icon.svg";
import Icons from "../../../../../assets/imageUrl";
import publishIcon from "../../../../../assets/publish-icon.svg";
import {useState} from "react";


const Version =()=>{

    const [showVersionMenu, setShowVersionMenu] = useState(false);
    return(
        <>
            <h5 className="idflow_np_versions_head mb-0">Version History</h5>
            <div className="idflow_np_version_add">
                <p className="mb-0">Current Version</p>
                <img src={plusIcon} alt="plus-icon" />
            </div>
            <div className="idflow_np_versions_main">
                <div className="idflow_np_version_step">
                    <div className="idflow_np_v_stepper">
                        <div className="idflow_np_version_circle"></div>
                        <div className="idflow_np_version_line"></div>
                    </div>
                    <div className="idflow_np_versions">
                        <h5 className="idflow_np_current_version">Current Version</h5>
                        <section className="idflow_np_version_main">
                            <div className={showVersionMenu ? "idflow_np_version active" : "idflow_np_version"}>
                                <div>
                                    <h5>Version 88</h5>
                                    <p>Thrusday 12:00:00, by RonaldoJr</p>
                                </div>
                                <img src={optionIcon} alt="versionIcon" className="idflow_np_version_optionIcon" onClick={() => setShowVersionMenu(!showVersionMenu)} style={{opacity: showVersionMenu ? "1" : "0"}}/>
                            </div>
                            {
                                showVersionMenu &&
                                <>
                                    <div className="idflow_np_versionPopup">
                                        <div className="cursorPointer">
                                            <img src={restoreIcon} alt="restore" />&nbsp;&nbsp;<p>Restore Version</p>
                                        </div>
                                        <div className="cursorPointer">
                                            <img src={Icons?.deleteModelImg} alt="delete" />&nbsp;&nbsp;<p>Delete Version</p>
                                        </div>
                                        <div className="cursorPointer">
                                            <img src={publishIcon} alt="publish" />&nbsp;&nbsp;<p>Publish Version</p>
                                        </div>
                                    </div>
                                </>
                            }

                        </section>
                    </div>
                </div>
                <div className="idflow_np_version_step mt-0">
                    <div className="idflow_np_v_stepper">
                        <div className="idflow_np_version_circle"></div>
                        <div className="idflow_np_version_line"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Version;