import Icons from "../../assets/imageUrl";


export const Select = props => {

    let {
        name = "Select",
        value = "",
        helpingText = "helpingText",
        style = {},
        setValue = () => { },
        options
    } = props;

    return <>
        <div className="RightLabel" style={style}>
            <div className="nodePanel-labels" >{name} </div>
            <div>
                <div className="RightLabelImg">
                    <img src={Icons.InfoIcon} alt="IdflowInfoIcon" />
                    {/*{!!required && <span className="alertRequired">required *</span>}*/}
                    <div className="RightTooltip ">
                        <div className="RightSubtool">
                            <p>{helpingText}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div className="labelInputFull">
            <select
                disabled={props.disabled}
                className="nodePanel-inputs"
                style={options.style}
                onChange={(e) =>
                    setValue(
                        options[e.target.selectedIndex - 1].value
                    )
                }>

                <option disabled>Select {name}</option>
                {options.map((option) => <>
                    <option selected={option.value === value} value={option.value}>
                        {option.name}
                    </option>
                </>)}
            </select>
        </div>
    </>
}