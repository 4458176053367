import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import PlusIcon from '../../assets/crossPlusBlack.svg';
import TrashIcon from '../../assets/trash.svg';
import CustomSelect from '../CustomSelect';
import { generateId, removeSpaceFromObj } from "../utils";
const { XMLParser, XMLBuilder, XMLValidator } = require("fast-xml-parser");
import RawRadioButton from './RawRadioBtn';

import HeadDescription from "./CreateNodes/details/headDescription";


export const RenderRadioButton = ({type, label, radioBtnName, handleRadioBtn, setRadioToggle, reqRadioBtn, rawDDValue, setRawDDValue, radioToggle}) => {
  const rawOptions = [
    { value: "xml", label: "XML" },
    { value: "json", label: "JSON" },
  ];

  useEffect(() => {
    if (radioToggle) {
      setRawDDValue(reqRadioBtn)
      setRadioToggle(true)
    }
  }, [radioToggle]) 

  const handleChangeMethod = (value) => { 
    setRawDDValue(value);
  }

  return (
    <button
      type="button"
      onClick={() => {
        handleRadioBtn(type)
        if (type !== "raw") {
          setRadioToggle(false)
        } else {
          setRadioToggle(true)
        }
      }}
      className={`reqbody--header reqbody__btn cursor-pointer ${reqRadioBtn === type ? "btn--white" : ""}`}
    >
      <input
        type="radio"
        checked={reqRadioBtn === type}
        className="btn--checkbox"
        name={radioBtnName}
        id={type + radioBtnName}
      />
      <label className="cursor-pointer" htmlFor={type+radioBtnName}>{label}</label>
      {(reqRadioBtn == "raw" && type == "raw") && (
          <CustomSelect
            name="method"
            isSearchable={false}
            placeholder="Select"
            options={rawOptions}
            defaultValue={rawOptions.find((item) => item.value === rawDDValue)}
            value={rawOptions.find((item) => item.value === rawDDValue)}
            onChange={(e) => handleChangeMethod(e.value)}
          />
        )}
    </button>
  );
}

const RequestBodyBox = ({ name, formikValue, formikName, onChange, errorBody, formik, uniqueKey, parentTOchild }) => {
  const [getFormData, setFormData] = useState([]);
  const [reqRadioBtn, setReqRadioBtn] = useState('formdata');
  const [rawDDValue, setRawDDValue] = useState('xml');
  const [radioToggle, setRadioToggle] = useState(false);
  const [xmlError, setXmlError] = useState({});

  const ref = useRef(null);
  const xmlRef = useRef(null);
 

  useEffect(() => {
    setFormData([{ id: generateId(6), key: "", value: "", description: "" }])
    formik.setFieldValue(formikName, "formdata")
  }, []);

  useEffect(() => {
    if (rawDDValue === 'json') {
      ref.current?.jsonEditor.set(formikValue);
    }
  }, [formikValue, rawDDValue]);

  useEffect(() => {
    onChange(getFormData);
  }, [getFormData]);

  const handleRadioBtn = useCallback((value) => {
    formik.setFieldValue(formikName, value);
    setReqRadioBtn(value);
  }, [formik, formikName]);

  const handleChangeMethod = (value) => {
    setRawDDValue(value);
  };

  useEffect(() => {
    if (reqRadioBtn == 'formdata' || reqRadioBtn == "urlencoded") {
      setFormData([{ id: generateId(6), key: "", value: "", description: "" }])
      // setRawDDValue('xml')
    } else {
      setFormData({})
    }
  }, [reqRadioBtn, rawDDValue]);

  useEffect(() => {
    formik.setFieldValue(formikName, rawDDValue);
  }, [rawDDValue]);

  const handletableInput = (event, objIndex) => {
    let { name, value } = event.target;
    let updatedData = formikValue?.map((item, index) =>
      index === objIndex ? { ...item, [name]: value } : item
    );
    setFormData(updatedData);
    onChange(updatedData);
  };

  const handleDelete = (rowId) => {
    let finalData = formikValue?.filter((item) => item.id !== rowId);
    setFormData(finalData);
    onChange(finalData);
  };

  const handleRaw = (value) => {
    handleRadioBtn("");
    formik.setFieldValue(formikName, "xml");
  };

  useEffect(() => {
    if (radioToggle) {
      setRawDDValue('xml')
    }
  }, [radioToggle]);

  return (
    <div className="request_body_box config_box">
      <HeadDescription
        head="Request Body"
        des="Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien scelerisque ac."
      />

      <div className="reqbody--tab-header">
        <RenderRadioButton
        radioToggle={radioToggle} 
          type="formdata"
          label="Form-data"
          radioBtnName={uniqueKey}
          setRadioToggle={setRadioToggle}
          handleRadioBtn={handleRadioBtn}
          reqRadioBtn={reqRadioBtn}
          setRawDDValue={setRawDDValue}

        />
        {/* {renderRawButton()} */}
        <RenderRadioButton
        radioToggle={radioToggle} 
          type="raw"
          label="Raw"
          radioBtnName={uniqueKey}
          handleRadioBtn={handleRadioBtn}
          setRadioToggle={setRadioToggle}
          reqRadioBtn={reqRadioBtn}
          setRawDDValue={setRawDDValue}
          rawDDValue={rawDDValue}
          // handleChangeMethod={handleChangeMethod}

        />
        <RenderRadioButton
        radioToggle={radioToggle} 
          type="urlencoded"
          label="x-www-form-urlencoded"
          radioBtnName={uniqueKey}
          handleRadioBtn={handleRadioBtn}
          setRadioToggle={setRadioToggle}
          reqRadioBtn={reqRadioBtn}
          setRawDDValue={setRawDDValue}

        />
      </div>

      {radioToggle && rawDDValue === 'xml' && (
        <div className="json-editor">
          {/* <XmlEditor
            docSpec={xmlDocSpec}
            ref={xmlRef}
            xml={xmlState}
            onChange={() => {
              const builder = new Builder({});
              const xmlData = xmlRef?.current?.getXml();
              if (xmlData) {
                onChange([{ data: builder?.buildObject(xmlData) }]);
              }
            }}
          /> */}

          <textarea
            cols={70}
            rows={6}
            style={{ border: "none", width: "99%" }}
            // value={parser.parse(formik.values?.body[0]?.data)}
            value={formikValue}
            onChange={(e) => {
              try {
                const result = XMLValidator.validate(e.target.value, {
                  allowBooleanAttributes: true,
                });
                if (typeof (result) === "boolean") {
                  setXmlError("")
                }
                if (Object.keys(result?.err).length > 0 && result?.err !== undefined) {
                  setXmlError(result.err)
                  parentTOchild(result.err)
                } 
                if (result) {
                  onChange([{ data: e.target.value }])
                }
              } catch (error) {
                onChange([{ data: e.target.value }])
              }

            }} />
          {Object.keys(xmlError)?.length > 0 ? <p className="formik_error">{JSON.stringify(xmlError)}</p> : null}
        </div>
      )}

      {radioToggle && rawDDValue === 'json' && (
        <>
          <div className="json-editor">
            <JsonEditor
              ref={ref}
              name={name}
              mode="text"
              value={removeSpaceFromObj(formikValue) || {}}
              onChange={onChange}
            />
          </div>
          {errorBody ? <p className="formik_error">{errorBody}</p> : null}
        </>
      )}

      {reqRadioBtn === 'urlencoded' && renderTable('urlencoded')}
      {reqRadioBtn === 'formdata' && renderTable('formdata')}
    </div>
  );


  function renderTable(type) {
    return (
      <div className="table--node-nodepnl">
        <div className="table__category-nodepnl">
          <table className="table__list">
            <tr>
              <th width="30%" className="table__data">
                key
              </th>
              <th width="30%" className="table__data">
                value
              </th>
              <th className="table__data">Description</th>
            </tr>

            {Array.isArray(formikValue) &&
              formikValue?.map((item, index) => (
                <tr key={index}>
                  <td width="30%" className="table__data">
                    <input
                      className="table__data-input"
                      onChange={(e) => handletableInput(e, index)}
                      value={formikValue[index].key}
                      type="text"
                      name="key"
                    />
                  </td>
                  <td width="30%" className="table__data">
                    <input
                      className="table__data-input"
                      onChange={(e) => handletableInput(e, index)}
                      value={formikValue[index].value}
                      type="text"
                      name="value"
                    />
                  </td>
                  <td width="30%" className="table__data">
                    <input
                      className="table__data-input"
                      onChange={(e) => handletableInput(e, index)}
                      value={formikValue[index].description}
                      type="text"
                      name="description"
                    />
                  </td>
                  <td
                    width="10%"
                    className="table__data text-center cursor-pointer"
                    onClick={() => handleDelete(item.id)}
                  >
                    <img src={TrashIcon} alt="" />
                  </td>
                </tr>
              ))}
          </table>
        </div>
        <button
          className="idflow-btn-outlined mt-2"
          onClick={() =>
            setFormData([
              ...getFormData,
              { id: generateId(6), key: "", value: "", description: "" },
            ])
          }
        >
          <img src={PlusIcon} alt="copied-icon" />
          Add Data
        </button>
      </div>
    );
  }
};

export default memo(RequestBodyBox);
