import { createContext, useContext } from "react";

export const NodePanelContext = createContext({});


export const useVars = () => {
    const {vars} = useContext(NodePanelContext);
    return vars
}


export const useCommonVars = () => {
    const {common} = useVars();
    return common
}

export const useUniqueVars = () =>{
    const {unique} = useVars();
    return unique
}