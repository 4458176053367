import React, { useState } from "react";
import "./pagebuilder.css";
import { Editor } from "./editor";
import { LeftPanel } from "./left";
import Ground from "./ground";
import { RightPanel } from "./right";
import { useFullScreen } from "../WorkFlowBuilder/hooks";

export const Inputs = () => {
  return <></>;
}

export const PageBuilder = () => {
  useFullScreen(false)

  // data of left bar
  return (
    <>
      <Editor>
        <main className="main_edit">
          <section className="d-flex">
            {/* for options to drag and drop */}
            <LeftPanel />

            {/* main page ground grid layout */}
            <Ground />

            {/* right panel appear here */}

            <RightPanel />
          </section>
        </main>
      </Editor>
    </>
  );
};


