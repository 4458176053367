import React from "react";

export default function EditIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3939 2.26348C13.1704 2.26348 12.9561 2.35225 12.7981 2.51025L7.03326 8.2751L6.63611 9.86373L8.22474 9.46657L13.9896 3.70172C14.1476 3.54372 14.2364 3.32943 14.2364 3.10598C14.2364 2.88254 14.1476 2.66825 13.9896 2.51025C13.8316 2.35225 13.6173 2.26348 13.3939 2.26348ZM12.1403 1.85247C12.4728 1.52002 12.9237 1.33325 13.3939 1.33325C13.864 1.33325 14.3149 1.52002 14.6474 1.85247C14.9798 2.18492 15.1666 2.63582 15.1666 3.10598C15.1666 3.57614 14.9798 4.02704 14.6474 4.35949L8.79141 10.2154C8.7318 10.275 8.65712 10.3173 8.57533 10.3378L6.10967 10.9542C5.95117 10.9938 5.7835 10.9474 5.66798 10.8319C5.55245 10.7163 5.50601 10.5487 5.54563 10.3902L6.16205 7.9245C6.1825 7.84272 6.22478 7.76803 6.28439 7.70842L12.1403 1.85247ZM2.33057 3.13819C2.649 2.81977 3.08088 2.64088 3.5312 2.64088H7.84611C8.10299 2.64088 8.31123 2.84912 8.31123 3.10599C8.31123 3.36287 8.10299 3.57111 7.84611 3.57111H3.5312C3.32759 3.57111 3.13232 3.65199 2.98834 3.79597C2.84437 3.93994 2.76348 4.13521 2.76348 4.33882V12.9686C2.76348 13.1722 2.84437 13.3675 2.98834 13.5115C3.13232 13.6555 3.32759 13.7364 3.5312 13.7364H12.161C12.3646 13.7364 12.5599 13.6555 12.7039 13.5115C12.8479 13.3675 12.9287 13.1722 12.9287 12.9686V8.65373C12.9287 8.39686 13.137 8.18862 13.3939 8.18862C13.6507 8.18862 13.859 8.39686 13.859 8.65373V12.9686C13.859 13.419 13.6801 13.8508 13.3617 14.1693C13.0432 14.4877 12.6113 14.6666 12.161 14.6666H3.5312C3.08088 14.6666 2.649 14.4877 2.33057 14.1693C2.01214 13.8508 1.83325 13.419 1.83325 12.9686V4.33882C1.83325 3.8885 2.01214 3.45662 2.33057 3.13819Z" fill="#010814"/>
    </svg>

  );
}
