import { Alignment, emptyProps, FullContainer, Node, Style } from "../common";
import React, { useMemo } from "react";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";

import { registerComponent } from "../components";
import { useFonts, useTheme } from "../../../src/stores/selectors";
import { getBaseURL, isURL } from "../../../src/stores/util";

const type = "Image";
const inputs = [
  Style,
  {
    name: "Image URL",
    value: "image",
    type: "fileInput",
    subType: "png/jpeg/jpg/svg/webp",
    required: true
  },
  {
    name: "Image URL",
    value: "image",
    type: "urlInput",
    required: true
  },
  {
    name: "Text",
    value: "text",
    type: "textInput",
    required: true,
    autoFocus: true,
    placeHolder: "Button Name",
    defaultValue: "Click Me"
  },
  {
    name: "Alternative Text",
    value: "alt",
    type: "textInput",
    required: true
  },
  {
    name: "Width",
    value: "width",
    type: "numberInput",
    required: true
  },
  {
    name: "Height",
    value: "height",
    type: "numberInput",
    required: true
  },
  {
    name: "Border Radius",
    value: "borderRad",
    type: "progressInput",
    required: true,
    defaultValue: 0
  },
  {
    name: "Background Color",
    value: "bgcolor",
    type: "colorInput",
    required: true,
    autoFocus: false
  },

  {
    name: "Color",
    value: "color",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "MarginTop",
    value: "marginTop",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "MarginBottom",
    value: "marginBottom",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Horizonal Padding",
    value: "hPadding",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Vertical Padding",
    value: "vPadding",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },

  Alignment,
  FullContainer
];

export const Image = {
  name: "Image",
  type,
  props: emptyProps
};

export const ImageNode = () => {

  return (
    <Node type={type}>
      <div className="page-side-node br-right">
        <img src={PageBuilderAssets?.imageIcon} className="node-img" />
        <p className="node-name mb-2">Image</p>
      </div>
    </Node>
  );
};

export const ImagePlay = ({
  id,
  image = "",
  alt = "image",
  width = 100,
  height = "",
  alignment = "center",
  text = "Upload",
  style = "subtitle2",
  hPadding = 0,
  vPadding = 0,
  marginTop=0,
  marginBottom=0,
  bgcolor = "",
  color = "",
  loader = false,
  borderRad = "4px"
}) => {
  const fonts = useFonts();
  const font = useMemo(() => fonts[style], [fonts, style]);
  const theme = useTheme();
  const defaultBgColor = theme === "dark" ? "#f5f5f5" : "#12201e";
  const defaultColor = theme === "dark" ? "#12201e" : "#f5f5f5";

  return (

    <>
      <div style={{ textAlign: alignment }}>
        <img
          src={isURL(image) ? image: getBaseURL(image)}
          alt={alt}
          style={{
            width: `${width}%`,
            height: `${height}%`,
            borderRadius: borderRad,
            padding:`${hPadding}px ${vPadding}px`,
            marginTop:`${marginTop}px`,
            marginBottom:`${marginBottom}px`
          }}
          className=""
        />
      </div>
    </>
  );
};

registerComponent(type, {
  node: ImageNode,
  play: ImagePlay,
  item: Image,
  inputs,
  category: "Images"
});


// const d = <div>
// <button className="upload-button" onClick={onUpload}
//   style={{
//       fontWeight: 400,
//       margin: "10px auto",
//       color: finalColor,
//       background: finalBgcolor,
//       borderRadius: borderRad,
//       border:"none",
//       // boxShadow: `5px 5px ${Math.floor(
//       //   boxShadow * 2
//       // )}px #6c6c6c, 5px 5px ${Math.floor(boxShadow * 2)}px #fff`,
//       width: `${width}%`,
//       height:`${height}px`,
//       padding: `${vPadding}px ${hPadding}px`,
//       fontSize: font.size,
//       position: "relative"
//     }}
// >
//   <img src={UploadIcon} alt="uploadIcon"/> Upload
// </button>
// <input
//   type="file"
//   ref={fileInputRef}
//   onChange={fileChange}
//   style={{ display: "none" }}
// />
// </div>