import React from "react";
import { useFormik } from "formik";
import { withFormikDevtools } from "formik-devtools-extension";
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import { useEffect, useMemo, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {  Tabs as BootStrapTabs } from "react-bootstrap-tabs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import CustomSelect from "../../../CustomSelect";



import { ToastContainer } from 'react-toastify';

import Trash from "../../../../../src/assets/trash-icon";
import Upload from "../../../../../src/assets/upload-icon";
import {
  createNode,
  getNode,
  imageUpload,
  updateNode,
} from "../../../../api/nodes";
import { generateId, getAllStringValues, getInputs, isValidUrl, removeSpaceFromObj } from '../../../utils';
import RequestBodyBox from '../../RequestBoby';
import TabsComponent from "./tabs";

import { IMAGE_URL } from "../../../../api/common";
import HeadDescription from './headDescription';
import "./index.css";
import CodeEditor from "../components/codeEditor";
import { Modal } from "react-bootstrap";
import Icons from '../../../../assets';
import { CreateNodeKeys, NodeTypes } from "../../../constants";

const {ForwardIconWhite, InfoIcon, CrossClose} = Icons

const excludedValues = ["jobId", "timestamp", "isoTimestamp", "guid"];
const method = ["POST", "GET", "PUT", "DELETE"];


const Keydata = ({ name, description, items, updateItems, className, error, handleBlur, touchedError }) => {

  return (
    <div className={`config_box ${className}`}>
      <HeadDescription
        head={name}
        des={description}
      />
      {(Array.isArray(items)) ? items?.map((item,index)=>{

        let err = error && error[index]
        return(
          <div className="mr_all" key={index}>
            <div className="adding_parameters">
              <div className="space_btw1 input_row">
                <div className="desp_flex1">
                  <label className="labels">Key</label>
                  <input
                    className="inputs"
                    placeholder="Enter the key"
                    value={item?.name}
                    onBlur={handleBlur}
                    name="name"
                    // onChange={updateItems}
                    onChange={(event) => {
                      updateItems(
                        items?.map((i) => {
                          if (i.id === item.id) {
                            i.name = event.target.value;
                          }
                          return i;
                        })
                      );
                    }}
                  />
                {/* {err?.name ? <p className="formik_error">{err?.name}</p> : null} */}
                </div>
                <div className="desp_flex2">
                  <label className="labels">Value</label>
                  <input
                    className="inputs"
                    placeholder="Enter the value"
                    value={item.value}
                    onBlur={handleBlur}
                    name="value"
                    onChange={(event) => {
                      updateItems(
                        items?.map((i) => {
                          if (i.id === item.id) {
                            i.value = event.target.value;
                          }
                          return i;
                        })
                      );
                    }}
                  />
                {/* {err?.value ? <p className="formik_error">{err?.value}</p> : null} */}
                </div>
                <div
                  className="del_btn"
                  onClick={() => {
                    updateItems(items.filter((i) => i.id !== item.id));
                  }}
                >
                  <Trash />
                </div>
              </div>
            </div>
        </div>
        )
      }) : (
        <div className="mr_all" key="first">
        <div className="adding_parameters">
          <div className="space_btw1 input_row">
            <div className="desp_flex1">
              <label className="labels">Key</label>
              <input
                className="inputs"
                placeholder="Select key"
                onBlur={handleBlur}
                name="name"
                onChange={(event) => {
                  updateItems([...items,{ id: generateId(8), name: event.target.value, value: "" }])
                }}
              />
              {/* {err?.name ? <p className="formik_error">{err?.name}</p> : null} */}
            </div>
            <div className="desp_flex2">
              <label className="labels">Value</label>
              <input
                className="inputs"
                placeholder="Enter the value"
                onBlur={handleBlur}
                name="value"
                onChange={(event) => {
                  updateItems([...items,{ id: generateId(8), value: event.target.value, name: "" }])

                }}
              />
                {/* {err?.value ? <p className="formik_error">{err?.value}</p> : null} */}
            </div>
          </div>
        </div>
      </div>
      )}

      <div className="mr_all">
        <div
          id="dropzone1"
          onClick={() => {
            updateItems([...items,{ id: generateId(8), name: "", value: "" }]);
          }}
        >
          <label>
            <p className="caption1">+ Add new {name}</p>
          </label>
        </div>
      </div>
    </div>
  );
};

const ApiMethod = ({
  methodValue,
  urlValue,
  handleChange,
  handleChangeMethod,
  errorMethod,
  errorUrl,
  name,
  description,
  className,
  handleBlur,
  handleTouch
}) => {
  const apiOptions = [
    { value:"POST", label: "POST" },
    { value:"GET", label: "GET" },
    { value:"PUT", label: "PUT" },
    { value:"DELETE", label: "DELETE" },
  ]
  return (
    <div className={`API_method_box config_box ${className}`}>
      <HeadDescription
        head={name}
        des={description}
      />
      <div className="space_btw1 input_row">
        <div className="desp_flex1 input_wrappers">
          <CustomSelect
            name="method"
            isSearchable={false}
            label="API Method"
            placeholder="Select API Method"
            options={apiOptions}
            onBlur={handleBlur}
            defaultValue={(apiOptions.find(item => item.label === methodValue))}
            value={(apiOptions.find(item => item.label === methodValue))}
            onChange={(e) => handleChangeMethod(e.value)}          
          />
          {errorMethod ? (
            <p className="formik_error">{errorMethod}</p>
          ) : null}


        </div>
        <div className="desp_flex2 input_wrappers">
          <label className="labels">URL</label>
          <input
            className="inputs"
            placeholder="ex:https://www.example.com"
            name="url"
            value={urlValue}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errorUrl ? <p className="formik_error">{errorUrl}</p> : null}
        </div>
      </div>
    </div>
  );
};


const StyledInput = ({ lable, handleChange, value, error, name, touchedError, handleBlur, placeholder }) => (
  <div className="left-side">
    <div className="space_btw">
      <div className="leftside-input">
        <label className="labels"> {lable}</label>
        <input
          type="text"
          className="inputs"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {error ? <p className="formik_error">{error}</p> : null}
      </div>
    </div>
  </div>
);

// const HeadDes = ({ head, des }) => (
//   <div>
//     <div className="header_text">{head} </div>
//     <p className="description-auth">{des}</p>
//   </div>
// );

const ImageUpload = ({ onChange }) => (
  <input
    type="file"
    name="logo"
    placeholder="Enter Node Name"
    onChange={onChange}
    value={undefined}
    hidden
    draggable={true}
    accept="image/*"
    alt="select-file"
      />
);

const Statuscodes = ({ name,data,placeholder, description, className, updateData, errorStatusCode }) => {
    return (
    <div className={`config_box ${className}`}>
      <HeadDescription
        head={name}
        des={description}
      />

      {Array.isArray(data) ? data.map((item)=>{
        return(
        <div key={item.id} className="status_box_wrapper">
          <div className="status-trash-div">
            <div className="desp_flex3">
              <label className="labels-status">Status code</label>
              <input
              type="number"
                className="inputs"
                placeholder={placeholder}
                name="status"
                max={599}
                value={item.status}
                onChange={(event) => {
                  updateData(
                    data.map((i) => {
                      if (i.id === item.id) {
                        i.status = parseInt(event.target.value);
                      }
                      return i;
                    })
                  );
                }}
              />
            </div>
            <div className="del-btn-main-div">
              <div
                className="del_btn1"
                onClick={() => {
                  updateData(data.filter((i) => i.id !== item.id));
                }}
              >
                <Trash />
              </div>
            </div>
          </div>
          <div className="json-editor">
            <JsonEditor
              mode="text"
              name="variables"
              value={item.variables || {}}
              onChange={(value) => {
                updateData(
                  data.map((i) => {
                    if (i.id === item.id) {
                      i.variables = value;
                    }
                    return i;
                  })
                );
              }}
            />
          </div>
        {errorStatusCode ? <p className="formik_error">{errorStatusCode}</p> : null}
        </div>
        )
      }): (
        <div key="first" className="status_box_wrapper">
          <div className="status-trash-div">
            <div className="desp_flex3">
              <label className="labels-status">Status code</label>
              <input
                className="inputs"
                placeholder="Enter the code"
                name="status"
                max={599}
                type="number"
                value={item.status}
                onChange={(event) => {
                  updateData(
                    data.map((i) => {
                      if (i.id === item.id) {
                        i.status = parseInt(event.target.value);
                      }
                      return i;
                    })
                  );
                }}
              />
            </div>
            <div className="del-btn-main-div">
              <div
                className="del_btn1"
                onClick={() => {
                  updateData(data.filter((i) => i.id !== item.id));
                }}
              >
                <Trash />
              </div>
            </div>
          </div>
          <div className="json-editor">
            <JsonEditor
              mode="text"
              name="variables"
              value={item.variables || {}}
              onChange={(value) => {
                updateData(
                  data.map((i) => {
                    if (i.id === item.id) {
                      i.variables = value;
                    }
                    return i;
                  })
                );
              }}
            />
          </div>
        {errorStatusCode ? <p className="formik_error">{errorStatusCode}</p> : null}
        </div>
      )}
      <div className="mr_all">
        <div
          id="dropzone2"
          onClick={() => {
            updateData([
              ...data,
              { id: generateId(6), status: "", variables: {} },
            ]);
          }}
        >
          <label>
            <p className="caption1">+ Add new Status Code</p>
          </label>
        </div>
      </div>
    </div>
  );
};


const validationSchema = Yup.object({
  // logo: Yup.string().required(),
  name: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required("Node name required"),
  category: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required("Category required"),
  sub_category: Yup.string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Only alphabets are allowed")
    .required(" SubCategory required"),
  // url: Yup.string().required("url is required"),
  url: Yup.string().when('nodeType', {
    is: (nodeType) => nodeType == NodeTypes.API,
    then: () => Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required("Url is required"),
    otherwise: () => Yup.string().notRequired(),
  }),
  method: Yup.string().when('nodeType', {
    is: (nodeType) => nodeType == NodeTypes.API,
    then: () => Yup.string().required('Method is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  // body: Yup.object().required(),
  status_codes: Yup.array().when('nodeType', {
    is: (nodeType) => nodeType == NodeTypes.API,
    then: () => Yup.array()
      .of(Yup.object().shape({})) // Define your validation for status_codes here if needed
      .required('Status code is required'),
    otherwise: () => Yup.array().notRequired(),
  }),

  // params: Yup.array().when('nodeType', {
  //   is: (nodeType) => nodeType == NodeTypes.API,
  //   then: () => Yup.array().of(
  //     Yup.object().shape({
  //       name: Yup.string().required("Name is required"),
  //       value: Yup.string().required("Value is required")
  //     })
  //   ),
  //   otherwise: () => Yup.array().notRequired(),
  // }),


  auth: Yup.object().when('nodeType', {
    is: (nodeType) => nodeType == NodeTypes.API,
    then: () => Yup.object().shape({
      // type: Yup.string().required('Select an option'),
      username: Yup.string().when(['type', 'auth.username'], {
        is: (type) => type == "Basic",
        then: () => Yup.string().required("Username is required"),
        otherwise: () => Yup.string().notRequired() 
      }),
      password: Yup.string().when(['type', 'auth.password'], {
        is: (type) => type == "Basic",
        then: () => Yup.string().required("Password is required"),
        otherwise: () => Yup.string().notRequired() 
      }),
      url: Yup.string().when(['type', 'auth.url'], {
        is: (type, url) => type == "Session",
        then: () => Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required("Url is required"),
        otherwise: () => Yup.string(),
      }),
      method: Yup.string().when(['type', 'url'], {
        is: (type, method) => type === 'Session',
        then: () => Yup.string().required('Method is required'),
        otherwise: () => Yup.string(),
      }),
      // body: Yup.string().when(['type', 'auth.body'], {
      //   is: (type, body) => type === 'Session',
      //   then: () => Yup.object().shape({}).required('Body is required'),
      //   otherwise: () => Yup.object().shape({}),
      // }),
      status_codes: Yup.array().when('type', {
        is: (type) => type == 'Session',
        then: () => Yup.array()
          .of(Yup.object().shape({})) // Define your validation for status_codes here if needed
          .required('Status code is required'),
        otherwise: () => Yup.array().of(Yup.object().shape({})).notRequired(),
      }),
    }),
    otherwise: () => Yup.object().notRequired(),
  }),

  code: Yup.string().when('nodeType', {
    is: (nodeType) => nodeType == NodeTypes.CUSTOM_CODE,
    then: () => Yup.string().required('Code is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  
});



const Details = ({ nodeId, isEdit, isBreadCrumb=true}) => {
  const [saving, setSaving] = useState(false);
  const [disable, setDisable] = useState(false);
  const [radioToggle, setRadioToggle] = useState(false);
  const [xmlError, setXmlError] = useState({});
  const [selectedNode, setSelectedNode] = useState(NodeTypes.API);

  const navigate = useNavigate();

  useEffect(() => {
    if (isEdit === true && nodeId) {
      getNode(nodeId)
        .then((detail) => {
          formik.setValues(detail);
          setSelectedNode(detail?.nodeType || NodeTypes.API)
          setLogoBase64(detail?.logo || "");
        })
        .catch((err) => {
          toast.error("unable to get node with ", nodeId);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, nodeId]);


  const formik = useFormik({
    enableReinitialize:true,
    initialValues:{
      method: "",
      url: "",
      params: [],
      headers: [],
      name: "",
      category: "",
      logo: "",
      sub_category: "",
      auth: {},
      organization: "",
      inputs: [],
      outputs: [],
      bodyType: "formdata",
      code: `async function handler(options) {
        // Write your own logic here
        let { variables, nodeId, workflowId } = options;
        variables["myNewVar"] = "someVariableValue";
   
        return { variables: variables };
      }`,
      environment: "FrontEnd",
      language: "javascript",
      nodeType: NodeTypes.API,
      variables: []
    },
    validationSchema: validationSchema,    

    onSubmit:async (values) => {
      setDisable(true)
      
      try {
        values.inputs = values.nodeType === NodeTypes.CUSTOM_CODE ? values.inputs :  inputs;
        values.outputs = values.nodeType === NodeTypes.CUSTOM_CODE ? values.outputs :  outputs;
        values.logo = logoBase64;
        values = getRequestValues(values);
        setSaving(true);
        if (isEdit && isEdit === true) {
          updateNode(values)
            .then((res) => {
              if (res.id) {
                formik.resetForm();
                toast.success("Successfully Updated " + values.name);
                setSaving(false);
                setDisable(false)
                setTimeout(() => {
                  navigate(-1);
                }, 0);
              }
              setSaving(false);
            })
            .catch((err) => {
              toast.error("unable to update node with ", err.message);
              setSaving(false);
              setDisable(false);
            });
          return;
        }
        createNode(values)
          .then((res) => {
            if (res.name) {
              toast.success("Successfully Created " + values.name);
              formik.resetForm();
              setSaving(false);
              setDisable(false)
              setTimeout(() => {
                navigate(-1);
              }, 0);
            }
          })
          .catch((err) => {
            toast.error("Unable to create node with ", err.message);
            setSaving(false);
            setDisable(false)
          });
        }
       catch (err) {
        // toast.error("error in node ", err.message);
        setDisable(false)
      }
    },
  });
  withFormikDevtools(formik);
  const [logoBase64, setLogoBase64] = useState("");
  const flattenObj = (ob) => {

    // The object which contains the
    // final result
    let result = {};

    // loop through the object "ob"
    for (const i in ob) {

        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {

                // Store temp in result
                result[i + '.' + j] = temp[j];
            }
        }

        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }

    }
    return result;
};


  // let inputs = useMemo(() => {
  //   let values = JSON.parse(JSON.stringify(formik.values));
  //   values.auth.status_codes = [];
  //   values.status_codes = [];
  //   let res = getInputs(flattenObj(values))
  //   return res;
  // }, [formik.values]);
  let inputs = useMemo(() => {
    return getInputs(formik.values);
  }, [formik.values]);

  const [outputs, internal] = useMemo(() => {
    const newValue = JSON.parse(JSON.stringify(formik.values || {}));

    let internalValues = {};

    if (formik.values?.auth?.status_codes) {
      internalValues = (newValue.auth?.status_codes || []).map(
        (item) => item.variables
      );
      newValue.auth.status_codes = undefined;
    }

    return [
      (formik.values?.status_codes || []).map((status) => {
        const opts = getAllStringValues(status.variables);
        return { id: status.id, status: status.status, outputs: opts };
      }),
      getAllStringValues(internalValues),
    ];
  }, [formik.values]);

  inputs = inputs.filter((input) => !internal.find((i) => i === input));
  inputs = inputs.filter((input) => !excludedValues.find((ex) => ex === input));



  const updateAuth = (field, obj) =>{
      removeSpaceFromObj(obj);
    formik.setFieldValue("auth", {
      ...formik.values?.auth,
      [field]: obj,
    });

  }


  const getRequestValues = (node) =>{
    const request = {};
    if(!node?.nodeType){
      node['nodeType'] = NodeTypes.API
    }
    const keys = node?.nodeType === NodeTypes.API ? CreateNodeKeys.API : CreateNodeKeys.CUSTOM_CODE;
    Object.keys(node).map(key=>{
      if(keys.includes(key)){
        request[key] = node[key]
      }
    })
    if(node?.nodeType === NodeTypes.CUSTOM_CODE){
      request["variables"] = node["inputs"].map(item=> item.name)
    }
    return request
  }

  const onChangeImage = (event) => {
    event.preventDefault(); 
    event.stopPropagation(); 

    let file;
    if (event.target?.files?.length > 0) {
      file = event.target?.files[0]
    } else if (event.dataTransfer?.files?.length > 0) {
      file = event.dataTransfer.files[0]
    }

    let formData = new FormData();
    formData.append('file', file);

    //let's grab the image file
    imageUpload(formData)
      .then((res) => {
        formik.setFieldValue('logo', res.id)
        setLogoBase64(res.id);
      })
      .catch((err) => {
        toast.error("unable to fetch id", err);
      });

    // const reader = new FileReader();

    // reader.onloadend = (e) => {
    //   setLogoBase64(reader.result);
    // };

    // if (file) {
    //   reader.readAsDataURL(file);
    // }
  };

  const onChangeAuth = (e) => {
      const { value, name } = e.target;
      updateAuth(name, value);
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    let formData = new FormData();
    formData.append('file', file)
    imageUpload(formData)
      .then((res) => {
        setLogoBase64(res.id);
      })
      .catch((err) => {
        toast.error("unable to fetch id", err);
      });

    // if (file && file.type.match("image.*")) {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     setLogoBase64(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
    }

  const imageCheck = (logo) => {
    return logo?.length < 30;
  };

 
  const authOptions = [
    { value:"Basic", label: "Basic Auth" },
    { value:"Session", label: "Session Auth" }
  ]

const parentTOchild = (data) => {
setXmlError(data);
}
  return (
    <>
      <div className="pos_fixed breadcrumb_sec align-items-center">
        <div className="left_sec">
         {isBreadCrumb ?  <Breadcrumb>
            <Breadcrumb.Item href="#" className="breadCrumb-group">
              <NavLink to="/home/nodes">Nodes</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active href="#">
              {isEdit && isEdit === true ? "Edit Node" : "Create Node"}
            </Breadcrumb.Item>
          </Breadcrumb> : null}
          <div className="node-builder-heading">
            <div className="node-builder-main-heading">
              <h1>{isEdit && isEdit === true ? "Edit Node" : "Create node"}</h1>
              <p className="sub_text"> {isEdit && isEdit === true ? "View the details of the  node." : "Create your own node"}</p>
            </div>
          </div>
        </div>
        <div className="right_sec">
          <button
            className="node_builder_btn"
            type="submit"
            onClick={() => {
              // toast.error("askdfjlaksdjf")
              if (xmlError) {
                if (Object.keys(xmlError).length > 0 ) {
                  toast.error("Please clear XML validations");
                } else {
                  formik.handleSubmit();
                }
              } else {
                formik.handleSubmit()
                Object.keys(formik.errors).length > 0 && toast.error("Please check all Fields");
              }        
            }}
            // disabled={!formik.isValid || saving}
          >
            {saving === true ? (
              "Saving ..."
            ) : (
              <>
                {isEdit && isEdit === true ? "Update Changes" : "Save Changes"}
              </>
            )}
          </button>
        </div>
      </div>

      {/* body of create node  */}
      <div className="wid-100">
        <div className="nodeDetail_main">
          <div className="left-side-component">
            <div className="header_text1">Node details </div>
            <div className="section_wrapper">
              <div className="first_box">
                <div className="row">
                  <div className="input_wrapper col-lg-6">
                    <StyledInput
                      name={"name"}
                      lable={"Node Name"}
                      value={formik.values?.name}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={formik.errors.name}
                      touchedError={formik.touched.name}
                      placeholder={"Enter node name"}
                    />
                    <StyledInput
                      name={"category"}
                      lable={"Category"}
                      value={formik.values?.category}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={formik.errors.category}
                      touchedError={formik.touched.category}
                      placeholder={"Enter node category"}
                    />
                    <StyledInput
                      name={"sub_category"}
                      lable={"Sub Category"}
                      value={formik.values?.sub_category}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={formik.errors.sub_category}
                      touchedError={formik.touched.sub_category}
                      placeholder={"Enter node sub category"}

                    />
                  </div>
                  <div className="upload_wrapper-cus col-lg-6">
                    <div className="column">
                      <label className="labels">Icon</label>
                    </div>
                    <div
                      className="mr_all"
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={handleDrop}
                    >
                      <div id={logoBase64 === "" ? "dropzone" : "dropzone_pd"}>
                        <label className="label-dropzone">
                          {logoBase64 !== "" && (
                            <img
                              src={
                                imageCheck(logoBase64)
                                  ? IMAGE_URL + logoBase64
                                  : `data:image/png;base64 ,${logoBase64}`
                              }
                              style={{ border: "none" }}
                              width="120"
                              height="120"
                              className="logo-img"
                              alt="logo"
                            />
                          )}
                          <ImageUpload onChange={onChangeImage} />
                          {logoBase64 === "" ? (
                            <div className="btn-dropzone"
                            onDrop={onChangeImage}
                            >
                              <div>
                                <p className="caption">
                                  Upload or drag and drop you logo
                                </p>
                                <p className="caption"> here to upload</p>
                              </div>
                              <div className="">
                                <label className="upload_btn">
                                  <ImageUpload onChange={onChangeImage} />
                                  <Upload />
                                  Upload logo
                                </label>
                              </div>
                            {formik.errors.logo ? <p className="formik_error">{formik.errors.logo}</p> : null}
                            </div>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text_input">
                    <label className="labels">Description</label>
                    <textarea
                      className="description w-100"
                      placeholder="Enter description"
                      value={formik.values?.description}
                      name="description"
                      onChange={formik.handleChange}
                    />
                </div>
              </div>
            </div>
            <Tabs onSelect={(k) => {
              setSelectedNode(k);
              formik.setFieldValue("nodeType", k);
            }} className="create_node_tabs" activeKey={selectedNode}>
                <Tab eventKey={NodeTypes.API} title="API Node" disabled={isEdit}/>
                <Tab eventKey={NodeTypes.CUSTOM_CODE} title="Custom Code" disabled={isEdit}/>
            </Tabs>
            {selectedNode === NodeTypes.API && <>
            <div className="section_wrapper mt-4">
              <div className="second_box">
                <HeadDescription
                  head={"Configure Authentication"}
                  des={
                    "Build a form with fields for each item your API requires for authentication, such as username, password, subdomain, and more."
                  }
                />
                <div className="col-lg-6 input_wrappers">
                  <CustomSelect
                    name="method"
                    isSearchable={false}
                    label="Authentication Type"
                    defaultValue={(authOptions.find(item => item.value === formik.values?.auth?.type))}
                    value={(authOptions.find(item => item.value === formik.values?.auth?.type))}
                    placeholder="Select Authentication Type"
                    options={authOptions}
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue("auth", {
                        ...formik.values?.auth,
                        type: e.value,
                      })
                    }
                  />
                  { formik.errors.auth?.type ? (<p className="formik_error">{formik.errors.auth?.type}</p>) : null}
                </div>
                {formik.values?.auth?.type === "Basic" ?
                (
                  <div className="space_btw1 input_row user_inputs">
                    <div className="desp_flex1 input_wrappers">
                      <label className="labels">Username</label>
                      <input
                        className="inputs"
                        placeholder="Enter Username"
                        name="username"
                        value={formik.values?.auth?.username}
                        onChange={(e) => updateAuth("username", e.target.value)}
                      />
                      {formik.errors?.auth?.username ? <p className="formik_error">{formik.errors?.auth?.username}</p> : null}
                    </div>
                    <div className="desp_flex2 input_wrappers">
                      <label className="labels" id="authentication">
                        Password
                      </label>
                      <input
                        className="inputs"
                        placeholder="Enter Password"
                        type="password"
                        name="password"
                        value={formik.values?.auth?.password}
                        onChange={(e) => updateAuth("password", e.target.value)}
                      />
                      {formik.errors?.auth?.password ? <p className="formik_error">{formik.errors?.auth?.password}</p> : null}
                    </div>
                  </div>
                ) : "" }
                {formik.values?.auth?.type === "Session" ? (
                <div className="auth_session_wrapper">
                  <ApiMethod
                    name={"Session Auth"}
                    description={"Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum"}
                    methodValue={formik.values?.auth?.method}
                    urlValue={formik.values?.auth?.url}
                    errorMethod={formik.errors.auth?.method}
                    errorUrl={formik.errors.auth?.url}  
                    handleBlur={formik.handleBlur}
                    handleChange={onChangeAuth}
                    handleTouch={formik.touched.auth?.url}
                    handleChangeMethod={(value) => {
                      formik.setFieldValue("auth", {
                        ...formik.values?.auth,
                        method: value,
                      })
                    }}
                  />


                  <Keydata
                    className="Parameter_box"
                    description={`Use the session authentication type if
                    you need to collect some information
                    from your users, for example a user name
                    and password, and then make a request to
                    your API to exchange that information
                    for a token or session key, which you
                    will use to authorize subsequent API
                    requests`}
                    name="Parameter"
                    value={formik.values?.auth?.params}
                    items={formik.values?.auth?.params || [{ id: generateId(8), name: "", value: "" }]}
                    updateItems={(items) => updateAuth("params", items)}
                    handleBlur={formik.handleBlur}
                    touchedError={formik.touched?.auth?.params}
                    error={formik.errors?.auth?.params}

                  />
                  <Keydata
                    className="headers_box"
                    description={`Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam
                  sapien scelerisque ac.`}
                    name="Header"
                    value={formik.values?.auth?.headers}
                    items={formik.values?.auth?.headers || [{ id: generateId(8), name: "", value: "" }]}
                    updateItems={(items) => updateAuth("headers", items)}
                    handleBlur={formik.handleBlur}
                    error={formik.errors?.auth?.headers}
                    touchedError={formik.touched?.auth?.headers}

                  />
                  <RequestBodyBox
                    formik={formik}
                    uniqueKey="un1"
                    name="auth.body"
                    formikName="auth.bodyType"
                    formikValue={removeSpaceFromObj(formik.values?.auth?.body) || [{id: generateId(6), key: "", value: "", description: ""}]}
                    onChange={(value) => updateAuth("body", value)}
                    // errorBody={formik.errors.auth?.body}
                    parentTOchild={parentTOchild}
                    />

                <Statuscodes
                  className="status_box"
                  description={`Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum.`}
                  name={"Status code"}
                  placeholder={"Enter the status code"}
                  data={formik.values?.auth?.status_codes || [
                    {
                      id: generateId(6),
                      status: 0,
                      variables: {},
                    },
                  ]}
                  updateData={(data) =>
                    formik.setFieldValue("auth.status_codes", data)
                  }
                  errorStatusCode={formik.errors.auth?.status_codes}
                  />

                </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="section_wrapper">
              <div className="third_box api_config_wrapper">
                <ApiMethod
                  methodValue={formik.values?.method}
                  urlValue={formik.values?.url}
                  handleChange={formik.handleChange}
                  handleChangeMethod={(value) => {
                    formik.setFieldValue("method",  value)
                  }}
                  errorMethod={formik.errors.method}
                  errorUrl={formik.errors.url}
                  name={"Configure your API"}
                  description={"Build a form with fields for each item your API requires for authentication, such as username, password, subdomain, and more."}
                />

                <Keydata
                  handleBlur={formik.handleBlur}
                  touchedError={formik.touched?.params}
                  error={formik.errors.params}
                  className="Parameter_box"
                  name="Parameter"
                  description={`Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum`}
                  value={formik.values?.params || []}
                  items={formik.values?.params || [{ id: generateId(8), name: "", value: "" }]}
                  updateItems={(items) => formik.setFieldValue("params", items)}

                />

                <Keydata
                  handleBlur={formik.handleBlur}
                  touchedError={formik.touched?.headers}
                  error={formik.errors.headers}
                  className="headers_box"
                  description={`Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum`}
                  name={"Header"}
                  value={formik.values?.headers || []}
                  items={formik.values?.headers || [{ id: generateId(8), name: "", value: "" }]}
                  updateItems={(items) => formik.setFieldValue("headers", items)}
                  // updateItems={(items) => console.log(items, '--sad')}
                />

                <RequestBodyBox
                  formik={formik}
                  formikName="bodyType"
                  formikValue={formik.values?.body || [{id: generateId(6), key: "", value: "", description: ""}]}
                  name={"body"}
                  uniqueKey="un0"
                  onChange={(value) => {
                  removeSpaceFromObj(value);
                  formik.setFieldValue("body", value)
                  }}
                  parentTOchild={parentTOchild}
                  // error={formik?.errors?.body}
                />

            <Statuscodes
              className="status_box"
              description={`Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien scelerisque ac.`}
              name={"Status Code"}
              placeholder={"Enter the status code"}
              data={formik.values?.status_codes || [
                {
                  id: generateId(6),
                  status: 0,
                  variables: {},
                }
              ]}
              updateData={(data) =>
                formik.setFieldValue("status_codes", data)
              }
              errorStatusCode={formik.errors?.status_codes}
            />
            
            </div>
            </div>
            </>}
            {selectedNode === NodeTypes.CUSTOM_CODE && <CustomCodeComponent data={formik}/>}
          </div>
        </div>
        {selectedNode === NodeTypes.API && <div className="tab-componet">
          <div className="main-tab-div">
            <div className="inner-tab-div">
              <div className="top-content">Your Responses</div>
              <BootStrapTabs>
                <TabsComponent label={"Inputs"} array={inputs} />
                <TabsComponent label={"Outputs"} array={outputs} />
                <TabsComponent label={"Internals"} array={internal} />
              </BootStrapTabs>
            </div>
          </div>
        </div>}
      </div>
      <ToastContainer />
    </>
  );
};

const codelanguages = [
  { name: "Javascript", value: "javascript" },
  { name: "Typescript", value: "typescript" },
  { name: "Python", value: "python" }
];

const CustomCodeComponent = ({data})=>{

  const availablelanguages = useMemo(()=>{
    return data?.values.environment === "FrontEnd" ? [codelanguages[0]] : codelanguages
  }, [data?.values.environment])


  return (
    <div className="custom_code_container first_box">
      <h3 className="custom_code_header">
          Code Editor
      </h3>
      <div className="select_language_container">
        <p className="nodePanel-labels">Code For</p>
        <div className="custom_code_radio_container">
            <div className="custom_code_radio">
              <input
                  type="radio"
                  name="environment"
                  id="FrontEnd"
                  value={"FrontEnd"}
                  onChange={data.handleChange}
                  checked={data?.values.environment === "FrontEnd"}
              />
              <label for="FrontEnd">Frontend</label>
            </div>
            <div className="custom_code_radio">
              <input type="radio"
                  name="environment"
                  value={"BackEnd"}
                  id="BackEnd"
                  onChange={data.handleChange}
                  checked={data?.values.environment === "BackEnd"}
              />
              <label for="BackEnd">Backend</label>
            </div>
        </div>
      </div>
      <div className="custom_code_language_container">
        <div class="RightLabel">
              <div class="nodePanel-labels">Language</div>
              <div>
                  <div className="RightLabelImg">
                      <InfoIcon/>
                      <div className="RightTooltip ">
                          <div className="RightSubtool">
                              <p>Choose a programming language for your code.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <select
            name="language"
            value={data?.language}
            onChange={data.handleChange}
          > <option disabled>Select language</option>
              {(
                  availablelanguages?.map((lang, i) => (
                      <option value={lang.value} key={i} selected={lang.value === data?.language}>
                          {lang.name}
                      </option>
                  ))
              )}
          </select>
         
      </div>
      <section className="mt-3">
          <CodeEditor
              name="Small Editor"
              mode={data.values.language}
              handleEditorChange={(value)=> data.setFieldValue("code", value)}
              value={data.values.code}
          />
      </section>
      {data?.errors?.code ? <p className="formik_error">{data?.errors?.code}</p> : null}
      <AddVariables data={data}/>
  </div>
  )
}


const AddVariables = ({data})=>{
  const [show, setShow] = useState("");
  const [addVariable, setAddVariable] = useState("");
  const [showError, setShowError] = useState(false);

  const handleDeleteTask = (index) => {
    const existingVariables = data.values[show] || [];
    const updatedVariables = [...existingVariables];
    updatedVariables.splice(index, 1);
    data.setFieldValue(show, updatedVariables)
  };

  const handleAddVariable = () => {
    const name = addVariable.trim();
    let newVariable = {
      id : generateId(10),
      name,
      value: ""
    }
    if (name !== "") {
        const existingVariables = data.values[show] || [];
        if (existingVariables.some(item=> item.name === name)) {
            setShowError(true);
        } else {
            const updatedVariables = [...existingVariables, newVariable];
            setAddVariable("");
            setShowError(false);
            data.setFieldValue(show, updatedVariables)
        }
    }
  };

  return (
    <section className="sideInnerParent">
    <div className="idflow_np_code_for">
      <div className="idflow_np_add_variables">
          <p><span className="idflow_np_astreik">&#10033;</span> Add variables into the code for system recognition and identification.</p>
          <div className="idflow_np_variables_button_container">
            <button onClick={() => setShow("inputs")}>Add Input variables</button>
            <button onClick={() => setShow("outputs")}>Add Output variables</button>
          </div>
      </div>
      <section>
        <Modal show={Boolean(show.length)} onHide={() => setShow("")} size="lg" centered>
            <div className="modal-main">
                <div className="idflow_popup-head">
                    <h5 className="mb-0">Add Variables</h5>
                    <div className="idflow_np_modal_close idflow_np_modal_close_white" onClick={() => setShow("")}  >
                        <CrossClose />
                    </div>
                </div>
                <p className="idflow_np_popup_supporting_text pt-2">Add variables here for system recognition and reuse across other nodes.</p>
                <section>
                    <div class="RightLabel">
                        <div class="nodePanel-labels">Variables</div>
                        <div>
                            <div className="RightLabelImg">
                                <InfoIcon/>
                                <div className="RightTooltip ">
                                    <div className="RightSubtool">
                                        <p>Add your variables</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="labelInputFull idflow_np_add_variable_inp_area">
                        <input type="text"
                            onChange={(e) => {
                                setAddVariable(e.target.value);
                                setShowError(false)
                            }}
                            value={addVariable}
                            autoFocus
                        />
                        <button className="idflow_np_forward_img" onClick={handleAddVariable} >
                          <ForwardIconWhite />
                        </button>
                    </div>
                    {showError && <p className="idflow_np_var_err">Variable taken. Please try another.</p>}
                    {data && data.values[show]?.length > 0 ?
                        <>
                            <div className="idflow_np_code_for">
                                <ul className="idflow_np_variables_ul">
                                    {data.values[show].map((variable, index) => (
                                        <li key={index} className="idflow_np_var_item">
                                            <p>{variable?.name}</p>
                                            <div className="idflow_np_modal_close" onClick={() => handleDeleteTask(index)} >
                                              <CrossClose />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </> : <>
                            <div className="idflow_np_code_for">
                                <ul className="idflow_np_variables_ul">
                                    <p className="mb-0">Please add variables.</p>
                                </ul>
                            </div>
                        </>
                    }
                </section>
            </div>
        </Modal>
      </section>
    </div>
  </section>
  )
}
export default Details;