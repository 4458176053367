import { Alignment, FullContainer, Node, emptyProps } from "../common";
import React from "react";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { registerComponent } from "../components";
import { useLogo, useTheme } from "../../../src/stores/selectors";

const type = "Logo";


const inputs = [

    {
        name: "Alternative Text",
        value: "alt",
        type: "textInput",
        required: true,
    },
    {
        name: "Width",
        value: "width",
        type: "numberInput",
        required: true,
    },
    {
        name: "Height",
        value: "height",
        type: "numberInput",
        required: true,
    },
    {
        name: "Border Radius",
        value: "borderRad",
        type: "progressInput",
        required: true,
        defaultValue: 0,
    },

    Alignment,
    FullContainer,
];

export const Logo = {
    name: "Logo",
    type,
    props: emptyProps,
};

export const LogoNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.logoIcon} className="node-img" />
                <p className="node-name mb-2">Logo</p>
            </div>
        </Node>
    );
};


export const LogoPlay = ({
    id,
    image = 'https://www.sbmbank.co.in/img/logo.png',
    alt = "image",
    width = 50,
    height = 50,
    alignment = "center",
    borderRad = "0px",
}) => {

    const { white, black: dark } = useLogo()
    const theme = useTheme()
    return (
        <>
            <div style={{ textAlign: alignment }}>
                <img
                    src={theme === "light" ? dark : white}
                    alt={alt}
                    style={{
                        width: `${width}%`,
                        height: `${height}%`,
                        margin: "1rem 0rem",
                        borderRadius: borderRad,
                    }}
                    className="PBimageplay"
                />
            </div>
        </>
    );
};

registerComponent(type, {
    node: LogoNode,
    play: LogoPlay,
    item: Logo,
    inputs,
    category: "Images",
});