
import { StartNode, NodeHeader } from "../../Common";
import { TbVariable } from "react-icons/tb";
import { Items } from "../APICall/Apicall";
import {
  Handle,
  Position,
} from "reactflow";
import React, { useState, useEffect } from "react";
import Icons from '../../../../../assets/imageUrl';
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader"
import { useNodeData } from "../../../../store/selector";
const nodeName = "Zapier";


export const Zapier = ({ id, type = nodeName, vars }) => {
  const [data, setData] = useNodeData(id);
 
  return (
    <div className="right_panel_workflow">
      <SidePanelHeader nodeName={nodeName} />
      <section className="sideInnerParent">
        <div>
        <label className="nodePanel-labels">{type + " Name"}</label>
<div className="labelInputFull">
<input
  type="text"
  className="nodePanel-inputs "
  value={data?.name || ""}
  onChange={(e) => setData({ ...data, name: e.target.value })}
  placeholder={"Trigger" + " Name"}
/>
</div>
<h6 className="NoteNode">Note : </h6>
<p className="NoteMsg">Please provide a unique name for this node, in Zapier to help with its
        identification.</p>
        </div>
        {data.id && (
        <div className="flow-name right_section_separate">
          <label className="nodePanel-labels">Id</label>
          <input
            type="text"
            className="nodePanel-inputs mb-2"
            defaultValue={data.id}
            placeholder="Id"
            readOnly
          />
        </div>
      )}
      <Items
        name="Variables"
        items={data.variables || []}
        updateItems={(items) => setData({ ...data, variables: items })}
        vars={vars}
      />
      </section>
      
      
    </div>
  );
};

export const ZapierNode = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <div
      className={
        "App "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "") 
      }
    >
      <div style={{ backgroundColor: "#D4FAFF", border: selected ? `solid 2px${"#26C6DA"}` : "" }} className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={nodeName}
          icon={Icons.zapierNodeLogo}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="zapierNodeStyle">
          <div>
            <p>
              POST
            </p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
