
export const loadWASM = async () => {
    // let workflowWasm = await import(/* webpackIgnore: true */ `https://${window.location.hostname}/_wasm/workflow_wasm.js`); // Remote Import
    let workflowWasm = await import(/* webpackIgnore: true */ `https://m2p.idflow.site/_wasm/workflow_wasm.js`); // Remote Import

    // let workflowWasm = await import(/* webpackIgnore: true */ "http://localhost:3000/workflow_wasm.js") // Local Import TODO remove before push
    // TODO change before pushing the changes
    await workflowWasm.default();
    window.wasm = workflowWasm;
  }

export const getWasm = async () =>{
    if (window.wasm === undefined){
        await loadWASM();
    }
    return window.wasm
}