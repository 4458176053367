import React, { createRef, useEffect, useMemo, useRef, useState, lazy, Suspense } from "react";
import { useEvents, useForm, useIsButtonLoading, useIsEditor } from "../../../src/stores/context";
import { Alignment, ButtonLoader, emptyProps, FullContainer, Node, Style } from "../common";
import { registerComponent } from "../components";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { useFonts, useTheme } from "../../../src/stores/selectors";
import { nameInput } from "./input";
// import OTPInput from "react-otp-input";
const OTPInput = lazy(() => import('react-otp-input'));
import * as Yup from "yup";

export const type = "oneTimeCode";


export const inputs = [
  nameInput,
  {
    name: "No of OTP Fields",
    value: "fieldsCount",
    type: "textInput",
    defaultValue: 4,
    requried: true,
    autoFocus: true
  },
  {
    name: "Placeholder",
    value: "placeHolder",
    type: "textInput",
    defaultValue: 4,
    requried: true,
    autoFocus: true
  },
  {
    name: "Border Radius",
    value: "radius",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Box Shadow",
    value: "boxShadow",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Label",
    value: "label",
    type: "textInput",
    requried: true,
    autoFocus: true,
    placeHolder: "Enter ...!"
  },
  {
    name: "Label Color",
    value: "textColor",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Required",
    value: "required",
    type: "toggle",
    defaultValue: true
  },
  {
    name: "Color",
    value: "color",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Background Color",
    value: "bgColor",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Counter ",
    value: "counter",
    placeHolder: "In Seconds",
    type: "numberInput",
    defaultValue: 30
  },
  {
    name: "Counter Format",
    value: "counterFormat",
    type: "textInput",
    defaultValue: "{{m}}:{{s}} secs"
  },
  {
    name: "Resend Button",
    value: "resendButton",
    type: "textInput",
    defaultValue: "Resend OTP"
  },
  FullContainer,
  Alignment,
  Style,
  {
    name: "Required",
    value: "required",
    type: "toggle",
    defaultValue: true
  },
];

export const OTP = {
  name: "OTP",
  type,
  props: { ...emptyProps }
};

export const OtpNode = () => {
  return (
    <Node type={type}>
      <div className="page-side-node br-right">
        <img src={PageBuilderAssets?.otpIcon} className="node-img" />
        <p className="node-name mb-2">OTP</p>
      </div>
    </Node>
  );
};

let nunjucks = require("nunjucks");


const useCounter = (counter, active = false) => {
  const [currentCounter, setCurrentCounter] = useState(counter);
  const interval = useRef(0);

  useEffect(() => {
    if (active) {
      let i = setInterval(() => {
        setCurrentCounter(counter => {
          if (counter > 0) {
            return --counter
          } else {
            clearInterval(interval.current)
          }
          return 0
        });
      }, 1000);
      interval.current = i;

      return () => clearInterval(i);
    }

  }, []);

  return currentCounter

}

export const OTPPlay = ({
  id,
  name,
  label = "Name",
  style = 'subtitle2',
  textColor = "",
  required = true,
  alignment = "center",
  direction = "row",
  radius = 6,
  boxShadow = "10px 10px 138px -11px rgba(0,0,0,0.75)",
  fieldsCount = 4,
  placeHolder = 4,
  color = "",
  bgColor = "rgb(33 36 39)",
  Alignment = "Left",
  counter = 30,
  counterFormat = "{{m}}:{{s}} secs",
  resendButton = "Resend OTP",
  originalProps = {},

}) => {
  const editor = useIsEditor();
  const { isForm, formik, registerValidator } = useForm();

  const { buttonClick } = useEvents();
  const refs = useRef(Array(fieldsCount).fill(undefined).map(() => createRef()))
  const { isLoading } = useIsButtonLoading(id);
  const fonts = useFonts();
  const font = useMemo(() => fonts[style], [fonts, style]);
  const theme = useTheme();
  const defaultColor = theme === "dark" ? "#f5f5f5" : "#212427";
  const finalLabelColor = textColor || defaultColor;
  const finalInputColor = color || defaultColor;
  const currentCounter = useCounter(parseInt(counter, 10), !editor);

  const handleResendButtonClick = () => {
    if (!editor) {
      buttonClick(id, true)
    }
  }

  useEffect(() => {
    if (!editor && isForm) {
      let yup = Yup.string();
      if (required) {
        yup = yup.required("Required")
      }
      registerValidator(name, yup)
    }
  }, [])

  return (
    <>
      <div className="PBmainheadform">
        <label htmlFor="name" className="ml-1"
          style={{
            fontSize: font.size,
            fontWeight: font.weight,
            fontFamily: font.family,
            color: finalLabelColor,
            paddingBottom: "5px"
          }}>
          {label} {required && <span className="PB-required-star"> *</span>}
        </label>
        <div className="PB-otp-main" >
          <Suspense fallback={<div>OTP is Loading..</div>}>
            <OTPInput
              value={formik?.values[name]}
              numInputs={fieldsCount}
              placeholder={placeHolder}
              inputStyle={{
                borderRadius: `${Math.floor(radius / 2)}px`,
                boxShadow: `10px 10px ${Math.floor(
                  boxShadow * 1.5
                )}px -10px rgba(0,0,0,0.75)`,
                border: `${theme === "light" ? "1px solid #212427" : "1px solid rgb(137 137 137)"}`,
                color: finalInputColor,
                background: `${theme === "light" ? "#fff" : bgColor}`,
                width: "2.6rem",
                height: '2.6rem',

              }}
              className="PB-secret-inp"

              renderInput={props => <input
                {...props}
                disabled={editor}
                type="number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />}
              onChange={otp => formik?.setFieldValue(name, otp)} />
          </Suspense>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <li style={{
            color: "#7D8895",
            fontSize: "12px",
            fontWeight: "400",
            marginTop: "10px"
          }}>{editor ? counterFormat : nunjucks.renderString(originalProps?.counterFormat, { m: 0, s: currentCounter, h: 0 })}</li>
          <li style={{ fontSize: "12px", fontWeight: "400", color: "#151515", marginTop: "10px" }}>
            {isLoading ? <ButtonLoader color={finalLabelColor} /> :

              <a disabled={editor || currentCounter !== 0} onClick={handleResendButtonClick}>
                {resendButton}
              </a>}
          </li>
        </div>
      </div>
    </>
  );
};


registerComponent(type, {
  item: OTP,
  play: OTPPlay,
  node: OtpNode,
  inputs,
  isInput: true,
  category: "Inputs"
});
