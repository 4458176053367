import headingIcon from './Heading.svg';
import idflowLogo from './id-flow-logo.svg';
import errorIcon from './ErrorIcon.svg';
import paraIcon from './ParagraphIcon.svg';
import searchIcon from './SearchIcon.svg';
import saveIocn from './SaveIcon.svg';
import leftAlign from './AlignLeft.svg';
import centerAlign from './AlignCenter.svg';
import rightAlign from './AlignRight.svg';
import rowIcon from './FlexRowIcon.svg';
import columnIcon from './FlexColumnIcon.svg';
import deleteIcon from './DeleteIcon.svg';
import deleteIconBlack from './DeleteBlack.svg';
import addIcon from './AddIcon.svg';
import resetIcon from './ResetIcon.svg';
import cameraCloseIcon from './CameraCloseIcon.svg';
import cameraShutterIcon from './CameraShutterIcon.svg';
import uploadIcon from './UploadIcon.svg';
import panDemo from './PanDemo.svg';
import rotateIcon from './RotateIcon.svg';
import staticCamera from './staticCamera.svg';
import cropTemp from './cropTemp.svg';

// Basic Assets

import dividerIcon from './Divider.svg';
import containerIcon from './Container.svg';
import formIcon from './Form.svg';
import linkIcon from './Link.svg';
import buttonIcon from './Button.svg';
import timerBtn from './TimerBtn.svg';
import cameraIcon from './Camera.svg';
import imageIcon from './Image.svg';
import logoIcon from './Logo.svg';
import fileUploadIcon from './FileUpload.svg';
import selectIcon from './Selector.svg';
import inputIcon from "./Input.svg";
import checkboxIcon from './CheckBox.svg';
import radioIcon from './RadioBtn.svg';
import otpIcon from './Otp.svg';
import comingSoon from './comingsoon.gif';
import imageCrop from './ImageCrop.svg';

const PageBuilderAssets = {
    dividerIcon,
    containerIcon,
    formIcon,
    headingIcon,
    linkIcon,
    buttonIcon,
    timerBtn,
    cameraIcon,
    imageIcon,
    logoIcon,
    fileUploadIcon,
    inputIcon,
    checkboxIcon,
    radioIcon,
    otpIcon,
    cropTemp,
    errorIcon,
    paraIcon,
    selectIcon,
    searchIcon,
    saveIocn,
    leftAlign,
    centerAlign,
    rightAlign,
    rowIcon,
    columnIcon,
    deleteIcon,
    deleteIconBlack,
    addIcon,
    resetIcon,
    idflowLogo,
    cameraCloseIcon,
    cameraShutterIcon,
    uploadIcon,
    panDemo,
    rotateIcon,
    staticCamera,
    comingSoon,
    imageCrop
}

export default PageBuilderAssets;