import { emptyProps, Node } from "../common";
import { allType, Components, getItem, inputItems, registerComponent } from "../components";
import {
    FormContext,
    useComponent,
    useDropAccept,
    useEvents,
    useIsEditor,
    useRenderedValues, useVars
} from "../../../src/stores/context";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { inputs as containerInputs, ContainerPlay } from "./container";
import { useFormik } from "formik";
import { useMemo, useRef } from "react";
import { findComponentsByType } from "../../../src/stores/util";
import * as Yup from "yup";
export const type = "Form";

export const inputs = [{
    name: "Name",
    value: "name",
    type: "textInput",
    required: true,
},
...containerInputs
];

export const Form = {
    name: "Form",
    type,
    props: emptyProps,
};

export const FormNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.formIcon} className="node-img" />
                <p className="node-name mb-2">Form</p>
            </div>
        </Node>
    );
};

export const FormInner = (props) => {
    let {
        id,
        components
    } = props;

    const editor = useIsEditor();
    const { formSubmit } = useEvents();
    const validators = useRef({});
    const submittingButton = useRef(id);
    const render = useRenderedValues();
    const vars = useVars();

    const initialValues = useMemo(() => {
        let allInputsTypes = inputItems();

        let items = findComponentsByType(components, allInputsTypes);

        console.log("Items ", items)
        let values = {};
        items.forEach(item => {
            let name = item?.props?.name;
            if (!name){
                console.warn("Name not found for item --> please fix this issue.", item);
            }
            if (name !== undefined) {
                let value = item?.props?.value;
                if (!editor){
                    console.log('rendering somethig',name, value, render(value))
                }
                values[name] = editor ? value: render(value)
            }
        })
        return values;
    }, [components, editor, vars])

    const formik = useFormik({
        initialValues,
        validationSchema: () => (Yup.object(validators.current)),
        onSubmit: values => {
            console.log("Form is Submitting", values)
            formSubmit(submittingButton.current, values)
        }
    })

    const formSubmitHandle = (buttonId) => {
        if (editor) {
            return undefined
        }
        submittingButton.current = buttonId;

        formik.submitForm().then(() => console.log("Form is submitted"));
    }

    const registerValidator = (name, yup) =>{
        validators.current[name] = yup
    }

    const handleSubmit = e =>{
        console.log(e)
        e.preventDefault()
        e.stopPropagation()
        alert("Realoding")
        formik.handleSubmit(e)
    }

    return (
      <form onSubmit={handleSubmit}>
          <FormContext.Provider value={{ isForm: true, formik, formSubmitHandle, registerValidator}} >
              <ContainerPlay {...props}/>
          </FormContext.Provider>
      </form>
    );
};


export const FormPlayEditor = (props) => {
    const { components } = useComponent(props.id);

    const { drop, isOver } = useDropAccept(props.id, allType(), getItem);

    return (
        <div style={{ background: isOver ? "lightgreen" : "" }} ref={drop}>
            <FormInner {...props} components={components} />
        </div>
    );
};

export const FormPlay = props => {
    const editor = useIsEditor();

    let Tag = editor ? FormPlayEditor : FormInner;

    return <Tag {...props} />
}


registerComponent(type, {
    item: Form,
    play: FormPlay,
    node: FormNode,
    inputs,
    category: "Basic",
});
