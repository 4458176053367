import { Alignment, FullContainer, Style, Node, emptyProps } from "../common";
import React, { useMemo } from "react";
import { registerComponent } from "../components";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { useFonts, useTheme } from "../../../src/stores/selectors";
export const type = "Text";

export const inputs = [
    {
        name: "Text",
        value: "text",
        type: "textInput",
        requried: true,
        autoFocus: true,
        placeHolder: "Enter Text",
    },
    {
        name: "Width",
        value: "width",
        type: "numberInput",
        required: true,
    },
    {
        name: "Height",
        value: "height",
        type: "numberInput",
        required: true,
    },
    {
        name: "Horizonal Padding",
        value: "hPadding",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Vertical Padding",
        value: "vPadding",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Margin Top",
        value: "margintop",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Margin Bottom",
        value: "marginbottom",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Margin Left",
        value: "marginleft",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Margin Right",
        value: "marginright",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Line Height",
        value: "lineheight",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Color",
        value: "textColor",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
    {
        name: "Background Color",
        value: "bgcolor",
        type: "colorInput",
        required: true,
        autoFocus: false,
      },
      {
        name: "Border Radius",
        value: "borderRad",
        type: "progressInput",
        required: true,
        default: 0,
      },
    Style,
    Alignment,
    FullContainer,
];
export const Text = {
    name: "Text",
    type,
    props: emptyProps,
};
export const TextNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.headingIcon} className="node-img" />
                <p className="node-name mb-2">Heading</p>
            </div>
        </Node>
    );
};
export const TextPlay = ({
    id,
    text = "Text",
    width="",
    height="",
    style = "H3",
    alignment = "left",
    hPadding = 0,
    vPadding = 0,
    margintop=0,
    marginbottom=0,
    marginleft=0,
    marginright=0,
    textColor = "",
    bgcolor,
    borderRad,
    lineheight
}) => {
    const fonts = useFonts();
    const font = useMemo(() => fonts[style], [fonts, style]);
    const theme = useTheme();
    const defaultColor = theme === "dark" ? "#f5f5f5" : "#212427";
    const finalTextColor = textColor || defaultColor;
    // console.log(fonts)
    return (
        <>
            <div>
                <p
                    style={{
                        width:`${width}px`,
                        height:`${height}px`,
                        fontSize: font.size,
                        fontWeight: font.weight,
                        fontFamily: font.family,
                        margin: `${margintop}px ${marginright}px ${marginbottom}px ${marginleft}`,
                        textAlign: alignment,
                        padding: `${vPadding}px ${hPadding}px`,
                        color: finalTextColor,
                        // lineHeight:lineheight,
                        lineHeight: lineheight === 0 ? "normal" :`${parseInt(lineheight)}px`,
                        backgroundColor:bgcolor,
                        borderRadius:borderRad
                    }}
                    dangerouslySetInnerHTML={{__html: text}}
                    className="Idflow_heading"
                >
                </p>    
            </div>
        </>
    );
};

registerComponent(type, {
    item: Text,
    play: TextPlay,
    node: TextNode,
    inputs,
    category: "Text",
});
