import React, { useState } from "react";
import "./index.css";


/**
 *
 * @param {Array} colorsArray
 * @param {Boolean} BorderRadius
 * @param {Boolean} BoxShadow
 * @returns Object
 */
const ColorCode = ({ colorsArray, colors, setColors}) => {

  const [colorCodes, setColorCodes] = useState(
    colorsArray?.map((color) => color.code)
  );

  return (
    <div className="colorGrid">
      {colorsArray?.map((colorObj, index) => (
          <div className="colorpicker_main" key={index}>
            <div className="slider_label">{colorObj.title}</div>
            <label className="colorPicker">
              <input
                className="colorPicker_boxes"
                type="color"
                style={{ outlineColor: `${index}` , borderRadius:"6px 0px 0px 6px"}}
                value={colors[colorObj.value]}
                onChange={(e) => {
                  setColors({...colors, [colorObj.value]: e.target.value})
                }}
              />
              <span className="color_code">{colors[colorObj.value]}</span>
            </label>
          </div>
      ))}
    </div>
  );
};

export default ColorCode;
