import { commonInputs, NodeHeader } from "../Common";
import { webHookColor } from "../../../../assets/imageUrl";
import { Handle, Position } from "reactflow";

export const inputs = [
    ...commonInputs,
    {
        name: "Active",
        value: "active",
        defaultValue: true,
        type: "boolInput",

    },
    {
        name: "Schedule",
        value: "cron",
        defaultValue: "30 5 * * 1,6", // Every day at 12 AM
        type: 'cronInput',
    }
]

export const Scheduler = ({ id, data, selected }) => {

    return <div

        className={"App "}
    >
        <div
            style={{ backgroundColor: "#E8F4FF", border: selected ? `solid 2px${"#00AAFF"}` : "" }}
            className={`${"set_msgcard"} ${"commonHeader"}`}>
            <NodeHeader
                name="Scheduler"
                icon={webHookColor}
                noHandle
            />

            <div className="webHookNodeStyle">
                <div>
                    <p>{data.name || "Post"}</p>
                    {/* <p>
                            {data.url.length > MAX_CHARS
                                ? data.url.substring(0, MAX_CHARS) + "..."
                                : data.url}
                        </p> */}
                    <Handle
                        type="source"
                        position={Position.Right}
                        className="commonHandleStyle"
                    />
                </div>
            </div>
        </div>

    </div>

}