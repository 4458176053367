export function findComponentById(obj, id) {
    if (obj.id === id) {
        return obj;
    }

    if (obj.components) {
        for (let i = 0; i < obj.components.length; i++) {
            const foundComponent = findComponentById(obj.components[i], id);
            if (foundComponent) {
                return foundComponent;
            }
        }
    }

    return null;
}

export function findComponentsByType(obj, types) {

    let array = []
    if (obj === undefined){
        return array
    }
    if (Array.isArray(obj)){
        for (let i = 0; i < obj.length; i++){
            let list = findComponentsByType(obj[i], types)
            array = array.concat(list)
        }
    }else{
        if (types.includes(obj.type)){
            array.push(obj)
        }
        if (obj?.components?.length !== 0){
            let list = findComponentsByType(obj.components, types);
            array = array.concat(list)
        }
    }

    return array
}

export function deleteComponentById(obj, id) {
    if (obj.id === id) {
        return null;
    }

    if (obj.components) {
        const filteredComponents = [];

        for (let i = 0; i < obj.components.length; i++) {
            const updatedComponent = deleteComponentById(obj.components[i], id);

            if (updatedComponent) {
                filteredComponents.push(updatedComponent);
            }
        }

        obj.components = filteredComponents;
    }

    return obj;
}

export function replaceComponentById(obj, id, replacementComponent) {
    if (obj.id === id) {
        return replacementComponent;
    }

    if (obj.components) {
        const updatedComponents = [];

        for (let i = 0; i < obj.components.length; i++) {
            const updatedComponent = replaceComponentById(
                obj.components[i],
                id,
                replacementComponent
            );

            if (updatedComponent) {
                updatedComponents.push(updatedComponent);
            }
        }

        obj.components = updatedComponents;
    }

    return obj;
}

export function moveComponentById(obj, id, targetId, index) {
    let component = findComponentById(obj, id);
    if (component) {
        // TODO Write performant algorithm
        deleteComponentById(obj, id);
    }

    let target = findComponentById(obj, targetId);
    if (target && target.components) {
        target.components.splice(index, 0, component);
    }

    return obj;
}

export function isURL(str) {
    // Regular expression pattern to match URLs
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(str);
}

const BASE64 = "data:image/jpeg;base64"

export const getBaseURL = string => `${BASE64},${string}`;
export const removeBasePath = string => {
    return string.replace(/^data:image\/[a-z]+;base64,/, "")
};

export const getURL = image => isURL(image) ? image: getBaseURL(image)



export const removeFunctions = obj =>{
    const newObj = {};

    Object.keys(obj).forEach(key =>{
        let value = obj[key];
        if (typeof value !== "function"){
            newObj[key] = value
        }
    })
    return newObj
}


export function generateId(length=10) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}