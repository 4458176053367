import { MainContainer } from "./components/blocks/mainContainer";
import {generateId} from "./src/stores/util";

export const defaultPage = () => ({
  id: generateId(10),
  name: "Page New",
  components: [
    {
      id: "main",
      ...MainContainer
    }]
})
