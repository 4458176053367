import { useIsEditor } from "../../../src/stores/context";
import { Alignment, FullContainer, Style, Node, emptyProps } from "../common";
import { registerComponent } from "../components";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";

export const type = "Divider";

export const inputs = [


    {
        name: "Text",
        value: "dividerText",
        type: "textInput",
        required: true,
        autoFocus: true,
    },
    {
        name: "Color",
        value: "textColor",
        type: "colorInput",
        autoFocus: true,
    },
    {
        name: "Line Color",
        value: "lineColor",
        type: "colorInput",
        autoFocus: true,
    },
    Style,
    FullContainer,
];

export const Divider = {
    name: "Divider",
    type,
    props: emptyProps,
};

export const DividerNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.dividerIcon} className="node-img" />
                <p className="node-name mb-2">Divider</p>
            </div>
        </Node>
    );
};


export const DividerPlay = ({
    id,
    dividerText = 'OR',
    textColor = "#fff",
    lineColor = "#gray",
    style,
    output,
}) => {
    const editor = useIsEditor();
    return (
        <>
            <div
                className="PBmainheadform"
            >

                <div className="PDdividermain">
                    <div className="PBdivider" style={{ borderBottom: `2px solid ${lineColor}` }}></div>
                    <p className="mb-0" style={{
                        fontSize: parseInt(style),
                        color: textColor,
                    }}>{dividerText}</p>
                    <div className="PBdivider" style={{ borderBottom: `2px solid ${lineColor}` }}></div>

                </div>
            </div>
        </>
    );
};


registerComponent(type, {
    item: Divider,
    play: DividerPlay,
    node: DividerNode,
    inputs,
    category: "Basic",
});
