import { NodeHeader, StartNode,VariablePick } from "../../Common";
import { TbVariable } from "react-icons/tb";
import { Items } from "../APICall/Apicall";
import {
  Handle,
  Position,
} from "reactflow";
import React, { useState, useEffect } from "react";
import Icons from "../../../../../assets/imageUrl";
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader";
import { useNodeData } from "../../../../store/selector";
import {AddButton,DeleteButton} from '../../../../CommonButtons'
import { generateId } from "../../../../../utils/store";
import {TextInput} from "../../../../components/inputs";
import {Select} from "../../../../components/select";

const nodeName = "SwitchNode";


export const SwitchNodePanel = ({ id, type = nodeName, vars = [] }) => {
    
  
  const [data, setData] = useNodeData(id);
    const options = [
        {name: "Select Operator", value:"" },
        {name: "=", value:"Equal" },
        {name: "!=", value:"NotEqual" },
        {name: ">", value:"GreaterThan" },
        {name: "<", value:"LessThan" },
        {name: ">=", value:"GreaterThan or Equal" },
        {name: "=<", value:"LessThan or Equal" },
    ];
  const switchCases = data.switchCases || [];

  const setSwitchCases = (SwitchCase) => {
    setData({ ...data, switchCases: SwitchCase });
  };

  if (typeof switchCases === "undefined") {
    setCases([]);
  }

  function deleteSwitchCases(switchCase){ 
    setSwitchCases(switchCases.filter((c) => switchCase.id !== c.id))
  }

  function callAddSwitchCases(){
    setSwitchCases([
      ...switchCases,
      { id: generateId(), value: "" },
    ])
  }

  const SwitchCasesOptions = switchCases.map((switchCase,i) => {
    

    return (

      <li >
      
      <div className="commonDeleteSection">
       <label className="nodePanel-labels">
          Case
         </label>
      <DeleteButton callFunction={deleteSwitchCases} comId={switchCase}/>
       </div>
         <div>
          <label className="nodePanel-labels mt-3">Value</label>
         <VariablePick input={switchCase.value} setInput={(value) => {
            setSwitchCases(
                switchCases.map((switchCa) => {
                if (switchCa.id === switchCase.id) {
                  return {
                    ...switchCa,
                    value: value,
                  };
                }
                return switchCa;
              })
            );
          }} vars={vars} />
         </div>
          

      </li>
    )
  });
  return (
    <div className="">
      <SidePanelHeader nodeName={nodeName } />
<section className="sideInnerParent px-3 ptb-20">
    <TextInput
        name={type + " Name"}
        type="text"
        value={data?.name || ""}
        maxLength={15}
        setValue={(value) => setData({ ...data, name: value })}
        placeholder={type + " Name"}
        helpingText="Please give a name to the Switch field"
    />
    <TextInput
        name="Variable"
        type="text"
        value={data?.variable || ""}
        setValue={(value)=> setData({...data,variable:value})}
        placeholder="Variable"
        helpingText="Please give a name to the Switch field"
    />

    <Select
        name="Operator"
        helpingText = "Please give a name to the Switch field"
        value={data?.operator || ""}

        setValue={(value) =>
            setData({ ...data, operator: value })
        }
        options={options}
    />
{/*      <div>*/}
{/*        <label htmlFor="">Operator</label>*/}
{/*        <div className="labelInputFull">*/}
{/*        <select*/}
{/*onChange={(e) =>*/}
{/*    setData({ ...data, operator: options[e.target.selectedIndex - 1] })*/}
{/*  }>*/}
{/*<option>Select Operator</option>*/}
{/*{options.map((option) =>{*/}
{/*return (*/}
{/*  <option selected={option === data.operator}>*/}
{/*    {SymbolMap[option]}*/}
{/*  </option>*/}
{/*)*/}
{/*} )}*/}
{/*</select>*/}
{/*        </div>*/}
{/*      </div>*/}
      <div  >
            {
              switchCases && switchCases.length >=1 ?
              <ul className="commonBackGray">{SwitchCasesOptions}</ul>
                :<></>
            }
                <div className="addConditions">
              <AddButton
                callFunction={callAddSwitchCases}
                opName='Add Cases'
                />
            </div>
          </div>
      </section>
    </div>
  );
};

const SwitchNode = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <div
      className={
        " "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div style={{ backgroundColor: "#E1FFE6", border: selected ? `solid 2px${"#00B51F"}` : "" }} className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={nodeName}
          icon={Icons.updateNodeLogo}
          // onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="updateNodeStyle">
          <div>
            <p>POST</p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchNode;
