import { generateId } from "../../utils/store";
import { NodeTypes } from "../FlowBuilder";
import { nodeInputs } from "../NodePanel";
import { useNodes } from "./flowStore";


export const defaultSettings = {
  brand: {
    theme: "light", // or black,
    animation: {
      loader: "one",
      image: ""
    },
    waterMark: {
      theme: "light",  // blue, light, dark
      position: ""
    },
    logo: {
      black: "",
      white: ""
    },
    colors: {
      button: {
        primary: "#525f7f",
        secondary: "#525f7f",
        textPrimary: "#525f7f",
        textSecondary: "#525f7f",
        borderRadius: 6,
        boxShadow: 6,
      },
      input: {
        background: "#525f7f",
        label: "#525f7f",
        value: "#525f7f",
        placeHolder: "#525f7f",
        border: "#525f7f",
        borderRadius: 6,
        boxShadow: 6,
        focusShadow: 6,
        focusBorder: "#525f7f",
        focus: "#525f7f"

      },
      text: {
        primary: "#525f7f",
        secondary: "#525f7f"

      }
    },
    fonts: {
      H1: {
        size: 32,
        family: "Arial",
        weight: 600
      },
      H2: {
        size: 28,
        family: "Arial",
        weight: 600
      },
      H3: {
        size: 24,
        family: "Arial",
        weight: 600
      },
      subtitle1: {
        size: 20,
        family: "Arial",
        weight: 600
      },
      subtitle2: {
        size: 16,
        family: "Arial",
        weight: 600
      },
      subtitle3: {
        size: 12,
        family: "Arial",
        weight: 600
      },
      body: {
        size: 12,
        family: "Arial",
        weight: 400
      },
    },


  },
  portal: {
    language: "english",
    title: "Idflow || Onboarding Platform",
    // faviconUrl: "",
    // coverImg: "",
    favicon: "",
    displayIcon: "",
    meta: {
      title: "Idflow || Onboarding platform",
      description: "Made with ID Flow",
      url: window.location.host
    }
  }
}

const defaultStartNode = () => ({
  id: generateId(),
  type: "StartNode",
  data: {},
  position: {
    x: 250,
    y: 250
  }
})

export const defaultWorkFlow = (type) => ({
  id: "",
  name: "New Flow",
  nodes: type && type === "api" ? [] : [defaultStartNode()],
  edges: [],
  extra: {},
  viewport: {
    x: 0,
    y: 0,
    zoom: .5
  }
})

export const getExecuteAPIDefaults = (nodeId) => {
  // Some computation is required for ExecuteAPI Node
  let nodes = useNodes.getState().nodes;

  let node = nodes.find(node => node.id === nodeId);

  if (!node) {
    // TODO Handle this case when Node listed and note available in the store
  }

  let inputs = node?.inputs?.map(input => ({
    id: generateId(),
    name: input,
    value: ""
  }));

  let outputs = node?.outputs?.map(output => ({
    id: generateId(),
    status: output.status,
    outputs: output.outputs.map(output => ({
      id: generateId(),
      name: output,
      value: ""
    }))
  }));
  return {
    name: "",
    inputs: inputs || [],
    outputs: outputs || [],
    nodeName: node?.name,
    nodeType: node?.nodeType || NodeTypes.API
  }
}

export const getCodeNodeDefaults = (nodeId, node) => {
  let nodes = useNodes.getState().nodes;
  node = node ? node : nodes.find(node => node.id === nodeId);
  
  return {
    name: node?.name ||  "",
    nodeType: node?.nodeType || NodeTypes.CUSTOM_CODE,
    language: node?.language || "javascript",
    environment: node?.environment || "BackEnd",
    variables: node?.variables ||  [],
    outputs: node?.outputs ||  [],
    inputs: node?.inputs ||  [],
    code: node?.code || `async function handler(options) {
      // Write your own logic here
      let { variables, nodeId, workflowId } = options;
      variables["myNewVar"] = "someVariableValue";
 
      return { variables: variables };
    }`
  }
}

export const getDefaultNodeData = (type, nodeId) => {
  // Handle Execute API Node first

  if (type === NodeTypes.API) {
    return getExecuteAPIDefaults(nodeId)
  }

  if (type === NodeTypes.CUSTOM_CODE) {
    return getCodeNodeDefaults(nodeId);
  }

  let inputs = nodeInputs[type];

  if (!inputs) {
    return {name:""}
  }

  return inputs.reduce((data, input) => {
    data[input.value] = input.defaultValue;
    return data
  }, {})
}