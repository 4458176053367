export const getInputs = (obj) => {
  let result = [];
  if (Array.isArray(obj)) {
    for (const val of obj) {
      result = result.concat(getInputs(val));
    }
  } else if (typeof obj === "object") {
    for (const key in obj) {
      if (key.startsWith("$")) {
        result.push(key.slice(1));
      } else {
        result = result.concat(getInputs(obj[key]));
      }
      if (typeof obj[key] === "string") {
        const matches = obj[key].match(/\{\{(\w+)\}\}/g);
        if (matches) {
          for (const match of matches) {
            result.push(match.slice(2, -2));
          }
        }
        if (obj[key].startsWith("$")) {
          result.push(obj[key].slice(1));
        }
      }
    }
  }
  return result;
};

export const getAllStringValues = (obj) => {
  let result = [];
  if (Array.isArray(obj)) {
    for (const val of obj) {
      result = result.concat(getAllStringValues(val));
    }
  } else if (typeof obj === "object") {
    for (const key in obj) {
      result = result.concat(getAllStringValues(obj[key]));
    }
  } else if (typeof obj === "string") {
    result.push(obj);
  }
  return result;
};

const areAllValuesString = (obj) => {
  if (Array.isArray(obj)) {
    return obj.every((val) => areAllValuesString(val));
  } else if (typeof obj === "object") {
    for (const key in obj) {
      if (!areAllValuesString(obj[key])) {
        return false;
      }
    }
    return true;
  } else {
    return typeof obj === "string";
  }
};

export const isValidPattern = (inputValue) => {
  const pattern = /\{\{([^}]+)\}\}/;
  const match = inputValue.match(pattern);
  if (match) {
    const extractedContent = match[1];
    return extractedContent;
  }
};

export function generateId(length = 10) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export function removeSpaceFromObj(data) {
  if (typeof(data) === "object" && data != undefined) {
    Object.entries(data).forEach(([key, val]) => {
      if (typeof(val) === "string" && val?.includes("{") && val?.includes("}")) {
        let res = val?.trim()?.replace(/\s+/g, '');
        data[key] = res;
        return data;
      } else {
        return data;
      }
    });
  }
  return data;
}

/**
 * 
 * @param {string} url 
 * @description check if URL is valid
 * @returns Boolean
 */
export const isValidUrl = (url) => {
  try {
      new URL(url);
  } catch (e) {
      return false;
  }
  return true;
};

export const useDebounce = (fn, delay) => {
  return (...args) => {
    let timer = setTimeout(() => fn(...args), delay);
    return () => clearTimeout(timer);
  };
};