import "bootstrap/dist/css/bootstrap.min.css";
import { createBrowserRouter } from "react-router-dom";
import {RouterProvider} from "react-router-dom";
import {BrowserRouter} from "react-router-dom";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import "../../components/src/CommonPagebuilder.css";
import { PageBuilder } from "./PageBuilder/pagebuilder";
import "./styles/workflow.css";
import "./styles/workFlowRightPanel.css";
import "./styles/workSpace.css";
import WorkFlow from "./WorkFlow";
import { WorkFlowNew } from "./WorkFlow/workflow";
import WorkFlowBuilder from "./WorkFlowBuilder/FlowBuilder";

import BrandSetting from "./Components/settings/BrandSetting";
import PortalSetting from "./Components/settings/PortalSetting";

import { ErrorPage, PageNotFound } from "@idflow/styles";
import { CreateNode, NodeBuilderView, NodesListing } from 'nodes';
import "react-loading-skeleton/dist/skeleton.css";
import { QueryClient, QueryClientProvider } from "react-query";
import WorkflowPreview from './Components/mockup/workflowPreview';
import SettingLayout from './SettingLayout';
import Flow from "./WorkFlowBuilder/Flow";
import SidePanel from "./WorkFlowBuilder/SidePanel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { ProtectedRoute } from "@idflow/styles";
import { CustomerPreview } from "components/app";
import NodeLayout from "./NodeLayout";

const path = (p) => "/home" + p;

const router = createBrowserRouter([
    {
        path: path("/workflows"),
        element: <ProtectedRoute> <WorkFlowNew /> </ProtectedRoute>,
        children: [
            {
                index: true,
                element: <WorkFlow />,
            },
            {
                path: ":workflowId",
                element: <WorkFlowBuilder />,
                children: [
                    {
                        index: true,
                        element: (
                            <div className="main_rajer">
                                <SidePanel />
                                <Flow />
                            </div>
                        ),
                    },
                    {
                        element: <WorkflowPreview />,
                        path: "preview",
                    },
                    {
                        element: <CreateNode wClassName="workflow-css" isBreadCrumb={false} />,
                        path: "create-node",
                    },
                    {
                        path: "list-node",
                        // element: <NodesListing wClassName="workflow-css-listing" />,
                        element: <NodeLayout />,
                        children: [
                            {
                                element: <NodesListing wClassName="workflow-css-listing" />,
                                index: true,
                            },
                            {
                                element: <CreateNode wClassName="workflow-css" isBreadCrumb={false} />,
                                path: "create", 
                            },
                            {
                                element: <CreateNode wClassName="workflow-css" isBreadCrumb={false} />,
                                path: "edit/:nodeId",
                            },
                            {
                                element: <NodeBuilderView wClassName="workflow-css-listing" isBreadCrumb={false} />,
                                path: ":nodeId",
                            },
                        ]
                    },
                    {
                        path: "pages/",
                        element: <PageBuilder />,
                    },
                    {
                        path: "pages/:pageId",
                        element: <PageBuilder />,
                    },
                    
                    {
                        path: "settings",
                        element: <SettingLayout />,
                        children: [
                            {
                                index: true,
                                element: <BrandSetting />,
                            },
                            {
                                path: "brand",
                                element: <BrandSetting />,
                            },
                            {
                                path: "portal",
                                element: <PortalSetting />,
                            },
                        ],
                    },
                ],
            },
        ],
        errorElement: <ErrorPage />
    },
    {
        path: path("/workflows/preview/:workflowId"),
        element: <CustomerPreview />,
        errorElement: <ErrorPage />

    },
    {

        path: "*",
        element: <PageNotFound />
    }
]);

const queryClient = new QueryClient();
// const DSN = "https://48ed957d34d342cbbbfe55ed9c4da998@o4505214857838592.ingest.sentry.io/4505584043884544";

// Sentry.init({
//     dsn: DSN,
//     integrations: [new Sentry.BrowserTracing()],
//     tracesSampleRate: 1.0,
// })

// const App = () => {
//     return (

//         <QueryClientProvider client={queryClient}>
//             <RouterProvider router={router}></RouterProvider>
//         </QueryClientProvider>

//     );
// };

// const App = () => {
//     return (
//         <QueryClientProvider client={queryClient}>
//             <BrowserRouter> 
//                 <Routes>
//                     <Route path="/home/workflows" element={<WorkFlowNew/>}>
//                         <Route path="/home/workflows" element={<WorkFlow/>}/>
//                         <Route path="/home/workflows/:workflowId" element={<WorkFlowBuilder/>}>
//                             <Route path="/home/workflows/:workflowId" element={<div className="main_rajer">
//                                         <SidePanel />
//                                         <Flow />
//                                     </div>}/>
//                             <Route path="/home/workflows/:workflowId/preview" element={<WorkflowPreview/>}/>
//                             <Route path="/home/workflows/:workflowId/pages" element={<PageBuilder/>}/>
//                             <Route path="/home/workflows/:workflowId/pages/:pageId" element={<PageBuilder/>}/>
//                             <Route path="/home/workflows/:workflowId/settings" element={<SettingLayout/>}>
//                                 <Route path="/home/workflows/:workflowId/settings" element={<BrandSetting/>}/>
//                                 <Route path="/home/workflows/:workflowId/settings/brand" element={<BrandSetting/>}/>
//                                 <Route path="/home/workflows/:workflowId/settings/portal" element={<PortalSetting/>}/>
//                             </Route>   
//                         </Route>
//                     </Route>
//                     <Route path="/home/workflows/preview/:workflowId" element={<CustomerPreview/>}/>
//                     <Route from="*" element={<PageNotFound />} />
//                 </Routes>
//              </BrowserRouter>
//         </QueryClientProvider>
//     );
// };

const isWorkflowPanel = localStorage.getItem("isWorkflowPanel") === "true" ? "/admin" : "";
const App = () => {
    return (
        <>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter> 
                <Routes>
                    <Route  path={isWorkflowPanel + "/home/workflows"} element={<WorkFlowNew/>}>
                        <Route path={isWorkflowPanel + "/home/workflows"} element={<WorkFlow/>}/>
                        <Route path={isWorkflowPanel + "/home/workflows/:workflowId"} element={<WorkFlowBuilder/>}>
                            <Route path={isWorkflowPanel +"/home/workflows/:workflowId"} element={<div className="main_rajer">
                                        <SidePanel />
                                        <Flow />
                                    </div>}/>
                            <Route path={isWorkflowPanel +"/home/workflows/:workflowId/preview"} element={<WorkflowPreview/>}/>
                            <Route path={isWorkflowPanel +"/home/workflows/:workflowId/create-node"} element={<CreateNode wClassName="workflow-css" isBreadCrumb={false} />}/>
                            <Route path={isWorkflowPanel +"/home/workflows/:workflowId/list-node"} element={<NodeLayout/>}>
                                <Route path={isWorkflowPanel +"/home/workflows/:workflowId/list-node"} element={<NodesListing wClassName="workflow-css-listing" />}/>
                                <Route path={isWorkflowPanel +"/home/workflows/:workflowId/list-node/create"} element={<CreateNode wClassName="workflow-css" isBreadCrumb={false} />}/>
                                <Route path={isWorkflowPanel +"/home/workflows/:workflowId/list-node/edit/:nodeId"} element={<CreateNode wClassName="workflow-css" isBreadCrumb={false} />}/>
                                <Route path={isWorkflowPanel +"/home/workflows/:workflowId/list-node/:nodeId"} element={<NodeBuilderView wClassName="workflow-css-listing" isBreadCrumb={false} />}/>
                            </Route>
                            <Route path={isWorkflowPanel +"/home/workflows/:workflowId/pages"} element={<PageBuilder/>}/>
                            <Route path={isWorkflowPanel +"/home/workflows/:workflowId/pages/:pageId"} element={<PageBuilder/>}/>
                            <Route path={isWorkflowPanel +"/home/workflows/:workflowId/settings"} element={<SettingLayout/>}>
                                <Route path={isWorkflowPanel +"/home/workflows/:workflowId/settings"} element={<BrandSetting/>}/>
                                <Route path={isWorkflowPanel +"/home/workflows/:workflowId/settings/brand"} element={<BrandSetting/>}/>
                                <Route path={isWorkflowPanel +"/home/workflows/:workflowId/settings/portal"} element={<PortalSetting/>}/>
                            </Route>   
                        </Route>
                    </Route>
                    <Route path={isWorkflowPanel +"/home/workflows/preview/:workflowId"} element={<CustomerPreview/>}/>
                    <Route from="*" element={<PageNotFound />} />
                </Routes>
             </BrowserRouter>
        </QueryClientProvider>
        <ToastContainer />
        </>
    );
};

export default App;
