import Resource from "../api/resource";

import { useLoaderData, NavLink, Outlet, redirect } from "react-router-dom";
const crud = Resource("workflows");


export const WorkFlowNew = () => {

  return (
    <>
        <Outlet />
    </>
  );
};
