import { BASE_URL as base_url, globalCommonRequest, globalCommonRequestFile, globalCommonRequestV1, globalImageRequest } from "@idflow/styles";
import { redirect } from "react-router-dom";

export const IMAGE_URL = `${base_url()}v1/files/`;
export const BASE_URL = base_url();

const ACCESS_TOKEN_KEY = "accessToken";

export const CLOUD_API_WEBHOOK = BASE_URL + "v2/messages/facebook/CloudAPI";
export const DIALOG_360_WEBHOOK = BASE_URL + "v2/messages/";

export function accessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}
export function clearAccessToken(token) {
  localStorage.clear();
}

export function universalError(err) {
  const status_code = err.response.status;
  if (status_code === 401) {
    return redirect("/login");
  }
}

export const METHODS = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
};

// export const request = async (method, v, path, body) => {
//   // return []
//   try {
//     const response = await axios({
//       url: BASE_URL + v + path,
//       method: method,
//       headers: {
//         Authorization: "Bearer " + accessToken(),
//       },
//       data: body,
//     });

//     return response.data;
//   } catch (e) {
//     if (e.response.status === 401) {
//       return redirect("/login");
//     }
//   }
// };

// export const imageRequest = async (v, path, body) => {
//   try {
//     const url = BASE_URL + v + path;
//     const formData = new FormData();
//     formData.append("file", body, body.name);
//     const response = await axios.post(url, formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ` + accessToken() 
//       },
//       data: body
//     });

//     return response.data;
//   } catch (err) {
//     throw err;
//   }
// };

// export const commonRequest = async (method, path, body) => {
//   return await request(method, "v1", path, body);
// };

// export const commonRequestV1 = (method, path, body, onSuccess, onFailure) => {
//   return request(method, "v1", path, body, onSuccess, onFailure);
// };

// export const imageRequestV1 = (path, body, onSuccess, onFailure) => {
//   return imageRequest("v1", path, body, onSuccess, onFailure);
// };


export const commonRequest = async (method, path, body) => {
  return await globalCommonRequest(method, path, body);
};

export const commonRequestV1 = async (method, path, body, onSuccess, onFailure) => {
  return await globalCommonRequestV1(method, path, body, onSuccess, onFailure);
};
export const commonRequestV1File = async (
  method,
  path,
  body,
  onSuccess,
  onFailure
) => {
  return await globalCommonRequestFile(method, path, body, onSuccess, onFailure);
};

export const imageRequestV1 = async (path, body, onSuccess, onFailure) => {
  return await globalImageRequest(path, body, onSuccess, onFailure);
}
