import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Details from "./details";
import "./index.css";

export const CreateNode = ({ wClassName, isBreadCrumb}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const routeName = location.pathname;
  const isEdit = routeName.includes("edit");
  const nodeId = routeName.split("/").pop();

  if (!nodeId && isEdit === true) {
    navigate("/nodes");
  }

  return (
    <div className={`main_div ${wClassName}`}>
      <div className=" body_component">
        <Details isEdit={isEdit} nodeId={nodeId} isBreadCrumb={isBreadCrumb} />
      </div>
    </div>
  );
};

