import React from "react";
import '../index.css';
import Skeleton from "react-loading-skeleton";
export default function PortalLoader() {
  return (
    <div className="main">
      <div className="title-sec">
        <div>
          <p className="title"><Skeleton width={100} height={20}/></p>
          <p className="desc"><Skeleton width={250} height={15}/></p>
        </div>
        <Skeleton width={120} height={30}/>
      </div>
      <div className="lang-sec row w-100 m-0 py-4 px-3">
        <div className="col-lg-5 p-3">
          <p className="lang-title"><Skeleton width={100} height={20}/></p>
          <p className="desc"><Skeleton width={150} height={15}/></p>
        </div>
        <div className="col-lg-7 p-3 m-auto">
          <Skeleton width={200} height={25}/>
        </div>
      </div>
      <div className="favi-sec row w-100 mt-4 mx-0 py-4 px-3">
        <div className="col-lg-5 p-3">
          <p className="lang-title"><Skeleton width={100} height={20}/></p>
          <p className="desc"><Skeleton width={150} height={15}/></p>
          <Skeleton width={80} height={80}/>
          <div>
          <Skeleton width={120} height={30}/>
          </div>
        </div>
        <div className="col-lg-7 p-3">
          <p className="img-title"> <Skeleton width={100} height={20}/></p>
          <Skeleton width={'90%'} height={60}/>
        </div>
      </div>
      <div className="web-sec row w-100 mt-4 mx-0 pt-4 px-3">
        <div className="col-lg-5 p-3">
          <p className="lang-title"><Skeleton width={100} height={20}/></p>
          <p className="desc">
            <Skeleton width={150} height={15}/> 
            </p>

          <Skeleton  width={300} height={140}/>
          <div style={{marginTop:'1rem'}}>
          <Skeleton width={120} height={40}/>
          </div>
        </div>
        <div className="col-lg-7 p-3">
          <p className="img-title"> <Skeleton width={100} height={20}/></p>
            <div className="image1">
               <Skeleton width={300} height={150}/>
            </div>
        </div>
      </div>
      <div className="web-sec row w-100 mt-4 mx-0 pt-4 px-3">
        <div className="col-lg-5 p-3">
          <p className="lang-title"> <Skeleton width={100} height={20}/></p>
          <p className="desc"><Skeleton width={100} height={15}/> </p>
          <p className="input-title p-0 m-0"><Skeleton width={100} height={10}/></p>
          <Skeleton width={300} height={35}/>
          <p className="desc txt-grey-3"><Skeleton width={150} height={20}/></p>
          <p className="input-title p-0 m-0"><Skeleton width={150} height={20}/></p>
          <Skeleton width={300} height={100}/>
          <p className="desc txt-grey-3"> <Skeleton width={100} height={20}/></p>
        </div>
        <div className="col-lg-7 p-3">
          <p className="img-title">  <Skeleton width={100} height={20}/></p>
          <div className="image1">
          <Skeleton width={300} height={200}/>
          </div>
        </div>
      </div>
    </div>
  );
}
