import React from 'react'

const macFrame = () => {
  return (
<svg
    width={971}
    height={35}
    viewBox="0 0 971 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
        d="M12.1657 0H958.834C965.369 0 970.661 5.37625 970.661 11.9768V34.2803H0.339111V11.9768C0.339111 5.37625 5.6304 0 12.1657 0Z"
        fill="#E4EEF4"
    />
    <path
        d="M27.1497 16.984C27.1497 13.2508 30.1818 10.2188 33.9149 10.2188C37.6481 10.2188 40.6802 13.2508 40.6802 16.984C40.6802 20.7172 37.6481 23.7492 33.9149 23.7492C30.1818 23.7492 27.1497 20.7172 27.1497 16.984Z"
        fill="#E73555"
    />
    <path
        d="M63.9202 23.7492C67.6565 23.7492 70.6854 20.7203 70.6854 16.984C70.6854 13.2477 67.6565 10.2188 63.9202 10.2188C60.1838 10.2188 57.1549 13.2477 57.1549 16.984C57.1549 20.7203 60.1838 23.7492 63.9202 23.7492Z"
        fill="#FECB2F"
    />
    <path
        d="M87.1782 16.984C87.1782 13.2508 90.2103 10.2188 93.9435 10.2188C97.6766 10.2188 100.709 13.2508 100.709 16.984C100.709 20.7172 97.6766 23.7492 93.9435 23.7492C90.2103 23.7492 87.1782 20.7172 87.1782 16.984Z"
        fill="#49AE48"
    />
</svg>  )
}

export default macFrame