import React, { useEffect, useRef } from 'react';

import { useActiveComponent, usePage, useRedo, useUndo } from 'components';

import { Components } from "components";

export const Ground = () => {
    const ground = useRef(null);
    const { components, id } = usePage();
    const { setActive } = useActiveComponent();
    const undo = useUndo();
    const redo = useRedo();

    useEffect(() => {
        const handleKeyDown = (event) => {

            if (event.ctrlKey && event.key === 'z') {
                if (event.shiftKey) {
                    // Handle Ctrl + Shift + Z
                    console.log("sht +z")
                    redo()
                } else {
                    // Handle Ctrl + Z
                    console.log("ctrl +z")
                    undo()
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    return (
        <div className="w-60 p-0" onClick={() => setActive(undefined)} ref={ground}>
            <section className="PBmaingrid">
                <div className='PBmidtest ground' style={{}} >
                    <div className='PBmidgrid'>
                        <Components components={components} id={id} />
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Ground