import React from 'react'

const plus_icon = ({onClick}) => {
  return (
<svg width="23" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={{ cursor: "pointer"}}>
<path d="M14 8.99799H8V14.998H6V8.99799H0V6.99799H6V0.997986H8V6.99799H14V8.99799Z" fill="#212429"/>
</svg>
  )
}

export default plus_icon