
import { JsonEditor as OJsonEditor } from "jsoneditor-react"
import Icons from "../../assets/imageUrl";


export const JsonEditor = ({
    name="JsonEditor",
                               helpingText = "helpingText",
    value={},
    setValue= () => {},
    required=false,
    key
}) =>{
    return <div className="RightBottomBorder">

            <div className="RightLabel">
                <div className="nodePanel-labels">{name}</div>
                <div>
                    <div className="RightLabelImg">
                        <img src={Icons.InfoIcon} alt="IdflowInfoIcon"/>
                        {/*{!!required && <span className="alertRequired">required *</span>}*/}
                        <div className="RightTooltip ">
                            <div className="RightSubtool">
                                <p>{helpingText}</p>
                            </div>

                        </div>
                    </div>


                </div>

        </div>

        <div className="RightJson">
            <OJsonEditor
                key={key}
                id={key}
                mode="text"
                value={value}
                onChange={(value) => setValue(value)}
            />

        </div>

    </div>
 
}