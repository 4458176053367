import React from "react";

export default function LandscapeComp({ title, url, description, displayIcon }) {
  const maxLength = 36; // Maximum length for each line
  const lines = []; // Array to hold the generated lines
  let patternId = "imgmeta"
  let startIndex = 0;
  while (startIndex < description?.length) {
    const line = description.slice(startIndex, startIndex + maxLength);
    lines.push(line);
    startIndex += maxLength;
  }
  return (
    <svg
      width="413"
      height="164"
      viewBox="0 0 413 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_0_1)">

        {displayIcon?.length > 0 ? (
          <pattern
            id={patternId}
            patternUnits="userSpaceOnUse"
            patternContentUnits="objectBoundingBox"
            width="100%"
            height="100%"
          >
            <image
              xlinkHref={displayIcon}
              width={1}
              height={1}
              preserveAspectRatio="xMidYMid slice"
            />
          </pattern>
        ) : (
          <rect x="8" y="6" width="397" height="148" rx="4" fill="white" />
        )}
        <rect width="150px" height="150px" fill={`url(#${patternId})`} />

      </g>
      <path
        d="M152 6H399C402.314 6 405 8.68629 405 12V148C405 151.314 402.314 154 399 154H152V6Z"
        fill="#F1F8FC"
      />

      <text
        x="40%"
        y="25%"
        fill="#212429"
        fontSize={16}
        style={{ fontWeight: "600" }}
      >
        {title?.length === 0 ? "Organization name" : title}
      </text>
      {lines?.length > 0 ? (
        <text x="40%" y="38%" fill="#495057" fontSize={14} style={{ fontWeight: "400" }}>
          {lines.map((line, index) => (
            <tspan x="40%" dy={index === 0 ? "0em" : "1.2em"} key={index}>
              {line}
            </tspan>
          ))}
        </text>
      ) : (
        <text x="40%" y="38%" fill="#495057" fontSize={14} style={{ fontWeight: "400" }}>
          Subheading
        </text>
      )}
      <text
        x="40%"
        y="68%"
        fill="#495057"
        fontSize={14}
        style={{ fontWeight: "400" }}
      >
        {url?.length > 0 ? url : window.location.host }
      </text>
      <defs>
        <filter
          id="filter0_dd_0_1"
          x="0"
          y="0"
          width="413"
          height="164"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.117647 0 0 0 0 0.141176 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.121569 0 0 0 0 0.117647 0 0 0 0 0.141176 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_0_1"
            result="effect2_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_0_1"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
