import { NodeHeader } from "../../Common";
import { Handle, Position } from "reactflow";
import React, { useEffect, useState } from "react";
import Icons from "../../../../../assets/imageUrl";
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader";
import { useNodeData } from "../../../../store/selector";
import { randomColor } from "../../../../../assets/imageUrl";
import {TextInput} from "../../../../components/inputs";
import {Select} from "../../../../components/select";

const nodeName = "RandomNode";


export const RandomNodePanel = ({ id, type = nodeName, vars = [] }) => {
  const [data, setData] = useNodeData(id);
  const options = [
    {name: "Select", value:"" },
    {name: "Number", value:"Number" },
    {name: "String", value:"String" },
    {name: "GUID", value:"guid" },
  ];
  const GuidOptions = [

    {name: "V4", value:"V4" },

  ];


  return (
    <div className="">
      <SidePanelHeader nodeName={nodeName} />
      <section className="sideInnerParent px-3 ptb-20">
          <TextInput
              name={type + " Name"}
              type="text"
              value={data?.name || ""}
              maxLength={15}
              setValue={(value) => setData({ ...data, name: value })}
              placeholder={type + " Name"}
              helpingText="Please give a name to the Random field"
          />
        <div>
          <Select
              name="Select Type"
              helpingText = "Please give a name to the Random field"
              value={data?.type || ""}
              setValue={(value) => {
                setData({ ...data, type: value });
              }}
              options={options}
          />
        </div>
        {
          data.type === "Number" ? <div>
            <TextInput
                name="MIN Value"
                type="number"
                value={data?.min || ""}

                setValue={(value) => setData({ ...data, min: parseInt(value) })}
                placeholder="Min Value"
                helpingText="Please give a name to the Random field"
            />
            <TextInput
                name="MAX Value"
                type="number"
                value={data?.max || ""}
                setValue={(value) => setData({ ...data, max: parseInt(value) })}
                placeholder="Min Value"
                helpingText="Please give a name to the Random field"
            />
          </div> : data.type === "String" ? <div>
            <TextInput
                name="String Length"
                type="number"
                value={data?.length || ""}
                setValue={(value) => setData({ ...data, length: parseInt(value) })}
                placeholder="Min Value"
                helpingText="Please give a name to the Random field"
            />


            <div>
              <label htmlFor="" className="nodePanel-labels">Include</label>
              <div className="includeCheckBox">
                <div className="checkbox-wrapper-33">
                  <label className="checkbox">

                    <input
                        type="checkbox"
                        className="checkbox__trigger visuallyhidden"
                        checked={data?.includeCaps || false}
                        onChange={(e) => setData({ ...data, includeCaps: e.target.checked })}
                    />
                    <span className="checkbox__symbol">
          <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 28 28" height="28px" width="28px" className="icon-checkbox" aria-hidden="true">
            <path d="M4 14l8 7L24 7"></path>
          </svg>
        </span>
                    <p className="checkbox__textwrapper">Caps</p>
                  </label>
                  <label className="checkbox">
                    <input
                        type="checkbox"
                        className="checkbox__trigger visuallyhidden"
                        checked={data?.includeNumbers || false}
                        onChange={(e) => setData({ ...data, includeNumbers: e.target.checked })}
                    />
                    <span className="checkbox__symbol">
          <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 28 28" height="28px" width="28px" className="icon-checkbox" aria-hidden="true">
            <path d="M4 14l8 7L24 7"></path>
          </svg>
        </span>
                    <p className="checkbox__textwrapper">Numbers</p>
                  </label>
                  <label className="checkbox">
                    <input
                        type="checkbox"
                        className="checkbox__trigger visuallyhidden"
                        checked={data?.includeSpecialChars || false}
                        onChange={(e) => setData({ ...data, includeSpecialChars: e.target.checked })}
                    />
                    <span className="checkbox__symbol">
          <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 28 28" height="28px" width="28px" className="icon-checkbox" aria-hidden="true">
            <path d="M4 14l8 7L24 7"></path>
          </svg>
        </span>
                    <p className="checkbox__textwrapper">Special Character</p>
                  </label>
                </div>
              </div>
            </div>
          </div> : data.type === "guid" ? <div>
            <Select
                name="GUID Version"
                helpingText = "Please give a name to the Random field"
                value={data?.version || ""}
                setValue={(value) => setData({ ...data, version:value })}
                options={GuidOptions}
            />

          </div> : <></>
        }

        <div>
          <TextInput
              name="Variable"
              type="text"
              value={data?.variable || ""}
              setValue={(value) => setData({ ...data, variable:value })}
              placeholder="Variable"
              helpingText="Please give a name to the Random field"
          />

        </div>
      </section>
    </div>
  );
};

const RandomNode = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected);
  }, [selected]);
  return (
    <div
      className={
        " "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div style={{ backgroundColor: "#E1FFE6", border: selected ? `solid 2px${"#00B51F"}` : "" }}
           className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={nodeName}
          icon={randomColor}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="updateNodeStyle">
          <div>
            <p>POST</p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandomNode;
