import React from 'react'
import { CreateNode, NodesListing } from 'nodes';
import {Outlet} from 'react-router-dom';

const NodeLayout = () => {
  return (
  <>
    {/* <NodesListing /> */}
    <Outlet />
    
</>
  )
}

export default NodeLayout