import { useMemo, useState, useRef, useEffect } from "react";
import { useVars } from "../store/nodePanel";
import Icons, {accessMenuToggle, deleteModelImg, penModelImg} from "../../assets/imageUrl";
export const VariablePick = ({ placeholder, name, input, setInput, props,  helpingText,errors }) => {

    const [inputValue, setInputValue] = [input, setInput];
    const [showVariable, setShowVariable] = useState(false);
    const [variableSpan, setVariableSpan] = useState(true);
    const inputRef = useRef(null);

    const vars = useVars();

    let totalVars = useMemo(() => [vars?.common, vars?.unique].flatMap(i => !!i), [vars]);

    // Design Variable Component
    const DesignVariable = () => {
        const matches = [];
        const regex = /{{(.*?)}}|([^{}]+)/g;
        let match;
        let lastIndex = 0;
        while ((match = regex.exec(inputValue)) !== null) {
            if (match.index > lastIndex) {
                matches.push({ covered: false, string: inputValue.substring(lastIndex, match.index) });
            }

            if (match[1]) {
                matches.push({ covered: true, string: match[1] });
            }

            lastIndex = match.index + match[0].length;
        }
        if (lastIndex < inputValue.length) {
            matches.push({ covered: false, string: inputValue.substring(lastIndex) });
        }

        return (<>
            {matches.map((match, index) => {
                return <>
                    {match.covered ? (
                        <span style={{ color: 'blue' }}>
                            {match.string}
                        </span>
                    ) : (
                        <span>
                            {match.string}
                        </span>
                    )}
                </>
            })}
        </>)
    }


    const handleCursorInput = () => {
        if (inputRef.current) {
            const cursorPos = inputRef.current.selectionStart || 0;
            if ((inputValue?.[cursorPos - 1] === "{")) {
                setShowVariable(true);
            } else {
                setShowVariable(false);
            }
        }
    }

    useEffect(() => {
        handleCursorInput();
    }, [inputValue])


    const handleBraceRemoval = (insertEle) => {
        let genValue = inputValue.split("");
        if (inputRef.current) {
            const cursorPos = inputRef.current.selectionStart || 0;

            let leftOpenBraceIndex = cursorPos - 1;

            let count = 0

            for (let i = leftOpenBraceIndex; i >= 0; i--) {

                if (genValue[i] === "{") {
                    genValue.splice(i, 1);
                    count++;
                } else { break; }

            }

            let rightCloseBraceIndex = cursorPos - count;


            for (let i = rightCloseBraceIndex; i < genValue.length; i++) {
                if (genValue[i] === "}") {
                    genValue.splice(i, 1);
                    i--;

                } else {
                    break;
                }

            }

            const elementToAdd = `{{${insertEle}}}`;
            const elementToInsertAfter = cursorPos - count;
            genValue.splice(elementToInsertAfter, 0, elementToAdd);
            const newValue = genValue.join("");
            setInputValue(newValue);
        }

    };
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <>
            {
                variableSpan === false && inputValue.length >= 1 ? <div className="backgroundVariable" onClick={() => {
                    setVariableSpan(true);
                }}>
                    <DesignVariable></DesignVariable>

                </div> :

                    <div className="labelInputFull mt-2">

                        <div className="RightLabel">
                            <div className="nodePanel-labels">{name}</div>
                            <div className="position-relative">
                                <div className="RightLabelImg"  onMouseEnter={() => setShowTooltip(true)}
                                     onMouseLeave={() => setShowTooltip(false)}>
                                    <img src={Icons.InfoIcon} alt="IdflowInfoIcon"/>

                                </div>
                                <div className={`RightTooltip ${showTooltip ? 'RightTooltipOpacity' : ''}`}>
                                    <div className="RightSubtool">
                                        <p>{helpingText} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="labelInputFull">
                        <input
                            type="text"
                            placeholder={placeholder}
                            ref={inputRef}
                            value={inputValue}
                            className={`nodePanel-inputs pr-40 ${errors && errors.errors ? 'ErrorBorder' : 'SuccessBorder'}`}
                            onChange={(e) => { setInputValue(e.target.value) }}
                            onKeyUp={handleCursorInput}
                            // onBlur={() => setVariableSpan(false)}
                            {...props}
                        />
                            {errors && errors.errors ?<> <img src={Icons.ErrorIcon} alt="IdflowErrorIcon" className="RightErrorIcon"/></>
                                :<><img src={Icons.SuccessIcon} alt="IdflowErrorIcon" className="RightErrorIcon"/></>}
                        </div>

                        {errors && errors.errors ? errors.errors.map(error => <p className="errorRightPanel">{error}</p>) : <p className="successRightPanel">Success</p>}

                        {(!!totalVars && totalVars.length > 0) && (showVariable === true) ? <ul className="variableShowStyle">
                            {!!vars?.common && vars?.common.map(variable => (
                                <li onClick={() => handleBraceRemoval(variable)}>
                                    {variable}
                                </li>
                            ))}
                            {!!vars?.unique && vars?.unique.map(variable => (
                                <li >
                                    <span style={{ textDecoration: 'line-through' }}>
                                        {variable}
                                    </span>

                                </li>
                            ))}
                        </ul> : <>
                        </>}
                    </div>
            }
        </>
    );
};



export const Input = props => {
    let {
        name = "Input",
        placeholder = "Input",
        helpingText="Please give a name to the field",
        value = "",
        setValue = () => { },
        type = "text",
        errors,
        key,
        variables = true,
    } = props;

    const vars = useVars();

    const newProps = { ...props };

    if (!!newProps.type) {
        delete newProps['type']; // Temp fix for type ( for inputs only)
    }

    console.log(vars);


    return !!variables ? <VariablePick
        placeholder={placeholder}
        name={name}
        input={value}
        helpingText={helpingText}
        setInput={setValue}
        props={newProps}

    /> : <div className="RightBottomBorder">

        <div className="RightLabel">
            <div className="nodePanel-labels">{name}</div>
            <div>
                <div className="RightLabelImg">
                    <img src={Icons.InfoIcon} alt="IdflowInfoIcon"/>
                    <div className="RightTooltip">
                        <div className="RightSubtool">
                            <p>{helpingText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="labelInputFull">

            <input
                type={type}
                className={`nodePanel-inputs pr-40 ${errors && errors.errors ? 'ErrorBorder' : 'SuccessBorder'}`}
                value={value}
                key={key}
                placeholder="Enter"
                onChange={(e) => setValue(e.target.value)}
                {...newProps}
            />
            {errors && errors.errors ?<> <img src={Icons.ErrorIcon} alt="IdflowErrorIcon" className="RightErrorIcon"/></>
:<><img src={Icons.SuccessIcon} alt="IdflowErrorIcon" className="RightErrorIcon"/></>}
        </div>
        {errors && errors.errors ? errors.errors.map(error => <p className="errorRightPanel">{error}</p>) : <p className="successRightPanel">Success</p>}
    </div>;
}

export const TextInput = props => <Input  {...props} type="text" />
export const NumberInput = props => <Input  {...props} type="number"
    value={props?.value !== 0 ? props.value : undefined}
    setValue={value => props.setValue(!!value ? parseInt(value) : 0)} />