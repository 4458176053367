import React from "react";
import { useDrag } from "react-dnd";

export const Alignment = {
    name: "Alignment",
    value: "alignment",
    type: "alignment",

    required: true,
    defaultValue: "center",
    options: [
        {
            name: "Center",
            value: "center",
        },
        {
            name: "Left",
            value: "left",
        },
        {
            name: "Right",
            value: "right",
        },
    ],
};

export const Direction = {
    name: "Direction",
    value: "direction",
    type: "direction",
    options: [
        {
            name: "Row",
            value: "row",
        },
        {
            name: "Column",
            value: "column",
        },
    ],
};

export const FullContainer = {
    name: "Fill container",
    value: "full-wrap",
    type: "checkbox",
    defaultValue: false,
};

export const Style = {
    name: "Style",
    value: "style",
    type: "select",
    required: true,
    defaultValue: "H3",
    options: [
        {
            name: "H1",
            value: "H1",
        },
        {
            name: "H2",
            value: "H2",
        },
        {
            name: "H3",
            value: "H3",
        },
        {
            name: "Subtitle1",
            value: "subtitle1",
        },
        {
            name: "Subtitle2",
            value: "subtitle2",
        },
        {
            name: "Subtitle3",
            value: "subtitle3",
        },
        {
            name: "Body",
            value: "body",
        }
    ],
};

export const Position = {
    name: "Position",
    value: "position",
    type: "select",
    required: true,
    defaultValue: "",
    options: [
        {
            name: "top",
            value: "0",
        },
        {
            name: "bottom",
            value: "0",
        },
        {
            name: "left",
            value: "0",
        },
        {
            name: "right",
            value: "0",
        }
    ],
};

export const emptyProps = {};

export const Node = ({ type, children }) => {
    const [{ isDragging }, reference] = useDrag(() => ({
        type: type,
        item: { type },
        collect: (monitor) => {
            return {
                isDragging: !!monitor.isDragging(),
            };
        },
    }));
    return <div ref={reference} className="col-lg-6 col-sm-6 p-0" >{children}</div>;
};


export const ButtonLoader = ({color = "#fff"}) => {
    return <svg viewBox="25 25 50 50" className="IdflowLoadersvg">
        <circle r="20" cy="50" cx="50" style={{stroke: color}}></circle>
    </svg>
}