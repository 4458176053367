import { Handle, Position } from "reactflow";
import React from "react";
import { generateId } from "../../../../../utils/store";
import Icons from "../../../../../assets/imageUrl";

export const StartNode = () =>{
  return <div className="beginStartNode">
    <img src={Icons.startNodeHome} alt=""/>
    Start Flow
    <Handle
      type="source"
      position={Position.Right}
      className="beginHandler"
    />
  </div>
}

