// Better to maintain eval code in seperate space to avoid sharing global variables

const executeCode = async (options, code) => {
    try{
        // Evaluate the Dynamic Code here
        const handler = eval(`
        () => {
            ${code}

            return handler
        }
        `)();

        // handler is async function that dynamically evaluated
        let result = await handler(options);

        return {result}
        
    }catch(e){
        console.error(e);
        return {reason: JSON.stringify(e)}
    }

    new Function()
}

export {executeCode};