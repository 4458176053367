import { isFunction } from "lodash";


export const Label = props => {
    let {value="", formatter} = props;

    if (formatter && isFunction(formatter)){
        value = formatter(value);
    }
    return <p>{value}</p>
}