import { useEffect, useMemo, useRef, useState } from "react";
import { useEdges, useNodes } from "reactflow";
import nodePropIcon from "../../../workflow/src/assets/nodePropIcon.svg";
import nodePropIconActive from "../../../workflow/src/assets/nodePropIconActive.svg";
import validErrorIconActive from '../../../workflow/src/assets/validationErrorIconActive.svg';
import validErrorIcon from '../../../workflow/src/assets/validationsErrorIcon.svg';
import versionsIcon from '../../../workflow/src/assets/versions.svg';
import versionsIconActive from '../../../workflow/src/assets/versionsActive.svg';
import SidePanelHeader from "../Components/sidePanelHeader/SidePanelHeader";
import { getWasm } from "../utils/wasm";
import { inputs as SchedulerInputs } from "./Flow/Nodes/API/Scheduler";
import { inputs as webhookInputs } from "./Flow/Nodes/API/webhook";
import { AwsS3Panel } from "./Flow/Nodes/Aws S3/AwsS3";
import { inputs as APICallInputs } from "./Flow/Nodes/Common/APICall/Apicall";
import { CodePanel } from "./Flow/Nodes/Common/CodeNode/Code";
import { inputs as ConditionInputs } from "./Flow/Nodes/Common/Condition/Condition";
import { inputs as ConversionInputs } from "./Flow/Nodes/Common/Conversion/Conversion";
import { ExecuteAPIPanel } from "./Flow/Nodes/Common/ExecuteAPI/ExecuteAPIPanel";
import { MatrixPanel } from "./Flow/Nodes/Common/Matrix/Matrix";
import { NovuPanel } from "./Flow/Nodes/Common/Novu/NovuPanel";
import { RandomNodePanel } from "./Flow/Nodes/Common/RandomNode/RandomNode";
import { SwitchNodePanel } from "./Flow/Nodes/Common/SwitchNode/SwitchNode";
import { TimeDelayPanel, inputs as TimeDelayInputs } from "./Flow/Nodes/Common/TimeDelay/TimeDelay";
import { inputs as UpdateVariableInputs } from "./Flow/Nodes/Common/UpdateVariable/UpdateVariable";
import Validation from "./Flow/Nodes/Common/Validation/validation";
import Version from "./Flow/Nodes/Common/Version/Version";
import { Zapier } from "./Flow/Nodes/Common/zapier/zapiertrigger";
import { PagePanel } from "./Flow/Nodes/Pages/Page/page";
import { InteractiveButtonsPanel } from "./Flow/Nodes/whatsapp/InteractiveButtons/InteractiveButtons";
import { InteractiveListPanel } from "./Flow/Nodes/whatsapp/InteractiveList/InteractiveList";
import { MessagePanel } from "./Flow/Nodes/whatsapp/Message/Message";
import { QuestionPanel } from "./Flow/Nodes/whatsapp/Question/Question";
import { CronInput } from "./components/cron";
import { NumberInput, TextInput } from "./components/inputs";
import { JsonEditor } from "./components/jsonEditor";
import { Label } from "./components/label";
import { ListItems } from "./components/listItems";
import { Select } from "./components/select";
import { Toggle } from "./components/toggle";
import { useNodeErrors, useNodeValidator } from "./store/errorsStore";
import useFlow from "./store/flowStore";
import { NodePanelContext } from "./store/nodePanel";
import { useNode, useNodeData, useSelectedNode } from "./store/selector";

const IconComponent = ({ type, icon, activeIcon, isActive, onClick }) => (
    <img
        key={type}
        src={isActive ? activeIcon : icon}
        alt={`${type}Icon`}
        className={isActive ? "idflow_active_icon" : "idflow_inactive_icon"}
        onClick={onClick}
    />
);

const PanelsMap = { // Add Panel here to register with the React Flow
  Trigger: Zapier,
  Novu: NovuPanel,
  // ExecuteAPI: ExecuteAPIPanel,
  Matrix: MatrixPanel,
  Message: MessagePanel,
  Question: QuestionPanel,
  InteractiveList: InteractiveListPanel,
  InteractiveButtons: InteractiveButtonsPanel,
  Page: PagePanel,
  AwsS3: AwsS3Panel,
  Random: RandomNodePanel,
  // TimeDelay: TimeDelayPanel,
  SwitchNode: SwitchNodePanel,
  ExecuteAPI: ExecuteAPIPanel,
  Code: CodePanel
}

const inputComponents = {
  textInput: TextInput,
  numberInput: NumberInput,
  jsonInput: JsonEditor,
  select: Select,
  listItems: ListItems,
  label: Label,
  cronInput: CronInput,
  boolInput: Toggle
}

export const nodeInputs = {
  Webhook: webhookInputs,
  Condition: ConditionInputs,
  APICall: APICallInputs,
  UpdateVariable: UpdateVariableInputs,
  Conversion: ConversionInputs,
  // ExecuteAPI: ExecuteAPIInputs,
  Scheduler: SchedulerInputs,
  TimeDelay: TimeDelayInputs

}

export const  Components = props => {
  let { inputs, data, setData, type, id, errors } = props;

  return inputs?.map((input, index) => {
    let InputComponent = inputComponents[input.type];
    return <InputComponent
      key={`${type}-${id}-${input?.value}-${index}`}
      name={input?.name}
      id={id}
      helpingText={input?.helpingText}
      style={input?.style}
      value={data?.[input?.value] ?? ''}
        // value={data[input?.value]}
      setValue={value => setData({ ...data, [input?.value]: value })}
      defaultValue={input?.defaultValue}
      errors={findValueByKey(errors, input?.value)}
      {...input.props}
      props={input.props}
      type={type}
    />

  })
}

function findValueByKey(obj, targetKey) {
  // Base case: If the current object is not an object, return undefined
  if (typeof obj !== 'object' || obj === null) {
    return undefined;
  }

  // Check if the target key exists in the current object
  if (targetKey in obj) {
    return obj[targetKey];
  }

  // If the target key is not found, recursively search in nested objects
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const nestedValue = findValueByKey(obj[key], targetKey);
      if (nestedValue !== undefined) {
        return nestedValue;
      }
    }
  }

  // If the key is not found anywhere, return undefined
  return undefined;
}

export const DefaultNodePanel = props => {
  let {
    id,
    type,
    flowId,
  } = props;
  const [data, setData] = useNodeData(id);
  

  const node = useNode(id);
  const inputs = useMemo(() => nodeInputs[type], [type]);
  // const [errors, setErrors] = useState({});
  const getFlow = useFlow(flow => flow.getFlow)
  const edges = useFlow(flow => flow.edges);
  
  const [vars, setVars] = useState({});

  const validator = useNodeValidator(id);
  const errors = useNodeErrors(id);



  useEffect(() => {
    // validator().then()
  }, [data])

  useEffect(() => {
    // Getting Available variables for current Node
    // Data updates are not required for varaibles,

    const g = async () => {
      let { get_variables_by_node_id } = await getWasm();
      let flow = getFlow();
      flow._id = flow._id ?? "some randome flow id";
      try {
        let result = get_variables_by_node_id(JSON.stringify(flow), id);
        let vars = JSON.parse(result);
        setVars(vars);
      } catch (e) {
        console.error(e);
      }
    };
    g();
  }, [edges]) // Edges connection changes may effect avaiable variables

  const [activeComponent, setActiveComponent] = useState("panel");

  const sidebarRef = useRef(null);

  let Panel = PanelsMap[type];
  const iconData = [
    { type: "version", icon: versionsIcon, activeIcon: versionsIconActive },
    { type: "panel", icon: nodePropIcon, activeIcon: nodePropIconActive },
    { type: "validation", icon: validErrorIcon, activeIcon: validErrorIconActive },
  ];

  // resizer
  const [sidebarWidth, setSidebarWidth] = useState(300); // Initial width
  const rsMouseDownHandler = (e) => {
    const x = e.clientX;
    const sbWidth = window.getComputedStyle(sidebarRef.current).width;
    const initialWidth = parseInt(sbWidth, 10);

    const mouseMoveHandler = (e) => {
      const dx = x - e.clientX;
      const newWidth = initialWidth + dx;

      if (newWidth >= 300) {
        setSidebarWidth(newWidth);
      }
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mouseup', mouseUpHandler);
      document.removeEventListener('mousemove', mouseMoveHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return (
    <NodePanelContext.Provider value={{ vars }}>
      <div className="right_panel_workflow" style={{ width: `${sidebarWidth}px` }} ref={sidebarRef}>
        <article className="sideInnerParent">
          <div className="resizer" onMouseDown={rsMouseDownHandler}>
          </div>
          <div className="idflow_np_right_icons_parent">
            <div className="idflow_np_icons_main">
              {iconData?.map(({ type, icon, activeIcon }) => (
                  <IconComponent
                      key={type}
                      type={type}
                      icon={icon}
                      activeIcon={activeIcon}
                      isActive={activeComponent === type}
                      onClick={() => setActiveComponent(type)}
                  />
              ))}
            </div>
          </div>
          {activeComponent === "panel" && (
              Panel ? <Panel {...props} /> :
                  <>
                    <SidePanelHeader nodeName={type} />
                    <section className="px-3">
                      <Components
                          inputs={inputs}
                          data={data}
                          setData={setData}
                          type={type}
                          errors={errors}
                          id={id}
                      />
                    </section>
                  </>
          )}
          {activeComponent === "validation" && <Validation />}
          {activeComponent === "version" && <Version />}
        </article>
      </div>
    </NodePanelContext.Provider>
  );
}


export function NodePanel() {
  const nodes = useNodes();
  const edges = useEdges();
  const selectedNode = useSelectedNode();
  const flowId = useFlow(flow => flow.id);
  let flow = { nodes: nodes, edges: edges };
  // const variables = getVariables(flow);

  if (selectedNode) {
    let type = selectedNode.type;

    // Handle Start Node First

    if (type === "StartNode"){
      return <></> // No Right Side Panel for StartNode
    }
    let vars = {}

    let props = {
      data: selectedNode.data,
      vars,
      type: selectedNode.type,
      flowId,
      ...selectedNode
    };

    return <DefaultNodePanel {...props} />;


  }
  return <></>
}