import React, { useEffect, useRef, useState } from "react";
import { BiCopy } from "react-icons/bi";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import defaultIcon from "../../../assets/default-node-icon.png";
import CiEdit from "../../../assets/nb-edit-icon";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Skeleton from "react-loading-skeleton";
import { useFlowId } from "../../../../../workflow/src/Components/NavBar";
import { IMAGE_URL } from "../../../api/common";
import { getNode } from "../../../api/nodes";
import "./index.css";

const LoaderComponent = () => (
  <Row className="p-0 mb-5">
    {/* <Col lg="4" className="p-0">
      {[0, 1, 2, 3, 4, 5, 6, 7].map((e, i) => {
        return (
          <div className="main-skelton-div">
            <div className="skelton-div2">
              <Skeleton circle={true} alt="" height="50px" width="50px" />
              <div className="skelton-desp-flex">
                <Skeleton height={12} width={180}></Skeleton>
                <Skeleton height={12} width={160}></Skeleton>
              </div>
            </div>
          </div>
        );
      })}
    </Col> */}
    <Col lg="12" className="pb-4">
      <Container fluid className="container-class">
        <Container fluid className="d-flex main-div p-3 ps-4 pb-3 pl-2">
          <div className="card-view-header">
            <div>
              <Skeleton circle={true} alt="" height="65px" width="65px" />
            </div>
            <div className="skeleton-header-text">
              {" "}
              <Skeleton height={12} width={160}></Skeleton>
              <Skeleton height={12} width={160}></Skeleton>
            </div>
          </div>
          <Skeleton height={30} width={60}></Skeleton>
        </Container>
        <div></div>
        <div className="pt-3 mb-5">
          <div className="skeleton-data-wrapper">
            <div className="mt-3 d-flex ">
              <Skeleton
                height={40}
                width={200}
                style={{ marginRight: "20px" }}
              ></Skeleton>
              <Skeleton height={40} width={900}></Skeleton>
            </div>
            <div className="mt-3 d-flex ">
              <Skeleton
                height={40}
                width={200}
                style={{ marginRight: "20px" }}
              ></Skeleton>
              <Skeleton height={40} width={900}></Skeleton>
            </div>
            <div className="mt-3 d-flex ">
              <Skeleton
                height={40}
                width={200}
                style={{ marginRight: "20px" }}
              ></Skeleton>
              <Skeleton height={40} width={900}></Skeleton>
            </div>
          </div>
        </div>
        <div className="skeleton-footer-wrapper">
          <Skeleton height={12} width={160}></Skeleton>
          <Skeleton height={12} width={650} className="mt-4"></Skeleton>
          <Skeleton height={12} width={160} className="mt-4"></Skeleton>
          <Skeleton height={12} width={650} className="mt-4"></Skeleton>
        </div>
      </Container>
    </Col>
  </Row>
);

export const NodeBuilderView = ({wClassName, isBreadCrumb=true}) => {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const location = useLocation();
  const currentNodeId = location.pathname.split("/").at(-1);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(true);
  const jsonEditorRef = useRef();

  const workflowId = useFlowId();


  const copyText = () => {
    navigator.clipboard
      .writeText(selected?.url)
      .then(() => {
        toast.success("Text copied to clipboard");
      })
      .catch((error) => {
        toast.error("Unable to copy text to clipboard");
      });
  };

  const replaceUrl = (newID) => {
    try {
      const currentURL = window.location.href;
      const urlWithoutID = currentURL.split("/list-node/")[0];
      const newURL = `${urlWithoutID}/list-node/${newID}`;
      window.history.pushState(null, "", newURL);
    } catch (error) {
      toast.error("unable to replace id");
    }
  };

  const fetchNodeData = (e) => {
    getNode(e)
      .then((res) => {
        setSelected(res);
        replaceUrl(res.id);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("unable to fetch the node");
      });
  };

  const imageCheck = (logo) => {
    return logo && logo.length < 30;
  };

  useEffect(() => {
    fetchNodeData(currentNodeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (jsonEditorRef && jsonEditorRef.current) {
  //     jsonEditorRef.current.jsonEditor.set(selected?.body || {});
  //   }
  // }, [selected?.body]);

  return (
    <div className={`node-builder-main ${wClassName ? "workflow-margin-top" : null}`}>
      {
        isBreadCrumb ? (
          <Breadcrumb>
            <Breadcrumb.Item href="#" className="breadCrumb-group">
              <NavLink to="/home/nodes">Nodes</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active href="#">
              View Node
            </Breadcrumb.Item>
          </Breadcrumb>
        ) : null
      }
      <div className="node-builder-heading">
        <div className="node-builder-main-heading">
          <h1>Node details</h1>
          <p>View the details of the node.</p>
        </div>
      </div>

      {/* All input and select field */}
      {/* <SearchSortFilter width={"31%"} /> */}
      {loading ? (
        <LoaderComponent />
      ) : (
        <>
          {selected && (
            <div className={`nb_view_container`}>
              <div className="node-main-header-box-main">
                <div className="node-main-header-box ">
                  <div className="img_wrapper">
                    {/* <img src={selected?.logo} alt={selected?.heading} /> */}
                    <img
                      src={
                        imageCheck(selected?.logo)
                          ? IMAGE_URL + selected?.logo
                          : selected?.logo
                          ? `data:image/png;base64,${selected?.logo}`
                          : defaultIcon
                      }
                      alt={selected?.heading}
                    />
                  </div>
                  <div className="align-self-lg-center">
                    <h3 className="p-0 m-0 headingText">{selected?.name}</h3>
                    {/* <p className="p-0 m-0 font_style subHeadingText">
                      Created by {selected?.sub_category}
                    </p> */}
                  </div>
                </div>
                <button
                  className="custom_button"
                  onClick={() =>
                    navigate(`/home/workflows/${workflowId}/list-node/edit/${selected?.id}`)
                  }
                >
                  <CiEdit />
                  <span>Edit</span>
                </button>
              </div>
              <div className="node-builder-div-after-header">
                <div className="category-table ">
                  <table className="table">
                    <tr>
                      <td width={"30%"} className="font_style category">Category</td>
                      <td className="value">{selected?.category}</td>
                    </tr>
                    <tr>
                      <td width={"30%"} className="font_style category">Sub Category</td>
                      <td className="value">{selected?.sub_category}</td>
                    </tr>
                    <tr>
                      <td width={"30%"} className="font_style category">Authentication type</td>
                      <td className="value">{selected?.auth ? selected?.auth.type : ""}</td>
                    </tr>
                  </table>
                </div>
                <div className="content_div">
                  <span className="title_text">Description</span>
                  <div className="desp_content">{selected?.description}</div>
                </div>
                {/* input */}
                <div className="content_div">
                  <p className="title_text">
                    API Request details
                  </p>
                  <div className="api_content">
                    <button className="api_btn">{selected?.method}</button>
                    <div className="div_wrapper">
                      <Form.Control
                          className="api_link"
                          type="text"
                          id="inputPassword5"
                          name="api"
                          value={selected?.url}
                          readOnly
                        />
                        <button className="custom_button" onClick={copyText}>
                          <BiCopy />
                          Copy
                        </button>
                    </div>
                  </div>
                </div>
                <div className="content_div">
                  <p className="title_text">Parameters</p>
                  <p className="sub_text">Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien scelerisque ac.</p>
                  <div className="category-table">
                    <table className="table">
                      {selected?.params?.map((value) => {
                        return (
                          <tr>
                            <td width={"30%"} className="font_style category">{value.name}</td>
                            <td>{value.value}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
                <div className="content_div">
                  <p className="title_text">Headers</p>
                  <p className="sub_text">Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien scelerisque ac.</p>
                  <div className="category-table">
                    <table className="table">
                      {selected?.headers?.map((value) => {
                        return (
                          <tr>
                            <td width={"30%"} className="font_style category">{value.name}</td>
                            <td>{value.value}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
                <div className="content_div">
                  <p className="title_text">Body</p>
                  <p className="sub_text">Lorem ipsum dolor sit amet consectetur. Tortor varius aliquam sapien scelerisque ac.</p>
                  <div className="category-table code_div">
                      <pre>
                        {/* {JSON.stringify(selected?.body)} */}
                        {JSON.stringify(selected?.body, undefined, 2)}
                      </pre>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

