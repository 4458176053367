import React from "react";
import './index.css'

const CustomButton = (props) => {
  const {icon, isLeft, className, title, type, onClick} = props
  return (
    <button
      {...props}
      className={ `${type === "Outlined" ? "idflow-btn-outlined" : "idflow-btn"} ${className ? className : ""}`}
      onClick={onClick}
    >
      {isLeft ? (
        icon && (
          <>
            <img src={icon} alt="icon" /> {title}
          </>
        )
      ) : (
        <>
          {title}
          {icon && <img src={icon} alt="icon" />}
        </>
      )}
    </button>
  );
};

export default CustomButton;
