import React, { useEffect, useState } from "react";
import { usePortalSettings } from "../../../WorkFlowBuilder/store/selector";
import { default as UploadIcon } from "../../../assets/Upload_icon.svg";
import BoxIcon from "../../../assets/portalsetting/box-icon.svg";
import CopiedIcon from "../../../assets/portalsetting/copied-icon.svg";
import CopyIcon from "../../../assets/portalsetting/copy-icon.svg";
import DisplayIcon from '../../../assets/portalsetting/dispIcon.svg';
import ImgComp from "../../../assets/portalsetting/imgComp";
import LandscapeComp from "../../../assets/portalsetting/landscapeComp";
import CustomInput from "../../core/InputField";
import PortalLoader from "./PortalLoader/PortalLoader";
import './index.css';

import Resource from '../../../api/resource';

import { BASE_URL, commonRequest } from "../../../api/common";


export default function PortalSetting() {

  const [portal, setPortal] = usePortalSettings();
  console.log(portal, "portal ===>")
  const [loading, setLoading] = useState(false);
  const [customDomainList, setCustomDomainList] = useState([]);
  const [copied, setCopied] = useState(false);
  const customDomainPath = (domainId) => "/domains/" + domainId;

  const getCustomDomains = () => {
    return commonRequest("get", customDomainPath(""))
  }

  const getAllCustomDomains = async () => {
    try {
      const res = await getCustomDomains();
      if (res && res.length > 0) {
        setCustomDomainList(res);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  useEffect(() => {
    getAllCustomDomains();
  }, []);


  const base64ToUrl = async (image) => {
    let formData = new FormData()
    formData.append("file", image);
    try {
        const response = await Resource("files").getImage(formData);
        return `${BASE_URL}v1/files/${response.id}`
    } catch (e) {
      console.log(e, '-->')
    }
    return;
  };


  const handleChangeFavicon = async (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      let resp = await base64ToUrl(file);
      setPortal({ ...portal, favicon: resp })
    }
  }

  const handleChangeDispalyIcon = async (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      let resp = await base64ToUrl(file);
      setPortal({ ...portal, displayIcon: resp })
    }
  }
  const languageOptions = [
    { value: "English", label: "English" },
    { value: "Hindi", label: "Hindi" },
    { value: "Tamil", label: "Tamil" }
  ]


  const handleCopyDomainLink = () => {
    if (portal?.domain && portal?.path) {
      const textToCopy = `https://${portal.domain}/${portal.path}`;
      setCopied(true);
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  return (
    <div className="main-portalsettings">
      {loading === true ? <PortalLoader /> :
        <div className="portal_setting_wrapper">
          <div className="title-sec row">
            <div className=" col-lg-6 context_wrapper">
              <p className="g-title">Portal Settings</p>
              <p className="g-desc">Apply your unique business branding to maintain a familiar and recognizable experience for your users.</p>
            </div>
          </div>
          <div className="lang-sec row">
            <div className="col-lg-6 left_sec">
              <p className="lang-title">Language</p>
              <p className="desc">Please choose language for the workflow.</p>
            </div>
            <div className="col-lg-3 right_sec">
              <label className="cusDomain-label">Select Language</label>

              <select onChange={(e) => setPortal({ ...portal, language: e.target.value })} >
                {languageOptions.map((option) => (
                  <option key={option.value} value={option.value} selected={option.value === portal.language}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="favi-sec row">
            <div className="col-lg-4 left_sec">
              <p className="lang-title">Favicon</p>
              <p className="desc">Ensure your favicon is a square image with dimensions of 64x64 pixels in the .png format.</p>
              {portal.favicon?.length > 0 ? (
                <img
                  className="image"
                  src={portal.favicon}
                  height={80}
                  width={80}
                  alt="favicon"
                />
              ) : (
                <img className="image1" src={BoxIcon} alt="box" />
              )}
              <div className="form_fields_wrapper mt-4 pt-2">
                <CustomInput
                  label="Title Name"
                  placeholder="Enter Title"
                  onChange={(e) => setPortal({
                    ...portal,
                    title: e.target.value

                  })}
                  value={portal?.title}
                />
              </div>
              <div className="upload_divs mt-4">
                <label class="upload_btn" for="favicon-upload">
                  <input onChange={handleChangeFavicon} type="file" id="favicon-upload" style={{ display: "none" }} accept="image/png" />
                  <img src={UploadIcon} alt="upload" />
                  Upload file
                </label>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-6 right_sec">
              <div className="img_preview">
                <p className="img-title"> Preview</p>
                <ImgComp imgSrc={portal?.favicon} imgText={portal?.title} alt="fav" />
              </div>
            </div>
          </div>
          <section className="cusDomain-sec row">
            <div className="col-lg-6 left_sec">
              <p className="lang-title">Custom Domain Settings</p>
              <p className="desc">Use your own domain or opt for a customized domain from ID Flow.</p>
            </div>
            <div className="col-lg-6 right_sec">
              <div onClick={handleCopyDomainLink}>
                {copied ? (
                  <button className="idflow-btn-outlined" >
                    <img src={CopiedIcon} alt="copied-icon" />
                    Domain Link Copied
                  </button>
                ) :
                  <button className="idflow-btn-outlined" >
                    <img src={CopyIcon} alt="copied-icon" /> Copy Domain Link
                  </button>
                }
              </div>
            </div>
            <div className="col-lg-6 left_sec id_">
              <label className="cusDomain-label">Default Subdomain</label>
              <select
                value={portal.domain}
                onChange={(e) => setPortal({ ...portal, domain: e.target.value })}
              >
                <option value="">Select a subdomain</option>
                {customDomainList
                  .filter((item) => item?.active === true)
                  .map((cusDomain) => (
                    <option key={cusDomain.domain} value={cusDomain.domain} selected={customDomainList.domain === portal?.domain}>
                      {cusDomain.domain}
                    </option>
                  ))}
              </select>

            </div>
            <div className="col-lg-6 cusDomain-label">
              <CustomInput
                label="Enter your path"
                placeholder="dkyc"
                onChange={(e) => setPortal({
                  ...portal, path: e.target.value
                })}
                value={portal?.path}
              />
            </div>
          </section>
          <div className="web-sec row">
            <div className="col-lg-4 left_sec">
              <p className="lang-title">Metadata</p>
              <p className="desc">Add Title and Description for the cover image.</p>
              <div className="display_icon_wrapper">
                <p className="lang-title">Display Icon</p>
                {portal?.displayIcon?.length > 0 ? (
                  <img
                    className="image"
                    src={portal.displayIcon}
                    height={80}
                    width={80}
                    alt="displayIcon"
                  />
                ) : (
                  <img className="image1" src={DisplayIcon} alt="box" />
                )}
                <div className="upload_divs">
                  <label class="upload_btn" for="display-upload">
                    <input onChange={handleChangeDispalyIcon} type="file" id="display-upload" style={{ display: "none" }} accept="image/png" />
                    <img src={UploadIcon} alt="upload" />
                    Upload file
                  </label>
                </div>
              </div>

              <div className="input_wrapper">
                <div className="form_fields_wrapper">
                  <CustomInput
                    label="Meta title"
                    placeholder="Enter title"
                    onChange={(e) => setPortal({
                      ...portal, meta: {
                        ...portal.meta,
                        title: e.target.value
                      }
                    })}
                    value={portal?.meta?.title}
                  />
                  <p className="desc txt-grey-3">Recommended length: 60 characters</p>
                </div>
                <div className="form_fields_wrapper">
                  <CustomInput
                    label="Meta URL"
                    placeholder={`https://${window.location.host}`}
                    onChange={(e) => setPortal({
                      ...portal, meta: {
                        ...portal.meta,
                        url: e.target.value
                      }
                    })}
                    value={portal?.meta?.url}
                  />
                </div>
                <div className="form_fields_wrapper">
                  <label className="input-label">Meta Description</label>
                  <textarea
                    type="textarea"
                    label="Meta Description"
                    placeholder="Enter description"
                    className="input-field textarea"
                    onChange={(e) => setPortal({
                      ...portal, meta: {
                        ...portal.meta,
                        description: e.target.value
                      }
                    })}
                    value={portal?.meta?.description}
                  />
                  <p className="desc txt-grey-3">Recommended length: 155 - 160 characters</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-6 right_sec">
              <div className="img_preview">
                <p className="img-title"> Preview</p>
                <div className="image1">
                  <LandscapeComp displayIcon={portal?.displayIcon} title={portal?.meta?.title} description={portal?.meta?.description} url={portal?.meta?.url} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
