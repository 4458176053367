export const nodesConstants = {
  method: "GET",
  url: "",
  params: [
    {
      id: "",
      name: "",
      value: "",
    },
  ],
  headers: [
    {
      id: "",
      name: "",
      value: "",
    },
  ],
  body: {},
  status_codes: [
    {
      id: "",
      status: 0,
      variables: {},
    },
  ],
  name: "",
  category: "",
  logo: "",
  sub_category: "",
  auth: {
    type: "",
    username: "",
    password: ""
  },
  auth: {
    type: "",
    status_codes: [
      {
        id: "",
        status: 0,
        variables: "",
      },
    ],
  },
  organization: 0,
  inputs: [""],
  outputs: [
    {
      id: "",
      status: 0,
      outputs: [""],
    },
  ],
};
export const nodesConstantsEdit = {
  method: "GET",
  url: "",
  params: [
    {
      id: "",
      name: "",
      value: "",
    },
  ],
  headers: [
    {
      id: "",
      name: "",
      value: "",
    },
  ],
  body: {},
  status_codes: [
    {
      id: "",
      status: 0,
      variables: {},
    },
  ],
  name: "",
  category: "",
  logo: "",
  sub_category: "",
  auth: {
    method:"",
    url: "",
    params: [
      {
        id: "",
        name: "",
        value: "",
      }
    ],
    headers: [
      {
        id: "",
        name: "",
        value: "",
      }
    ],
    body: {},
    status_codes: [
      {
        id: "",
        status: 0,
        variables: {},
      }
    ],
    type: "",

  },
  organization: 0,
  inputs: [""],
  outputs: [
    {
      id: "",
      status: 0,
      outputs: [""],
    },
  ],
};

export const tabConstants = {
  Inputs: [
    {
      data: "",
      status: "",
    },
  ],
  Outputs: [
    {
      data: "Outputs",
      status: "",
    },
    {
      data: "Outputs",
      status: "",
    },
    {
      data: "Outputs",
      status: "",
    },
    {
      data: "Outputs",
      status: "",
    },
  ],
  Internal: [
    {
      data: "internal",
      status: "",
    },
    {
      data: "internal",
      status: "",
    },
    {
      data: "internal",
      status: "",
    },
    {
      data: "internal",
      status: "",
    },
  ],
};

export const resetInputFields = {
  method: "GET",
  url: "",
  params: [
    {
      id: "",
      name: "",
      value: "",
    },
  ],
  headers: [
    {
      id: "",
      name: "",
      value: "",
    },
  ],
  body: {},
  status_codes: [
    {
      id: "",
      status: 0,
      variables: {},
    },
  ],
  name: "",
  category: "",
  logo: "",
  sub_category: "",
  auth: {
    type: "",
    username: "",
    password: ""
  },
  auth: {
    type: "",
    status_codes: [
      {
        id: "",
        status: 0,
        variables: "",
      },
    ],
  },
  organization: 0,
  inputs: [""],
  outputs: [
    {
      id: "",
      status: 0,
      outputs: [""],
    },
  ],
};

const COMMON_CODE_KEYS =  ["_id", "id", "name", "logo", "category", "sub_category", "description", "nodeType", "organization", "inputs", "outputs"];
export const CreateNodeKeys = {
  API: [...COMMON_CODE_KEYS, "method", "url", "params", "headers", "body", "status_codes", "auth", "bodyType"],
  CUSTOM_CODE : [... COMMON_CODE_KEYS, "code", "language", "environment", "variables"] 
}
export const NodeTypes = {
  API: "ExecuteAPI",
  CUSTOM_CODE: "Code"
}
