import errValidation from "../../../../../assets/validationError.svg";
import {Accordion} from "react-bootstrap";
import {Link} from "react-router-dom";
import warningValidation from "../../../../../assets/validationWarning.svg";
import infoValidation from "../../../../../assets/validationInfo.svg";


const Validation = () =>{
    return (
        <>
            <section className="idflow_validations_">
                <div className="idflow_np_validation_head">
                    <h5>Validations</h5>
                    <select>
                        <option>All</option>
                        <option>Error</option>
                        <option>Warning</option>
                        <option>Info</option>
                    </select>
                </div>
                <div className="idflow_np_validation_error_label">
                    <img src={errValidation} alt="errorIcon" />
                    <h6 className="mb-0">Error's</h6>
                </div>
                <div className="idflow_np_validation_err_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Error:2 Uncaught err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_err_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Error:2 Uncaught err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_err_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Error:2 Uncaught err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_err_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Error:2 Uncaught err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>

            <section className="idflow_validations_">
                <div className="idflow_np_validation_warn_label">
                    <img src={warningValidation} alt="warnIcon" />
                    <h6 className="mb-0">Warning's</h6>
                </div>
                <div className="idflow_np_validation_warn_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Warning:2 lorem ips</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_warn_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Warning:2 lorem err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_warn_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Warning:2 Uncaught err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_warn_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Warning:2 Uncaught err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>

            <section className="idflow_validations_">
                <div className="idflow_np_validation_info_label">
                    <img src={infoValidation} alt="infoIcon" />
                    <h6 className="mb-0">Info's</h6>
                </div>
                <div className="idflow_np_validation_info_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Info:2 lorem ips</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_info_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Info:2 lorem err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_info_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Info:2 Uncaught err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="idflow_np_validation_info_accordion">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Info:2 Uncaught err</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                <div>
                                    <Link to="#">View More Details</Link>
                                    <button>View Error Node</button>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>
        </>
    )
}
export default Validation;