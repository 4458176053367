

export const Toggle = ({ value, setValue, name }) => {
    return (
        <>
            <div className="PB-rightside-panel toggle">
                <label class="switch">
                    <input type="checkbox"
                        checked={value}
                        onChange={(e) => setValue(e.target.checked)} />
                    <span class="slider"></span>
                </label>
                <label htmlFor="name" className="PB-side-input-label m-2 mr-4"> &nbsp;{name}</label>
            </div>
        </>
    );
};
