import React, { useMemo, useState } from "react";
import MacFrame from '../../assets/macFrame';
import MobileFrame from '../../assets/mobileFrame';
import NavLeftIcon from '../../assets/navLefticon.svg';
import Navbar, { useFlowId } from "../NavBar";
import './index.css';
import { usePreviewColor, usePreviewType, useFlowType } from "../../WorkFlowBuilder/store/selector";
import { CustomerRoot } from "components";
import ApiTest from "./apiTest/apitest";

const url = window.location.hostname;


const WorkflowPreview = () => {

  const [flowType,] = useFlowType();

  if (flowType === 'web') {
    return (
      <>
        <WebWorkflowPreview />
      </>
    )
  } else if (flowType === 'api') {
    return (
      <>
        <ApiTest />
      </>
    )
  }
  else {
    return 
  }

}

const getDomain = () =>{
  let host = window.location.hostname.split('.');
  return `${host.at(-2)}.${host.at(-1)}`
}


const WebWorkflowPreview = () => {
  const [bgColor,] = usePreviewColor();
  const [type,] = usePreviewType();
  const workflowId = useFlowId();

  let domain = getDomain()

  return (
    <>
      <section className='Mac-mockup' style={{ background: `${bgColor}` }}>
        {
          type === "mac" ? (
            <div className="main">
              <MacFrame />
              <CustomerRoot workflowId={workflowId} domain={domain} />
            </div>
          )
            : type === "mobile" ? (
              <div className='mobile-frame position-relative text-center'>
                <span>{url}</span>
                <MobileFrame />
                <CustomerRoot workflowId={workflowId} domain={domain}/>
              </div>
            ) : <></>
        }


      </section>
    </>
  )
}

export default WorkflowPreview