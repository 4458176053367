import React, { useEffect, useMemo } from "react";

import { useForm, useIsEditor } from "../../../src/stores/context";
import { emptyProps, FullContainer, Node, Style } from "../common";
import { registerComponent } from "../components";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { useFonts, useTheme } from "../../../src/stores/selectors";
import { nameInput } from "./input";
import * as Yup from "yup";

export const type = "Checkbox";

export const inputs = [
  nameInput,
  {
    name: "Text",
    value: "checkboxtext",
    type: "textInput",
    required: true,
    autoFocus: true
  },
  {
    name: "Color",
    value: "color",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Checkbox Size",
    value: "height",
    type: "progressInput",
    required: true,
    autoFocus: false
  },
  Style,
  {
    name: "Required",
    value: "required",
    type: "toggle",
    defaultValue: true
  },

  {
    name: "defaultValue Checked",
    value: "checked",
    type: "toggle",
    defaultValue: true
  },
  FullContainer
];

export const Checkbox = {
  name: "CheckBox",
  type,
  props: emptyProps
};

export const CheckBoxNode = () => {
  return (
    <Node type={type}>
      <div className="page-side-node br-right">
        <img src={PageBuilderAssets?.checkboxIcon} className="node-img" />
        <p className="node-name mb-2">Checkbox</p>
      </div>
    </Node>
  );
};

export const CheckboxPlay = ({
  id,
  name,
  required = false,
  output,
  checkboxtext = "Your Consent Message",
  style = "subtitle3",
  color = "",
  height = "0"
}) => {
  const editor = useIsEditor();
  const fonts = useFonts();
  const font = useMemo(() => fonts[style], [fonts, style]);
  const theme = useTheme();
  const { isForm, formik, registerValidator } = useForm();

  const defaultColor = theme === "dark" ? "#f5f5f5" : "#212427";
  const finalTextColor = color || defaultColor;

  const handleOnChange = isForm ? e => formik?.setFieldValue(name, e.target.checked) : () => undefined;
  const handleBlur = isForm ? formik?.handleBlur : () => undefined;

  useEffect(() =>{
    if (!editor && isForm){
      let yup = Yup.boolean();
      if (required){
        yup = yup.required("Required")
      }
      registerValidator(name, yup)
    }
  }, [])

  return (
    <>
      <div
        className="PBmainheadform"
      >
        <div className="d-flex align-items-center">
          
          <input
            type="checkbox"
            name={name || id}
            id={id}
            required={required}
            disabled={editor}
            className="PBcheckbox"
            checked={formik?.values[name]}
            onChange={handleOnChange}
            onBlur={handleBlur}
            style={{ width: `${15}%`, height: `${height}px` }}
          />
          <p className="mb-0 PBcheckText" style={{
            fontSize: font.size,
            color: finalTextColor
          }}>{checkboxtext}</p>
          {required && <span className="PB-required-star"> *</span>}
        </div>
        {formik ?.touched[name] && formik?.errors[name] ? <div>{formik?.errors[name]}</div>:undefined}
      </div>
    </>
  );
};

registerComponent(type, {
  item: Checkbox,
  play: CheckboxPlay,
  node: CheckBoxNode,
  inputs,
  isInput: true,
  category: "Inputs"
});
