
const ArrowIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.22781 5.22781C5.53155 4.92406 6.02401 4.92406 6.32775 5.22781L12 10.9001L17.6723 5.22781C17.976 4.92406 18.4685 4.92406 18.7722 5.22781C19.0759 5.53155 19.0759 6.02401 18.7722 6.32775L13.0999 12L18.7722 17.6723C19.0759 17.976 19.0759 18.4685 18.7722 18.7722C18.4685 19.0759 17.976 19.0759 17.6723 18.7722L12 13.0999L6.32775 18.7722C6.02401 19.0759 5.53155 19.0759 5.22781 18.7722C4.92406 18.4685 4.92406 17.976 5.22781 17.6723L10.9001 12L5.22781 6.32775C4.92406 6.02401 4.92406 5.53155 5.22781 5.22781Z" fill="#151515"/>
    </svg>
  );
};

const ForwardIconWhite =()=>{
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.63439 2.81767C7.83595 2.61611 8.16275 2.61611 8.36431 2.81767L13.1815 7.63488C13.3831 7.83644 13.3831 8.16323 13.1815 8.36479L8.36431 13.182C8.16275 13.3836 7.83595 13.3836 7.63439 13.182C7.43283 12.9804 7.43283 12.6536 7.63439 12.4521L11.5705 8.51597H3.18214C2.89709 8.51597 2.66602 8.28489 2.66602 7.99984C2.66602 7.71479 2.89709 7.48371 3.18214 7.48371H11.5705L7.63439 3.54759C7.43283 3.34603 7.43283 3.01924 7.63439 2.81767Z" fill="white"/>
    </svg>
  )
}

const InfoIcon = ()=>{
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00065 2.26348C4.83251 2.26348 2.26422 4.83177 2.26422 7.99992C2.26422 11.1681 4.83251 13.7364 8.00065 13.7364C11.1688 13.7364 13.7371 11.1681 13.7371 7.99992C13.7371 4.83177 11.1688 2.26348 8.00065 2.26348ZM1.33398 7.99992C1.33398 4.31802 4.31875 1.33325 8.00065 1.33325C11.6826 1.33325 14.6673 4.31802 14.6673 7.99992C14.6673 11.6818 11.6826 14.6666 8.00065 14.6666C4.31875 14.6666 1.33398 11.6818 1.33398 7.99992ZM7.53554 5.5193C7.53554 5.26242 7.74378 5.05418 8.00065 5.05418H8.00685C8.26373 5.05418 8.47197 5.26242 8.47197 5.5193C8.47197 5.77618 8.26373 5.98442 8.00685 5.98442H8.00065C7.74378 5.98442 7.53554 5.77618 7.53554 5.5193ZM8.00065 7.5348C8.25753 7.5348 8.46577 7.74304 8.46577 7.99992V10.4805C8.46577 10.7374 8.25753 10.9457 8.00065 10.9457C7.74378 10.9457 7.53554 10.7374 7.53554 10.4805V7.99992C7.53554 7.74304 7.74378 7.5348 8.00065 7.5348Z" fill="#3D4551"/>
    </svg>
  )
}

const CrossClose = ()=>{
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.227806 0.727806C0.531547 0.424065 1.02401 0.424065 1.32775 0.727806L7 6.40006L12.6723 0.727806C12.976 0.424065 13.4685 0.424065 13.7722 0.727806C14.0759 1.03155 14.0759 1.52401 13.7722 1.82775L8.09994 7.5L13.7722 13.1723C14.0759 13.476 14.0759 13.9685 13.7722 14.2722C13.4685 14.5759 12.976 14.5759 12.6723 14.2722L7 8.59994L1.32775 14.2722C1.02401 14.5759 0.531547 14.5759 0.227806 14.2722C-0.0759353 13.9685 -0.0759353 13.476 0.227806 13.1723L5.90006 7.5L0.227806 1.82775C-0.0759353 1.52401 -0.0759353 1.03155 0.227806 0.727806Z" fill="#000000"/>
    </svg>
  )
}

export default { ArrowIcon, ForwardIconWhite, InfoIcon, CrossClose };
