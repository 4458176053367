import React, { useEffect, useState } from "react";
import { useUpdateNodeInternals } from "reactflow";

import {
  commonInputs,
  getMeasurements,
  NodeHeader,
  StartNode,
  VariablePick,
} from "../../Common";
import { generateId } from "../../../../../utils/store";
import { parseInt } from "lodash";
import { Handle, Position, useStoreApi } from "reactflow";


import { BsPlusLg } from "react-icons/bs";
import { FiMinus } from "react-icons/fi";

import { JsonEditor } from "jsoneditor-react";
import { Tick } from "../../../../logourls";
import Icons from "../../../../../assets/imageUrl";
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader";
import { useNodeData } from "../../../../store/selector";
import { AddButton,DeleteButton } from "../../../../CommonButtons";
const methods = ["GET", "POST", "PUT", "DELETE"];
const MAX_CHARS = 25;
const nodeName = "APICall";
const nodeNameWebhook = "Webhook";

export const inputs = [
  ...commonInputs,
  {
    name: "HTTP Method",
    value: "method",
    defaultValue: methods[0],
    type: "select",
    style: {
      "marginTop": "10px"
    },
    helpingText : "Please give a name to the field",
    props:{
      options: methods.map(method => ({name: method, value: method}))
    }
  },
  {
    name: "URL",
    value: "url",
    type: "textInput",
    style: {
      "marginTop": "5px"
    },
    defaultValue: "https://idflow.com",
    variables: true,
    placeHolder: "HTTP(S) URL",

  },
  {
    name: "Headers",
    value: "headers",
    defaultValue: [],
    type: "listItems",
    props:{
      deleteButton: true,
      children: [
        {
          name: "Name",
          value: "name",
          defaultValue: "",
          type: "textInput",
        },
        {
          name: "Value",
          value: "value",
          defaultValue: "",
          type: "textInput",
        },
      ]
    }
  },
  {
    name: "Query Params",
    value: "params",
    defaultValue: [],
    type: "listItems",
    props:{
      deleteButton: true,
      children: [
        {
          name: "Name",
          value: "name",
          defaultValue: "",
          type: "textInput",
        },
        {
          name: "Value",
          value: "value",
          defaultValue: "",
          type: "textInput",
        },
      ]
    }
  },
  {
    name: "Request Body",
    value: "body",
    defaultValue: {},
    type: "jsonInput",
  },
  {
    name: "HTTP Responses",
    value: "status_codes",
    defaultValue: [],
    type: "listItems",
    props:{
      children: [
        {
          name: "Status Code",
          value: "status",
          defaultValue: 200,
          type: "numberInput",
        },
        {
          name: "Response Variables",
          value: "variables",
          defaultValue: {},
          type: "jsonInput",
        },
      ]
    }
  },
]

export const Items = ({ name, items, updateItems, vars }) => {
  const setItems = updateItems;
  function callAddItems(){
    setItems([
      ...items,
      {
        id: generateId(),
        name: "",
        value: "",
      },
    ])
  }
function  deleteConditionItems(item){
  setItems(items.filter((i) => i.id !== item.id))
}
  return  <div>
  
            {/* {name === "Variables" ? (
              <>
                {" "}
                <span className="alertRequired">required *</span>
              </>
            ) : (
              <></>
            )} */}
<ul className="commonBackGray">
          {items.map((item) => {
            return (
              <li>
                <div className="commonDeleteSection">
                <label >
          {name}
         </label>
      <DeleteButton callFunction={deleteConditionItems} comId={item}/>
                </div>
                  <div>
                    <label htmlFor="" className="nodePanel-labels mt-3">{`${name} Name`}</label>
                    <VariablePick
                      input={item.name}
                      setInput={(value) =>
                        setItems(
                          items.map((i) => {
                            if (i.id === item.id) {
                              return {
                                ...i,
                                name: value,
                              };
                            }
                            return i;
                          })
                        )
                      }
                      vars={vars}
                    />
                  </div>
                  <div>
                  <label htmlFor="" className="nodePanel-labels mt-3">Value</label>
                    <VariablePick
                      input={item.value}
                      setInput={(value) =>
                        setItems(
                          items.map((i) => {
                            if (i.id === item.id) {
                              return {
                                ...i,
                                value: value,
                              };
                            }
                            return i;
                          })
                        )
                      }
                      vars={vars}
                    />
                  </div>
                
              </li>
            );
          })}
           </ul>
<div className="addConditions">
              <AddButton
              callFunction={callAddItems}
              opName={name}
              />
          </div>
          </div>
};

const StatusCodes = ({ name, items, setItems }) => {

  function callAddStatus(){
    setItems([...items, { id: generateId(), status: 0 }])
  }

  function deleteStatus(item){
    setItems(items.filter((value) => value.id !== item.id))
  }

  return (
    <>
      
        <ul className="commonBackGray">
        {items.map((item) => {
            return (
              <li >
                 <div className="commonDeleteSection">
       <label >
          Status
         </label>
      <DeleteButton callFunction={deleteStatus} comId={item}/>
       </div>
       <div className="labelInputFull mt-3">
        {/* <label htmlFor="" className="nodePanel-labels"></label> */}

        <input
                      type="number"
                      value={item.status}
                      placeholder="value"
                  
                      onChange={(e) => {
                        setItems(
                          items.map((i) => {
                            if (i.id === item.id) {
                              return {
                                ...i,
                                status: parseInt(e.target.value),
                              };
                            }
                            return i;
                          })
                        );
                      }}
                    />
       </div>
       <div>
       <label htmlFor="body" className="nodePanel-labels">
            Response Variables
          </label>
       <JsonEditor
            mode="text"
            value={item.variables || {}}
            onChange={(value) => {
              setItems(
                items.map((i) => {
                  if (i.id === item.id) {
                    return {
                      ...i,
                      variables: value,
                    };
                  }
                  return i;
                })
              );
            }}
          />
       </div>
       
              </li>
            );
          })}
        </ul>
        <div className="addConditions">
              <AddButton
                callFunction={callAddStatus}
                opName='Status Code'
                />
               
            </div>
        {/* <span className="alertRequired">required *</span> */}  
    </>
  );
};

const status_codesWebhook = ({
  name,
  items,
  setItems,
  jsonEditors,
  setJsonEditors,
}) => {
  const addJsonEditor = () => {
    const newEditor = { id: generateId(), value: {} };
    setItems([...items, { id: newEditor.id, statusCode: 0 }]);
    setJsonEditors([...jsonEditors, newEditor]);
  };

  const removeJsonEditor = (editorId) => {
    setItems(items.filter((item) => item.id !== editorId));
    setJsonEditors(jsonEditors.filter((editor) => editor.id !== editorId));
  };
  return (
    <>

          <div>
            <label htmlFor="url" className="side_label">
              {name}
            </label>
          </div>
          {
            <BsPlusLg
              // onClick={() =>
              //   setItems([...items, { id: generateId(), statusCode: 0 }])
              // }
              onClick={addJsonEditor}
            />
          }
      
        {jsonEditors.map((editor) => (
          <div key={editor.id}>
            <ul className="list-none mt-4">
              {items
                .filter((item) => item.id === editor.id)
                .map((item) => (
                  <li key={item.id} className="mb-4">
                    <div className="d-flex gap_cookie">
                      <div style={{ width: "100%" }}>
                        <input
                          type="number"
                          value={item.statusCode}
                          placeholder="value"
                          className="right_section_input w-100"
                          onChange={(e) => {
                            setItems((prevItems) =>
                              prevItems.map((i) => {
                                if (i.id === item.id) {
                                  return {
                                    ...i,
                                    statusCode: parseInt(e.target.value),
                                  };
                                }
                                return i;
                              })
                            );
                          }}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <FiMinus onClick={() => removeJsonEditor(editor.id)} />
                      </div>
                    </div>
                  </li>
                ))}
              <JsonEditor
                mode="text"
                value={editor.value}
                onChange={(value) => {
                  const updatedEditors = jsonEditors.map((ed) => {
                    if (ed.id === editor.id) {
                      return { ...ed, value };
                    }
                    return ed;
                  });
                  setJsonEditors(updatedEditors);
                }}
              />
            </ul>
          </div>
        ))}
   
    </>
  );
};

const Method = ({ methods, onMethodSelect, selectedMethod }) => {
  return (
    <div className="labelInputFull">
      <select onChange={onMethodSelect}>
        <option>Method</option>
        {methods.map((methodname) => {
          return (
            <option selected={selectedMethod === methodname}>
              {methodname}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export function SubWebhook({ data, children, vars, setData }) {
  return <>
        <Items
          name="Header"
          items={data.headers || []}
          updateItems={(items) => setData({ ...data, headers: items })}
          vars={vars}
        />

        <Items
          name="Params"
          items={data.params || []}
          updateItems={(items) => setData({ ...data, params: items })}
          vars={vars}
        />

        <div>
          <label htmlFor="body" className="nodePanel-labels">
            Request Body
          </label>

<JsonEditor
          className="react-json-editor"
            mode="text"
            value={data.body || {}}
            onChange={(value) => setData({ ...data, body: value })}

          />
          
        </div>
        {children}
      </>
}

export const APICallPanel = ({id, type = "APICall", vars }) => {
  const [data, setData] = useNodeData(id);

  return (
    <div className="right_panel_workflow">
      <SidePanelHeader nodeName="API call" />
      <section className="sideInnerParent">
        <div>

        <label className="nodePanel-labels">{type + " Name"}</label>
<div className="labelInputFull">

        <input
          type="text"
          className="nodePanel-inputs"
          value={data?.name || ""}
          maxLength={15}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          placeholder={type + " Name"}
        />
        <label className="nodePanel-labels"> Name</label>
        
        <Method
                methods={methods}
                selectedMethod={data.method}
                onMethodSelect={(e) =>
                  setData({ ...data, method: e.target.value })
                }
              />
              <label className="nodePanel-labels">URL</label>
              <VariablePick
              input={data.url}
              vars={vars}
              setInput={(value) => setData({ ...data, url: value })}
            />
</div>
     
        </div>
      <SubWebhook data={data} setData={setData} vars={vars}>
        <div>
          

          {/*<textarea id="w3review" className="right_section_input w-100 mt-2" rows="4" cols="50" value={data.response} onChange={*/}
          {/*  (e) => setData({...data, response: e.target.value})*/}
          {/*}></textarea>*/}

         
          <StatusCodes
          name={"Status Codes"}
          items={data.status_codes || []}
          setItems={(items) => setData({ ...data, status_codes: items })}
          vars={vars}
        />
        </div>
        
      </SubWebhook>
      </section>
    </div>
  );
};

export default function APICall({ id, data, selected }) {
 
  return (
    <div
      className={
        "App "
      }
    >
      <div
        style={{
          backgroundColor: "#D4FAFF",
          border: selected ? `solid 2px${"#00AAFF"}` : "",
        }}
        className={`${"set_msgcard"} ${"commonHeader"}`}
      >
        <NodeHeader
          name={nodeName}
          icon={Icons.apiCallNodeLogo}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={false}
        />
     
        <div className="conditionNodeStyle">
        <div>
            {data.method && data.url && (
              <>
                <p>{data.method}</p>
                <p>
                  {data.url.length > MAX_CHARS
                    ? data.url.substring(0, MAX_CHARS) + "..."
                    : data.url}
                </p>
              </>
            )}
            
          </div>
          
            {(data.status_codes || []).map((status, index) =>{
              return           <div>
              <p>{status.status}
              </p>
               <Handle
                        id={status.id}
                        type="source"
                        position={Position.Right}
                        className="commonHandleStyle"
                        style={{
                          background: colorCode(parseInt(status.status))
                        }}
                      />
                     
                        </div>
            })}
            
            </div>
      </div>
    </div>
  );
}

export const colorCode = (statusCode) => {
  switch (Math.floor(statusCode / 100)) {
    case 2:
      return "green";
    case 3:
      return "orange";
    case 4:
      return "red";
    case 5:
      return "pink";
    default:
      return "";
  }
};
