import { useActiveComponent, useDropAccept, useIsEditor, useRenderedProps } from "../../src/stores/context";
import { useDrag } from "react-dnd";
import { useRef } from "react";
import {generateId} from "../../src/stores/util";

export const components = {};


export const registerComponent = (name, component) => {
    components[name] = component;
};
export const inputItems = () =>
    Object.values(components)
        .filter((comp) => comp.isInput)
        .map((comp) => comp.item.type);

export const allItems = () =>
    Object.values(components).map((comp) => comp.item);
export const allType = () => Object.keys(components);
export const allValues = () => Object.values(components);
export const allNodes = () =>
    Object.values(components).map((comp) => comp.node);
export const allPlays = () =>
    Object.values(components).map((comp) => comp.play);
export const getAny = (objType) =>
    Object.entries(components).find(([key, _]) => key === objType)[1];
export const getPlay = (type) => getAny(type).play;
export const getItem = (type) => getAny(type).item;
export const getInputs = (type) => getAny(type).inputs;

const GreenLine = ({ id, index }) => {
    // console.log(id, index)
    const { isOver, drop, itemType } = useDropAccept(id, allType(), getItem, index)

    return <div className={isOver ? "PBactiveSuccess" : "PBactiveSuccess1"} ref={drop}>
    </div>
}

export const EditorComponent = ({ id, type, props, children }) => {
    const isCtrlPressed = useRef(false);

    const [{ isDragging }, reference] = useDrag(
        () => ({
            type,
            item: { id, type, props, isCtrlPressed },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }),
        [id, type, props, isCtrlPressed.current]
    );

    const { setActive, activeId } = useActiveComponent();
    let isActive = id === activeId;

    return (
        <>
            <div
                id={id}
                onClick={(e) => {
                    if (!isActive) {
                        e.stopPropagation();
                        setActive(id);
                    }
                }}
                style={
                    isActive
                        ? {
                            border: "1px dotted red",
                            borderRadius: "3px",
                            // padding: "5px",
                            width: `${props?.width || 100}%`
                        }
                        : {
                            width: `${props?.width || 100}%`
                        }
                }
                ref={reference}
                onMouseDown={(event) => {
                    isCtrlPressed.current = event.ctrlKey;
                }}
            >
                {children}
            </div>
        </>
    );
};

export const SdkComponent = ({ id, type, props, Comp, components }) => {
    const renderedProps = useRenderedProps(props);
    return <div style={{width: `${props?.width || 100}%`}} id={id}>
        <Comp components={components} {...renderedProps} originalProps={props} id={id} type={type} />
    </div>
}



export const Components = ({ components, id }) => {
    const editor = useIsEditor();

    if (!components){
      return

    }

    return <>
        {(components.length !== 0 && editor) && <GreenLine id={id} index={0} />}
        {
            components.map((com, index) => {
                let Tag = getPlay(com.type)
                return editor ? <>
                    <EditorComponent {...com}>
                        <Tag id={com.id} {...com.props} components={com.components} />
                    </EditorComponent>
                    <GreenLine id={id} index={index + 1} />
                </> : <SdkComponent {...com} Comp={Tag} />
            })}
    </>

};
