import { create } from "zustand";


const useActive = create((set, get) =>({
  active: "flow",
  pageId: undefined,
  setActivePage: pageId => set({pageId, active: "page"}),
  setActiveStyle: () => set({active: "style"}),
  setActiveFlow: () => set({active: "flow"})
}))

export const usePreview = create((set, get) =>({
  color: "#FFF",
  type: "mobile",
  setColor: color => set({color}),
  setType: type => set({type})
}))

const createSelectors = _store =>{
  let store = {..._store, use: {}};

  for (let k of Object.keys(_store.getState())){
    store.use[k] = () => _store(s => s[k]);
  }
  return store
}

export const useActiveSelectors = createSelectors(useActive);

export default useActive;