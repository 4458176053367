import { emptyProps, FullContainer, Node, Style } from "../common";
import React, { useEffect, useMemo } from "react";
import { registerComponent } from "../components";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { useForm, useIsEditor, useIsForm } from "../../../src/stores/context";
import { useFonts, useTheme } from "../../../src/stores/selectors";

import * as Yup from 'yup';

export const type = "Input";

export const nameInput = {
  name: "Name",
  value: "name",
  type: "textInput",
  required: true,
  placeHolder: "Enter name"
};

export const inputs = [
  nameInput,
  {
    name: "Label",
    value: "label",
    type: "textInput",
    requried: true,
    autoFocus: true,
    placeHolder: "Enter ...!"
  },
  {
    name: "Place Holder",
    value: "placeholder",
    type: "textInput",
    requried: true,
    autoFocus: true,
    placeHolder: "Enter ...!"
  },
  {
    name: "default Value",
    value: "value",
    type: "textInput",
    requried: true,
    autoFocus: true,
    placeHolder: "Enter ...!"
  },
  {
    name: "Read Only",
    value: "readOnly",
    type: "toggle",
    requried: true,
    defaultValue: false
  },
  {
    name: "Required",
    value: "required",
    type: "toggle",
    defaultValue: true
  },
  {
    name: "Output Var",
    value: "output",
    type: "textInput",
    requried: true,
    autoFocus: true,
    placeHolder: "Enter ...!"
  },
  {
    name: "Horizonal Padding",
    value: "hPadding",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Vertical Padding",
    value: "vPadding",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Margin Top",
    value: "margintop",
    type: "progressInput",
    min: -100,
    max: 100,
    default: 0,
    required: true,
},
{
    name: "Margin Bottom",
    value: "marginbottom",
    type: "progressInput",
    min: -100,
    max: 100,
    default: 0,
    required: true,
},
  {
    name: "Width",
    value: "width",
    type: "progressInput",
    required: true
  },
  {
    name: "Height",
    value: "height",
    type: "textInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Label Color",
    value: "textColor",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  {
    name: "Color",
    value: "color",
    type: "colorInput",
    required: true,
    autoFocus: false
  },
  // {
  //     name: "Background Color",
  //     value: "bgColor",
  //     type: "colorInput",
  //     required: true,
  //     autoFocus: false,
  // },
  {
    name: "Border Radius",
    value: "radius",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Border Color",
    value: "bordercolor",
    type: "colorInput",
    autoFocus: false,
    required: true
  },
  {
    name: "Background Color",
    value: "bgcolor",
    type: "colorInput",
    required: true,
    autoFocus: false,
  },
  {
    name: "Box Shadow",
    value: "boxShadow",
    type: "progressInput",
    defaultValue: 0,
    required: true
  },
  {
    name: "Minimum",
    value: "min",
    type: "numberInput",
    required: true
  },
  {
    name: "Maximum",
    value: "max",
    type: "numberInput",
    required: true
  },
  {
    name: "Regex Pattern",
    value: "regex",
    type: "textInput",
    required: true
  },
  {
    name: "Type",
    value: "inputType",
    type: "select",
    requried: true,
    defaultValue: "text",
    options: [
      {
        name: "Text",
        value: "text"
      },
      {
        name: "Number",
        value: "number"
      },
      {
        name: "Email",
        value: "email"
      },
      {
        name: "Password",
        value: "password"
      },
      {
        name: "date",
        value: "date"
      }
    ]
  },
  FullContainer,
  Style
];

export const Input = {
  name: "Input",
  type,
  props: emptyProps
};

export const InputNode = () => {
  return (
    <Node type={type}>
      <div className="page-side-node br-right">
        <img src={PageBuilderAssets?.inputIcon} className="node-img" />
        <p className="node-name mb-2">Input</p>
      </div>
    </Node>
  );
};


const textTypes = ["text", "email", "password"];

export const InputPlay = ({
  id,
  label = "Name",
  style = "subtitle2",
  alignment = "center",
  inputType = "text",
  placeholder = "Enter",
  readOnly = false,
  required = false,
  textColor = "",
  color = "",
  bgcolor,
  vPadding = 0,
  hPadding = 0,
  margintop =0,
  marginbottom=0,
  width = "100",
  height = "",
  radius = 8,
  bordercolor = "",
  // boxShadow = "10px 10px 138px -11px rgba(0,0,0,0.75)",
  name,
  min, max, regex, value
}) => {
  const editor = useIsEditor();
  const fonts = useFonts();
  const font = useMemo(() => fonts[style], [fonts, style]);

  const theme = useTheme();
  const { isForm, formik, registerValidator } = useForm();
  const defaultColor = theme === "dark" ? "#f5f5f5" : "#F8F8F8";
  const bgfinalColor = bgcolor || defaultColor;
  const borderColor = bordercolor || defaultColor;
  const finalLabelColor = textColor || defaultColor;
  const finalInputColor = color || defaultColor;
  const placeholderDynamic = theme === "light" ? "light-placeholder" : "dark-placeholder";
  const handleOnChange = isForm ? formik?.handleChange : () => undefined
  const handleBlur = isForm ? formik?.handleBlur : () => undefined

  useEffect(() => {
    if (!editor && isForm) {
      let yup;
      if (textTypes.includes(inputType)) {
        yup = Yup.string()
      } else if (inputType === "number") {
        yup = Yup.number()
      } else if (inputType === "date") {
        yup = Yup.date()
      }
      if (inputType === "email") {
        yup = yup.email("Invalid Email Address")
      }
      if (!!regex) {
        yup = Yup.string().matches(regex, `Invalid ${inputType}`)
      }
      if (!!required) {
        yup = yup.required(`${name} is required`)
      }
      if (!!min) {
        yup = yup.min(min, `Must be ${min} ${textTypes.includes(inputType) ? "characters" : ""} or less`)
      }
      if (!!max) {
        yup = yup.min(min, `Must be ${min} ${textTypes.includes(inputType) ? "characters" : ""} or more`)
      }

      registerValidator(name, yup)
    }
  }, [])

  return (
    <>
      <div
        className="PBmainheadform"
      style={{
        marginTop:`${margintop}px`,
        marginBottom:`${marginbottom}px`
      }}
      >
        <label htmlFor="name" className="ml-1"
          style={{
            fontSize: font.size,
            fontWeight: font.weight,
            fontFamily: font.family,
            color: finalLabelColor,
            paddingBottom: "5px",
            float: "left"
          }}>
          {label} {required && <span className="PB-required-star"> *</span>}
        </label>
        <input
          type={inputType}
          name={name || id}
          id={id}
          
          value={isForm ? formik?.values[name] : value}
          onChange={handleOnChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          readOnly={editor || readOnly}
          required={required}
          disabled={editor}
          className={`${placeholderDynamic} form-control PBmainform`}
          style={{
            width: `${width}%`,
            height: `${height}px`,
            padding: `${vPadding}px ${hPadding}px`,
            borderRadius: `${Math.floor(radius / 2)}px`,
            border: `${theme === "light" ? `1px solid ${borderColor}` : `1px solid ${borderColor}`}`,
            WebkitColor: finalInputColor,
            // background: `${theme === "light" ? "#fff" : bgcolor}`
            background: bgfinalColor,
          }}
        />
        {formik?.touched[name] && formik?.errors[name] ? <div>{formik?.errors[name]}</div> : ''}
      </div>
    </>
  );
};

registerComponent(type, {
  item: Input,
  play: InputPlay,
  node: InputNode,
  inputs,
  isInput: true,
  category: "Inputs"
});
