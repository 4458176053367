import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  Handle,
  Position,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
import { NodeHeader, getMeasurements } from "../../Common";
import { TbMathSymbols } from "react-icons/tb";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-router-dom";
import Icons from "../../../../../assets/imageUrl";
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader";
import { useNodeData } from "../../../../store/selector";
import { crossClose } from "../../../../../assets/imageUrl";
import { crossPlus } from "../../../../../assets/imageUrl";
import { generateId } from "../../../../../utils/store";
import { deleteMatrixButton } from "../../../../../assets/imageUrl";
import {TextInput} from "../../../../components/inputs";
import {Select} from "../../../../components/select";
export const MatrixPanel = ({ id, type = "Matrix" }) => {
  const [data, setData] = useNodeData(id);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const options = [
    {name: "Select", value:"" },
    {name: "Range", value:"range" },
    {name: "Defined", value:"defined" },
  ];
  return (
    <>
      <div className="px-3">
        <SidePanelHeader nodeName="Matrix" />
        <section className="sideInnerParent">

            <TextInput
                name={type + " Name"}
                type="text"
                value={data?.name || ""}
                maxLength={15}
                setValue={(value) => setData({ ...data, name: value })}
                placeholder={type + " Name"}
                helpingText="Please give a name to the Matrix field"
            />
          <div>
            <TextInput
                name="Row name"
                type="text"
                value={data?.row?.name || ""}
                maxLength={15}
                setValue={(value) =>
                    setData({
                      ...data,
                      row: { ...data.row, name:value },
                    })
                }
                placeholder="Row name"
                helpingText="Please give a name to the Matrix field"
            />
            <TextInput
                name="Row Variable"
                type="text"
                value={data?.row?.variable || ""}
                maxLength={15}
                setValue={(value) =>
                    setData({
                      ...data,
                      row: { ...data.row, variable:value },
                    })
                }
                placeholder="Row Variable"
                helpingText="Please give a name to the Matrix field"
            />

            <Select
                name="Select Value Type"
                helpingText = "Please give a name to the Matrix field"

                setValue={(value) =>
                    setData({
                      ...data,
                      row: { ...data.row, _type: value },
                    })
                }
                value={data?.row?._type || ""}
                options={options}
            />

          </div>
          <div>
            <TextInput
                name="Column name"
                type="text"
                value={data?.col?.name || ""}
                maxLength={15}
                setValue={(value) =>
                    setData({
                      ...data,
                      col: { ...data.col, name: value },
                    })
                }
                placeholder="Column name"
                helpingText="Please give a name to the Matrix field"
            />
            <TextInput
                name="Column Variable"
                type="text"
                value={data?.col?.variable || ""}
                maxLength={15}
                setValue={(value) =>
                    setData({
                      ...data,
                      col: { ...data.col, variable: value },
                    })
                }
                placeholder="Column Variable"
                helpingText="Please give a name to the Matrix field"
            />

            <Select
                name="Select Value Type"
                helpingText = "Please give a name to the Matrix field"

                setValue={(value) =>
                    setData({
                      ...data,
                      col: { ...data.col, _type:value },
                    })
                }
                value={data?.col?._type || ""}
                options={options}
            />

          </div>
          <div>
            <TextInput
                name="Output Variable"
                type="text"
                value={data?.output || ""}
                maxLength={15}
                setValue={(value) => setData({ ...data, output: value })}
                placeholder="Output Variable"
                helpingText="Please give a name to the Matrix field"
            />
          </div>
          <div className="buttonSectionMat">
            <button onClick={openModal} className="createMatrixButton">
              <img src={crossPlus} alt="" />
              <span>Create </span>
            </button>
          </div>
        </section>
      </div>
      {showModal && (
        <MatrixModal
          closeMatrixModal={closeModal}
          data={data}
          setData={setData}
        />
      )}
    </>
  );
};

export const MatrixModal = ({ closeMatrixModal, data, setData }) => {
const inputRef = useRef(null);
const addCellValue=(r,c,updateValue)=>{
    
    if(data.values?.[r]=== undefined || data.values?.[r]=== null){
    
        let tempArray=[...data.values];
        
        tempArray[r]=[];
        
        tempArray[r][c]=updateValue;
        
        return setData({...data,values:tempArray})
    }
        let tempArray=[...data.values];
        tempArray[r][c]=updateValue;
        return setData({...data,values:tempArray})

}
  const handleFocus = (e) => {
    inputRef.current = e.target.id;
  };

  const addRow = () => {
    inputRef.current = null;
    const newRow = data.row.values
      ? [...data.row.values, { _id: generateId(), name: "name", value: 0 }]
      : [{ _id: generateId(), name: "name", value: 0 }];
    setData({ ...data, row: { ...data.row, values: newRow }});
    // setData({ ...data, values: [ ...data.values, [] ] });
  };

  const delRow = (target) => {
    const newRow = data.row.values.filter((e,i) => i !== target);
    const updatedMatrix=[...data.values].filter((e,i)=> i!==target)
    setData({ ...data, row: { ...data.row, values: newRow },values:updatedMatrix });
  };
  const delCol = (target) => {
    const newCol = data.col.values.filter((e,i) => i !== target);
    const updatedMatrix=[...data.values].map((col,i)=> col.filter((e,j)=> j !== target))
    setData({ ...data, col: { ...data.col, values: newCol },values:updatedMatrix });
  };
  const addCol = () => {
    inputRef.current = null;
    const newCol = data.col.values
      ? [...data.col.values, { _id: generateId(), name: "name", value: 0 }]
      : [{ _id: generateId(), name: "name", value: 0 }];


    setData({ ...data, col: { ...data.col, values: newCol } });
  };
  const editCol = (_id, outValue) => {
    const newCol = data.col.values.map((e) => (e._id === _id ? outValue : e));
    setData({ ...data, col: { ...data.col, values: newCol } });
  };

  const editRow = (_id, outValue) => {
    
    const newRow = data.row.values.map((e) => (e._id === _id ? outValue : e));

    setData({ ...data, row: { ...data.row, values: newRow } });
  };
  return (
    <section className="matrixModalPop">
      <div>
        <div className="bacg_mat_whi">
          <div className="update_matrix">
            <h3>Update Matrix Value</h3>
            <img onClick={closeMatrixModal} src={crossClose} alt="" />
          </div>
          <p className="matrix_desc">
            Select the range for the respective rows and columns .
          </p>
          <div className="grid_sec_mat">
            <div className="grid_sec_inner">
              <p>Enter the Rows Name and Value</p>
              <div className="render_input_mat">
                {data.row?.values && data.row?.values.length >= 1
                  ? data.row.values.map((ed,i) => {
                      return (
                        <div key={generateId()} className="grid_handle_mat">
                          <div>
                            <label htmlFor="">Value Name</label>
                            <br />
                            <input
                              id={`${ed._id}name`}
                              onFocus={handleFocus}
                              autoFocus={inputRef.current === `${ed._id}name`}
                              type="text"
                              value={ed?.name || ""}
                              onChange={(e) =>
                                editRow(ed._id, {
                                  ...ed,
                                  ["name"]: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div>
                            <label htmlFor="">Value</label>
                            <br />
                            <input
                              id={`${ed._id}value`}
                              onFocus={handleFocus}
                              autoFocus={inputRef.current === `${ed._id}value`}
                              value={ed.value || ""}
                              onChange={(e) =>
                                editRow(ed._id, {
                                  ...ed,
                                  ["value"]: e.target.value,
                                })
                              }
                              type="number"
                            />
                          </div>
                          <div>
                            <button onClick={() => delRow(i)}>
                              <img src={deleteMatrixButton} alt="del" />
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="buttonSectionMat mat_add_butt">
                <button onClick={addRow} className="createMatrixButton">
                  <img className="mat_add_cross" src={crossClose} alt="" />
                  <span>Add row</span>
                </button>
              </div>
            </div>
            <div className="grid_sec_inner">
              <p>Enter the Column Name and Value</p>

              <div className="render_input_mat_twice">
                {data.col?.values && data.col?.values.length >= 1
                  ? data.col.values.map((ed, i) => {
                      return (
                        <div key={generateId()} className="grid_handle_mat">
                          <div>
                            <label htmlFor="">Value Name</label>
                            <br />
                            <input
                              onFocus={handleFocus}
                              id={`${ed._id}name`}
                              autoFocus={inputRef.current === `${ed._id}name`}
                              value={ed.name || ""}
                              onChange={(e) =>
                                editCol(ed._id, {
                                  ...ed,
                                  ["name"]: e.target.value,
                                })
                              }
                              type="text"
                            />
                          </div>
                          <div>
                            <label htmlFor="">Value</label>
                            <br />
                            <input
                              onFocus={handleFocus}
                              id={`${ed._id}value`}
                              autoFocus={inputRef.current === `${ed._id}value`}
                              value={ed.value || ""}
                              onChange={(e) =>
                                editCol(ed._id, {
                                  ...ed,
                                  ["value"]: e.target.value,
                                })
                              }
                              type="number"
                            />
                          </div>
                          <div>
                            <button onClick={() => delCol(i)}>
                              <img src={deleteMatrixButton} alt="del" />
                            </button>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>

              <div className="buttonSectionMat mat_add_butt">
                <button onClick={addCol} className="createMatrixButton">
                  <img className="mat_add_cross" src={crossClose} alt="" />
                  <span>Add column</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="table_appear_sec">
          <div className="top_cap_mat">
            <p>{data.col?.name}</p>
          </div>
          <div className="left_cap_mat">
            <p>{data.row?.name}</p>
          </div>

          <div className="table_mat_sec">
            <table>
              <tr>
                <th>0</th>
                {data.col?.values && data.col?.values.length >= 1
                  ? data.col?.values.map((cc) => (
                      <th>
                        <p>{cc.name}</p>

                        <p className="light_mat_p">{cc.value}</p>
                      </th>
                    ))
                  : null}
              </tr>

              <tbody>
                {data.row?.values && data.row?.values.length >= 1
                  ? data.row?.values.map((rc, i) => {
                      return (
                        <tr>
                          <th>
                            <p>{rc.name}</p>

                            <p className="light_mat_p">{rc.value}</p>
                          </th>
                          {data.col?.values && data.col?.values.length >= 1
                            ? data.col?.values.map((cc,j) => (
                                <td>
                                 <input id={`cell_uni${i}${j}`} onChange={(e)=>addCellValue(i,j,e.target.value)} onFocus={handleFocus} autoFocus={inputRef.current===`cell_uni${i}${j}`} type="text"  value={ data.values?.[i]?.[j] || ""}/>
                                </td>
                              ))
                            : null}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="buttonSectionMat create_mat_butt_sec">
          <button onClick={""} className="createMatrixButton">
            <span>Create</span>
          </button>
        </div>
      </div>
    </section>
  );
};
const Matrix = ({ id, data, selected }) => {
  const store = useStoreApi();
  const updateNodeInternals = useUpdateNodeInternals();

  updateNodeInternals(id);

  const [, Right] = getMeasurements(id, store);
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected);
  }, [selected]);
  return (
    <>
      <div
        style={{
          backgroundColor: "#ffecec",
          border: selected ? `solid 2px${"#FF9900"}` : "",
        }}
        className={
          `${"set_msgcard"} ${"commonHeader"}`
          //    +
          //     (data.isStartNode ? "start_node" : "") +
          //     (!data.isStartNode && selected ? "selected_node" : "")
        }
      >
        <NodeHeader
          name={"Matrix"}
          icon={Icons.conditionNodeLogo}
          accessSelected={accessSelected}
        />
        <div className="matrixNodeStyle">
          <div>
            <p>POST</p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            ></Handle>
          </div>
        </div>
      </div>
    </>
  );
};

export default Matrix;
