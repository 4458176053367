import { NodeHeader, StartNode, commonInputs } from "../../Common";
import { TbVariable } from "react-icons/tb";
import { Items } from "../APICall/Apicall";
import {
  Handle,
  Position,
} from "reactflow";
import React, { useState, useEffect } from "react";
import Icons from "../../../../../assets/imageUrl";
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader";
import { useNodeData } from "../../../../store/selector";
const nodeName = "UpdateVariable";

export const UpdateVariablePanel = ({ id, type = nodeName, vars = [] }) => {
  const [data, setData] = useNodeData(id);

  return (
    <div className="right_panel_workflow">
      <SidePanelHeader nodeName={nodeName} />
<section className="sideInnerParent">

      <div >
        <label className="nodePanel-labels">{type + " Name"} </label>
<div className="labelInputFull">
<input
          type="text"
          className="nodePanel-inputs"
          value={data?.name || ""}
          maxLength={15}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          placeholder={type + " Name"}
        />
</div>
       
      </div>

    
        <Items
          name="Variables"
          items={data.variables || []}
          updateItems={(items) => setData({ ...data, variables: items })}
          vars={vars}
        />
</section>
    </div>
  );
};


export const inputs = [
  ...commonInputs,
  {
    name: "Variables",
    value: "variables",
    defaultValue: [],
    type: "listItems",
    props:{
      deleteButton: true,
      children: [
        {
          name: "Name",
          value: "name",
          defaultValue: "",
          type: "textInput",
        },
        {
          name: "Value",
          value: "value",
          defaultValue: "",
          type: "textInput",
        },
      ]
    }
  },
]


const UpdateVariable = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <div
      className={
        " "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div style={{ backgroundColor: "#E1FFE6", border: selected ? `solid 2px${"#00B51F"}` : "" }} className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={nodeName}
          icon={Icons.updateNodeLogo}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="updateNodeStyle">
          <div>
            <p>POST</p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateVariable;
