import React, { useState } from "react";

const DoubleClickDiv = ({ children, className, onClick }) => {
  const [lastClickTime, setLastClickTime] = useState(0);

  const doubleClickTimeThreshold = 300;

  const handleDoubleClick = () => {
    const currentClickTime = new Date().getTime();

    if (currentClickTime - lastClickTime <= doubleClickTimeThreshold) {
      onClick();
    }

    setLastClickTime(currentClickTime);
  };

  return (
    <div className={className} onClick={handleDoubleClick}>
      {children}
    </div>
  );
};

export default DoubleClickDiv;
