import { useIsEditor } from "../../../src/stores/context";
import { Alignment, FullContainer, Style, Node, emptyProps } from "../common";
import { registerComponent } from "../components";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { useTheme } from "../../../src/stores/selectors";
import { nameInput } from "./input";

export const type = "DropDown";

export const inputs = [
    nameInput,
    {
        name: "Label",
        value: "label",
        type: "textInput",
        requried: true,
        autoFocus: true,
        placeHolder: "Enter ...!",
    },
    {
        name: "Options",
        value: "options",
        type: "DropDown",
        requried: true,
        autoFocus: true,
        placeHolder: "Value",
    },
    {
        name: "Label Color",
        value: "textColor",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
    {
        name: "Width",
        value: "width",
        type: "progressInput",
        required: true,
    },
    {
        name: "Color",
        value: "color",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
    {
        name: "Background Color",
        value: "bgColor",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
    {
        name: "Border Radius",
        value: "radius",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Box Shadow",
        value: "boxShadow",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },

    FullContainer,
];

export const DropDown = {
    name: "dropDown",
    type,
    props: emptyProps,
};

export const DropDownNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.selectIcon} className="node-img" />
                <p className="node-name mb-2">Select</p>
            </div>
        </Node>
    );
};

export const DropDownPlay = ({
    id,
    label = "Name",
    inputType = "select",
    required = false,
    output,
    color = '',
    bgColor = 'rgb(33 36 39)',
    width = "150px",
    radius = 8,
    options = [],
    boxShadow = "10px 10px 138px -11px rgba(0,0,0,0.75)",
}) => {
    const editor = useIsEditor();
    const theme = useTheme();
    const defaultColor = theme === "dark" ? "#f5f5f5" : "#212427";
    const finalLabelColor = color || defaultColor;
    const placeholderDynamic = theme === "light" ? 'light-placeholder' : 'dark-placeholder';
    return (
        <>
            <div
                className="PBmainheadform"
            >
                <label htmlFor="name" className="ml-1"
                    style={{
                        color: finalLabelColor
                    }}>
                    {label} {required && <span className="PB-required-star"> *</span>}
                </label>
                <div className="d-flex">
                    <select
                        className={`${placeholderDynamic} PBmainform`}
                        id="testSelect"
                        style={{
                            width: `${width * 8}px`,
                            borderRadius: `${Math.floor(radius / 2)}px`,
                            boxShadow: `10px 10px ${Math.floor(
                                boxShadow * 1.5
                            )}px -10px rgba(0,0,0,0.75)`,
                            border: `${theme === "light" ? "1px solid #212427" : "1px solid rgb(137 137 137)"}`,
                            color: finalLabelColor,
                            background: `${theme === "light" ? "#fff" : bgColor}`
                        }}
                        type={inputType} disabled
                    >
                        <option>Select option</option>
                        {options.map(option => <option value={option?.value}>{option?.name}</option>)}
                    </select>
                    {required && <span className="PB-required-star"> *</span>}
                </div>
            </div>
        </>
    );
};


registerComponent(type, {
    item: DropDown,
    play: DropDownPlay,
    node: DropDownNode,
    inputs,
    isInput: true,
    category: "Inputs",
});