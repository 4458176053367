import React from 'react';
import Select from "react-select";
import './index.css';
const CustomSelect = (props) => {
 const {options,className,isSearchable, placeholder,onBlur,
    defaultValue,onChange,isClearable,isRtl,isDisabled,label,labelfor,isLoading,value} = props
    return (
  <React.Fragment>
    <div className='node_builder_select'>
      {label && <label for={labelfor} className="labelClass">{label}</label> }
      <Select
        className={`id_flow_select`}
        classNamePrefix="select"
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={options}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        styles={{
        option: (base,{isFocused}) => ({
            ...base,
            borderRadius: "6px",
            height: '100%',
            margin:"auto",
            backgroundColor: isFocused ? "rgba(208, 215, 222, 0.32)" :"",
            color: isFocused ? "#000" : "#000"
          }),
        }}
        {...props}
      />
    </div>
  </React.Fragment>
)};
export default CustomSelect;


