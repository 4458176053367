import { Alignment, emptyProps, FullContainer, Node, Style } from "../common";
import React from "react";
import { registerComponent } from "../components";
import { useIsEditor } from "../../../src/stores/context";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { useTheme } from "../../../src/stores/selectors";
export const type = "Link";

export const inputs = [
    {
        name: "Text",
        value: "text",
        type: "textInput",
        requried: true,
        autoFocus: true,
        placeHolder: "Enter ...!",
        defaultValue: "Enter Some Text here...!",
    },
    {
        name: "URL",
        value: "url",
        type: "urlInput",
        placeHolder: "https://example.com",
    },
    Style,
    {
        name: "Horizonal Padding",
        value: "hPadding",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Vertical Padding",
        value: "vPadding",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Color",
        value: "textColor",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
    Alignment,
    FullContainer,
];

export const Link = {
    name: "Link",
    type,
    props: emptyProps,
};

export const LinkNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.linkIcon} className="node-img" />
                <p className="node-name mb-2">Link</p>
            </div>
        </Node>
    );
};

export const LinkPlay = ({
    id,
    text = "Link",
    style = 16,
    url = "https://google.com",
    alignment = "center",
    hPadding = 0,
    vPadding = 0,
    textColor = "",
}) => {
    const theme = useTheme();
    const defaultColor = theme === "dark" ? "#f5f5f5" : "#212427";
    const finalTextColor = textColor || defaultColor;
    let editor = useIsEditor();

    let child = (
        <p
            style={{
                fontSize: parseInt(style),
                fontWeight: "500",
                margin: "0rem 0rem",
                textAlign: alignment,
                padding: `${vPadding}px ${hPadding}px`,
                color: finalTextColor,
                textDecoration: "none",
                cursor: "pointer",
            }}
        >
            {text}
        </p>
    );
   

    return (
        <div style={{ fontSize: "1rem", fontWeight: "500", color: finalTextColor }}>
            {editor ? (
                <p style={{ margin: "0px" }}>{child}</p>
            ) : (
                <a href={url} style={{textDecoration:"none"}}>{child}</a>
            )}
        </div>
    );
};

registerComponent(type, {
    item: Link,
    node: LinkNode,
    play: LinkPlay,
    inputs,
    category: "Buttons",
});
