import {createContext, useContext, useMemo} from "react";
import {useDrop} from "react-dnd";
import {deleteComponentById, findComponentById, generateId, moveComponentById, replaceComponentById} from "./util";


export const PageContext = createContext(null);
export const PageUpdateContext = createContext(null)
export const FormContext = createContext(null);


export const useMode = () => {
    const { mode } = useContext(PageContext);
    return mode;
};

export const useIsEditor = () => {
    const mode = useMode();
    return mode === "editor";
};

export const useIsDebug = () =>{
    const { debug } = useContext(PageContext);
    return debug
}

export const useIsSdk = () =>{
    const mode = useMode();
    return mode === "sdk";
}


export const useEvents = () => {
    let {events} = useContext(PageContext);
    let editor = useIsEditor();

    const event_s = useMemo(() =>{
        let new_events = {};
        for (const key in events){
            new_events[key] = editor ? () => {}: events[key]
        }
        return new_events
    }, [editor, events])

    return {...event_s}

}
let nunjucks = require("nunjucks")

export const useRenderedProps = (originalProps) =>{
    const {vars} = useContext(PageContext);


    return useMemo(() => {
        let newProps = {};

        for (const key in originalProps) {
            let value = originalProps[key];

            if (typeof value === "string") {
                value = nunjucks.renderString(value, vars || {})
            }

            newProps[key] = value
        }
        return newProps
    }, [originalProps, nunjucks, vars])

}

export const useRenderedValues = () => {
    const {vars} = useContext(PageContext);
    return value => typeof value === "string" ?nunjucks.renderString(value || "", vars || {}): value
}
export const useVars = () =>{
    const {vars} = useContext(PageContext);
    return vars
}

export const useUndo = () => {
    const { undo } = useContext(PageUpdateContext);
    return undo;
};

export const useRedo = () => {
    const { redo } = useContext(PageUpdateContext);
    return redo;
};

export const usePage = () => {
    const { page } = useContext(PageContext);
    const { setPage } = useContext(PageUpdateContext);

    const deleteComponent = (id) => {
        let p = deleteComponentById(page, id);
        setPage({ ...p });
    };

    const updateComponent = (component) => {
        let p = replaceComponentById(page, component.id, component);
        setPage({ ...p });
    };

    return {
        page,
        setPage,
        id: page.id,
        name: page.name,
        setName: (name) => setPage({ ...page, name }),
        components: page.components,
        updateComponent,
        deleteComponent,
    };
};

export const useComponent = (id) => {
    const { page } = useContext(PageContext);
    const { setPage } = useContext(PageUpdateContext);

    const thisComponent = useMemo(() => findComponentById(page, id), [page, id]);

    const render = () => setPage({ ...page });

    const addComponent = (component) => {
        if (!thisComponent.components) {
            thisComponent.components = [];
        }
        thisComponent.components.push(component);
        render();
    };

    const deleteComponent = (id) => {
        thisComponent.components = thisComponent.components.filter(
            (component) => component.id !== id
        );
        render();
    };

    const updateComponent = (component) => {
        thisComponent.components = thisComponent.components.map((comp) => {
            if (comp.id === component.id) {
                return component;
            }
            return comp;
        });

        render();
    };

    const moveComponent = (id, index) => {
        moveComponentById(page, id, thisComponent.id, index);
        render();
    };

    return {
        thisComponent,
        components: thisComponent.components || [],
        addComponent,
        updateComponent,
        deleteComponent,
        moveComponent,
    };
};

export const useActiveComponent = () => {
    const { activeComponent, page } = useContext(PageContext);
    const { setActiveComponent } = useContext(PageUpdateContext);

    let component = useMemo(
        () => findComponentById(page, activeComponent),
        [page, activeComponent]
    );

    return {
        activeId: activeComponent,
        active: component,
        setActive: setActiveComponent,
    };
};

export const useDropAccept = (id, types, getItem, index = 0) => {
    const { addComponent, moveComponent } = useComponent(id);
    const { page } = usePage();

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: types,
            drop: ({ id, type, props = {}, isCtrlPressed }, monitor) => {
                console.log(id, type, isCtrlPressed);
                if (!monitor.didDrop()) {
                    if (
                        isCtrlPressed === undefined ||
                        (isCtrlPressed && isCtrlPressed.current === true)
                    ) {
                        // CTRL is pressed copy the component
                        addComponent({
                            id: generateId(10),
                            ...getItem(type),
                            props,
                        });
                    } // Otherwise move the component
                    else {
                        moveComponent(id, index);
                    }
                }
            },
            collect: (monitor) => ({
                isOver: !!monitor.isOver({ shallow: true }),
            }),
        }),
        [page]
    );

    return {
        isOver,
        drop,
    };
};


export const useForm = () =>{
    const form = useContext(FormContext);

    return form || {isForm: false}
}

export const useIsForm = () =>{
    const {isForm = false, } = useForm();

    return isForm

}


export const useIsButtonLoading = compId =>{
    const {loading} = useContext(PageContext);
    const editor = useIsEditor();
    return editor ? false: loading === compId
}