import { useNodeId } from "reactflow";
import { usePreview } from "./activeStore";
import useFlow, { useNodes, useSettings } from "./flowStore";

export const useSetToDefault = () => {
  return useFlow(flow => flow.setToDefault);
}

export const useFromWorkFlow = () => {
  return useFlow(flow => flow.setFromWorkflow)
}

export const useFlowName = () => {
  const name = useFlow(flow => flow.name);
  const setName = useFlow(flow => flow.setName);

  return [name, setName]
}

export const useFlowType = () => {
  const type = useFlow(flow => flow.type);
  const setType = useFlow(flow => flow.setType);

  return [type, setType]
  
}

export const useCustomNodes = () => {
  const nodes = useNodes(custom => custom.nodes);
  const setNodes = useNodes(custom => custom.setNodes)

  return [nodes, setNodes]
}

export const useRemoveNode = (id) => {
  // Must be called inside the Custom Node Body
  const nodeId = id || useNodeId();
  const removeNode = useFlow(state => state.removeNode);
  return () => removeNode(nodeId);
}

export const useSelectedNode = () => {
  const nodes = useFlow(flow => flow.nodes);
  return nodes.find(node => node.selected === true);
}
export const useNode = (nodeId) => {
  return useFlow(flow => flow.nodes.find(node => node.id === nodeId));
}

export const useNodeData = (nodeId) => {
  const thisNode = useNode(nodeId);
  const setNodes = useFlow(flow => flow.setNodes);

  if (thisNode === undefined) {
    return [undefined, () => undefined]
  }

  return [thisNode.data, data => setNodes(nodes => nodes.map(node => {
    if (node.id === nodeId) {
      node.data = data
    }
    return node
  }))]
}

export const useActivePage = () => {
  const setActivePage = useFlow(flow => flow.setActivePage);
  const activePageId = useFlow(flow => flow.activePage);
  const activePage = useFlow(flow => flow.nodes.find(node => node.id === activePageId))
  return [activePageId, setActivePage, activePage];
}

export const useSelectedPage = () => {
  const nodes = useFlow(flow => flow.nodes);
  return nodes.find(node => node.selected === true && node.type === "Page")
}


export const usePreviewColor = () => {
  const color = usePreview(p => p.color);
  return [color, usePreview(p => p.setColor)]
}

export const usePreviewType = () => {
  const type = usePreview(p => p.type);
  return [type, usePreview(p => p.setType)]
}

export const usePageWithId = id => {
  const page = useFlow(flow => flow.nodes.find(node => node.id === id));

  return useFlow(flow => flow.nodes.find(node => node.id === id));
}

export const useFromSettings = () => {
  return useSettings(settings => settings.setSettings)
}


export const usePortalSettings = () => {
  const portal = useSettings(settings => settings.portal);
  return [portal, useSettings(settings => settings.setPortalSettings)]
}

export const useBrandSettings = () => {
  const brand = useSettings(settings => settings.brand);
  return [brand, useSettings(settings => settings.setBrandSettings)]
}