import {Cron} from "react-js-cron";
import React , {useState} from 'react';
// import 'react-js-cron/dist/styles.css';
export const CronInput = props =>{

    let {value, setValue} = props;

    return <><div className="RightCronJob">
        <Cron
        value={value}
        setValue={value => setValue(value)}
        // setValue={value}
    /></div></>
}

