import { Alignment, emptyProps, Node } from "./common";
import {useComponent, useDropAccept, useIsEditor} from "../../src/stores/context";
import { allType, Components, getItem, registerComponent } from "./components";
import { useTheme } from "../../src/stores/selectors";
import { useEffect } from "react";

export const type = "MainContainer";

export const inputs = [
    Alignment,
    {
        name: "Background Color",
        value: "bgcolor",
        type: "colorInput",
        required: true,
        autoFocus: false,
      },
      {
        name: "Grid Layout",
        value: "gridrowcount",
        type: "progressInput",
        defaultValue: 0,
        required: true,
      },
      {
        name: "Horizonal Padding",
        value: "hPadding",
        type: "progressInput",
        defaultValue: 0,
        required: true,
      },
      {
        name: "Vertical Padding",
        value: "vPadding",
        type: "progressInput",
        defaultValue: 0,
        required: true,
      },
    {
        name: "Direction",
        value: "direction",
        type: "direction",
        options: [
            {
                name: "Row",
                value: "row",
            },
            {
                name: "Column",
                value: "column",
            },
           
        ],
        
    },
];
export const MainContainer = {
    type,
    props: emptyProps,
};

/* Make Main container node as empty*/
export const MainContainerNode = () => {
    return <></>;
};

export const MainContainerInner = ({
    id,
    width = "100%",
    height = "100%",
    alignment = "center",
    gridrowcount=0,
    bgcolor="",
    hPadding = "0",
    vPadding = "10",
    direction = "column",
    children,
    components,
}) => {
    const editor = useIsEditor();
  const theme = useTheme();
  const defaultColor = theme === "light" ? "#fff" : "#000";
  const finalBgColor = bgcolor || defaultColor;
    return (
        <>
      <main 
    //   style={{height:'100%'}}
      >
            <section
                style={{
                    width: "100%",
                    
                    height:"calc(93vh - 0px)",
                    // border: "1px dotted #ddd",
                    // height: 'calc(100vh - 0px)',
                    borderRadius: "4px",
                    background: finalBgColor,
                    // background:'#0dcaf0',
                    position:'relative',
                    padding: `${vPadding}px ${hPadding}px`,
                    display: "grid",
                    grigGap:'0.5rem',
                    gridAutoFlow: 'row',
                    // height: height === 0 ? "auto" :`${parseInt(height)}vh`,
                    gridAutoRows:gridrowcount === 0 ? "auto": `${gridrowcount}%`,
                   
                    
                    // justifyContent: alignment,
                    // flexDirection: direction,
                    gap: "0.5rem",
                }}
                className="fixLayout"
            >
                <div className="PBactiveSuccess"></div>
                {/* <main
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: alignment,
                        flexDirection: direction,
                        gap: "1rem",
                    }}
                ></main> */}
                    <Components components={components} id={id} />
                    {children}
                
            </section>
            </main>
        </>
    );
};

export const MainContainerPlayEditor = props =>{
    const { components } = useComponent(props.id);

    const { drop, isOver } = useDropAccept(props.id, allType(), getItem);

    return (
        <div ref={drop} style={{ background: isOver ? "" : "" }}>
            <MainContainerInner {...props} components={components} />
        </div>
    );

}

export const MainContainerPlay = (props) => {
    const editor = useIsEditor();
    return editor ? <MainContainerPlayEditor {...props} />: <MainContainerInner {...props} />
};

registerComponent(type, {
    item: MainContainer,
    play: MainContainerPlay,
    node: MainContainerNode,
    inputs,
    category: "Basic",
});
