import React, { useState } from 'react'
import { GrClose } from 'react-icons/gr'
import useFlow from '../../WorkFlowBuilder/store/flowStore'
import Icons, { accessMenuToggle, deleteModelImg, penModelImg } from "../../assets/imageUrl";
import { customCodeIcon } from '../../assets/imageUrl';

const imageIcon = {
    Webhook: Icons.webHookColor,
    "Custom Code": customCodeIcon,
    key3: 'image3.jpg',
};



const SidePanelHeader = ({ nodeName }) => {
    const [accessMenu, setAccessMenu] = useState(false);
    const setNodes = useFlow(flow => flow.setNodes);
    const removeSelectedNode = () => setNodes(nodes => nodes.map(node => { node.selected = false; return node }));

    const matchedIcon = imageIcon[nodeName];


    return (
        <div className="rightSidePanelHeader">
            <div>

                {matchedIcon ? (
                    <img src={matchedIcon} alt={`Image for ${nodeName}`} className="IconRightHeader" />
                ) : (
                    <img src={Icons.conditionNodeLogo} alt={nodeName} className="IconRightHeader" />
                )}

                <p >{nodeName}</p>
            </div>
            <div>
                <img src={Icons.accessMenuToggle} alt="Icons" className="cursorPointer" onClick={() => {
                    setAccessMenu(!accessMenu);
                }} />
            </div>
            {accessMenu && (
                <div className="accessMenu RightDropdown">

                    <div
                        className="cursorPointer"
                    // onClick={handleShow}
                    >
                        <img src={Icons.deleteModelImg} alt="delete" />&nbsp;&nbsp;
                        <p>Delete</p>
                    </div>
                    <div
                        className="cursorPointer"
                    // onClick={handleShow}
                    >
                        <img src={Icons.PopupIcon} alt="delete" />&nbsp;&nbsp;
                        {/*<p>Switch to pop-up mode</p>*/}
                    </div>

                </div>
            )}
            {/*           <div>*/}
            {/*<GrClose className="cpoint" onClick={removeSelectedNode}/>*/}
            {/*</div>*/}
        </div>
    )
}

export default SidePanelHeader