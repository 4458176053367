import React from "react";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-python";

import "ace-builds/src-noconflict/theme-twilight";

import "ace-builds/src-noconflict/theme-monokai";

import "ace-builds/src-noconflict/snippets/javascript";
import "ace-builds/src-noconflict/snippets/typescript";
import "ace-builds/src-noconflict/snippets/python";

import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-language_tools";


const CodeEditor = (props) => {
    const { mode, theme, name, width, height, placeholder, handleEditorChange, value } = props
    return (
        <>
            <AceEditor
                readOnly={props.disabled}
                mode={mode || "javascript"}
                theme={theme || "twilight"}
                name={name || "UNIQUE_ID_OF_DIV"}
                fontSize={14}
                // editorProps={{ $blockScrolling: true }}
                onChange={handleEditorChange}
                width={width || "100%"}
                height={height || "350px"}
                style={{ borderRadius: "8px" }}
                placeholder={placeholder || "// Your Code Here....."}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={true}
                setOptions={{
                    useWorker: false,
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
                value={value || ""}
            />
        </>
    )
}

export default CodeEditor