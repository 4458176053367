import { NodeHeader, StartNode, commonInputs } from "../../Common";
import { TbVariable } from "react-icons/tb";
import { Items } from "../APICall/Apicall";
import {
  Handle,
  Position,
} from "reactflow";
import React, { useState, useEffect } from "react";
import Icons from "../../../../../assets/imageUrl";
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader";
import { useNodeData } from "../../../../store/selector";
import { timeDelayColor } from "../../../../../assets/imageUrl";
import { Input, TextInput } from "../../../../components/inputs";
import { Select } from "../../../../components/select";
const nodeName = "TimeDelay";

export const inputs = [
  ...commonInputs,
  {
    name: "Seconds",
    value: "seconds",
    defaultValue: 1,
    type: "numberInput"
  }
]


export const TimeDelayPanel = ({ id, type = nodeName, vars = [] }) => {
  const [data, setData] = useNodeData(id);

  return (
    <div className="">
      <SidePanelHeader nodeName={nodeName} />
      <section className="sideInnerParent px-3 ptb-20">



        <TextInput
          name={type + " Name"}
          type="text"
          value={data?.name || ""}
          maxLength={15}
          setValue={(value) => setData({ ...data, name: value })}
          placeholder={type + " Node" + " Name"}
          helpingText="Please give a name to the TimeDelay field"
        />

        <TextInput
          name={type + " Name"}
          type="text"
          value={data?.name || ""}
          maxLength={15}
          setValue={(value) => setData({ ...data, name: value })}
          placeholder={type + " Node" + " Name"}
          helpingText="Please give a name to the TimeDelay field"
        />
        <TextInput
          name="Seconds"
          type="text"
          value={data?.delseconds || ""}
          setValue={(value) => setData({ ...data, delseconds: value })}
          placeholder="Seconds"
          helpingText="Please give a name to the TimeDelay field"
        />
        <TextInput
          name="Variable"
          type="text"
          value={data?.variable || ""}
          setValue={(value) => setData({ ...data, variable: value })}
          placeholder="Variable"
          helpingText="Please give a name to the TimeDelay field"
        />
      </section>
    </div>
  );
};

const TimeDelayNode = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <div
      className={
        " "
        // +
        // (data.isStartNode ? "start_node" : "") +
        // (!data.isStartNode && selected ? "selected_node" : "")
      }
    >
      <div style={{ backgroundColor: "#E1FFE6", border: selected ? `solid 2px${"#00B51F"}` : "" }} className={`${"set_msgcard"} ${"commonHeader"}`}>
        <NodeHeader
          name={nodeName}
          icon={timeDelayColor}
          // onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        ></NodeHeader>
        <div className="updateNodeStyle">
          <div>
            <p>POST</p>
            <Handle
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeDelayNode;
