export function accessToken() {
  return localStorage.getItem("accessToken");
}


export function generateId(length=10) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

