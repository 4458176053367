import { Alignment, FullContainer, Style, Node, emptyProps } from "../common";
import { registerComponent } from "../components";
import { useEvents, useIsEditor, useIsForm } from "../../../src/stores/context";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";

const type = "TimerButton";

export const inputs = [

    {
        name: "Text",
        value: "text",
        type: "textInput",
        required: true,
        autoFocus: true,
        placeHolder: "Button Name",
        defaultValue: "Click Me",
    },

    {
        name: "Icon URL",
        value: "icon",
        type: "textInput",
        required: true,
        autoFocus: false,
    },
    {
        name: "Set Time",
        value: "time",
        type: "timerInput",
        placeHolder: "00:00 sec",
        required: true,
    },
    Style,

    {
        name: "Background Color",
        value: "bgcolor",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },

    {
        name: "Color",
        value: "color",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
    {
        name: "Border Radius",
        value: "borderRad",
        type: "progressInput",
        required: true,
        defaultValue: 0,
    },
    {
        name: "Width",
        value: "width",
        type: "progressInput",
        required: true,
        autoFocus: false,
    },
    {
        name: "Horizonal Padding",
        value: "hPadding",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Vertical Padding",
        value: "vPadding",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Box Shadow",
        value: "boxShadow",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    Alignment,
    FullContainer,

]

export const TimerButton = {
    name: "TimerButton",
    type,
    props: emptyProps,
};


export const TimerButtonNode = () => {
    return (
        <Node type={type} >
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.timerBtn} className="node-img" />
                <p className="node-name mb-2">Timer Button</p>
            </div>
        </Node>
    );
};


export const TimerButtonPlay = ({
    id,
    text = "Click Me",
    style,
    hPadding = "16",
    vPadding = "10",
    alignment = "center",
    bgcolor = "#c7a663",
    color = "#000",
    width = "100",
    boxShadow = "5px 5px 21px #6c6c6c, 5px 5px 21px #ffffff",
    icon,
    time = "00:00",
    borderRad = "0px",

}) => {
    const editor = useIsEditor();
    const form = useIsForm();
    const { buttonClick } = useEvents();

    const handleClick = () => {
        if (editor || form) { // Don't process click in editor mode or in Form
            return
        }
        buttonClick(id)
    }

    return (
        <>
            <div style={{ textAlign: alignment }}>
                <button onClick={handleClick}
                    type={form ? "submit" : undefined}
                    style={{
                        fontWeight: 600,
                        margin: "10px auto",
                        color: color,
                        background: bgcolor,
                        borderRadius: borderRad,
                        boxShadow: `5px 5px ${Math.floor(
                            boxShadow * 2
                        )}px #6c6c6c, 5px 5px ${Math.floor(boxShadow * 2)}px #fff`,
                        width: `${width}%`,
                        padding: `${vPadding}px ${hPadding}px`,
                        fontSize: "1rem",
                    }}
                >
                    {icon && (
                        <img src={icon} alt="Invalid Icon" className=" PBclrwhite PBsvg" />
                    )}
                    &nbsp; {text} &nbsp; {time}
                </button>
            </div>
        </>
    );
};

registerComponent(type, {
    node: TimerButtonNode,
    play: TimerButtonPlay,
    item: TimerButton,
    inputs,
    category: "Buttons",
});
