import { React, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "./Components/core/Button";
import { useSetToDefault } from "./WorkFlowBuilder/store/selector";
import { deleteWorkflow, getWorkflows } from "./api/workflows";
import Icons from "./assets/imageUrl";
import PlusIcon from "./assets/plus-icon.svg";
import {getQueryString} from './WorkFlowBuilder/hooks';
import "./styles/workSpace.css";

const WorkFlow = () => {

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [isMoreRecords, setIsMoreRecords] = useState(true);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [deleteModal, setDeleteModal] = useState(false)
  const [workflowId, setWorkflowId] = useState(null);
  const workFlowType = getQueryString("type");
  const workflowName = useRef();
  const setToDefault = useSetToDefault();

  const [searchParams,] = useSearchParams( );


  const handleScroll = () => {
    if (loading || !isMoreRecords) {
      return;
    }
    if (isMoreRecords === true) {
      setLoading(true);
      getWorkflows(10, skip, search, sortOrder, sortBy, "", workFlowType)
        .then((res) => {
          if (res && res.length > 0) {
            setWorkflows((prevWorkflows) => prevWorkflows.concat(res));
            setIsMoreRecords(res.length === 10);
            setSkip(skip + 10);
          } else {
            setLoading(false);
            setIsMoreRecords(false);
          }
        })
        .catch((_err) => {
          console.log("Error :", _err)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDeleteWorkflow = () => {
    if (workflowId) {
      const workflowIndex = workflows.findIndex((workflow) => workflow.id === workflowId);

      if (workflowIndex !== -1) {
        const updatedWorkflows = [...workflows];
        updatedWorkflows.splice(workflowIndex, 1);
        setWorkflows(updatedWorkflows);
      }

      deleteWorkflow(workflowId)
        .then(() => {
          setDeleteModal(false);
        })
        .catch((error) => {
          console.error("Error deleting workflow:", error);
        });
    }
  };

  const fetchWorkflows = (sk = skip) => {
    getWorkflows(10, sk, "", sortOrder, sortBy, "", workFlowType)
      .then((res) => {
        if (res && res.length > 0) {
          setWorkflows(res);
          setLoading(false);
          setSkip(10);
          setIsMoreRecords(true);
        } else {
          setLoading(false);
        }
      })
      .catch((_err) => {
        console.log("Unable to fetch workflows data");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchWorkflows();
  }, []);

  /* ==================================Select Work-flow Modal===Start=================================== */

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="select_workflows_modal"
      >
        <Modal.Body>
          <div className="-fluid wf-main-pad">
            <div className="row">
              <div className="col-lg-12 content_wrapper">
                <h3 className="wf-main-head">Select Your Workflow</h3>
                <p className="wf-desc">
                  Kindly choose the workflow category that best suits your needs in order to start building your workflow.
                </p>
              </div>
              <div className="col-lg-12 p-0"  onClick={() => setToDefault()}>
                <div className="select_workflows_wrapper row">
                  {
                    (searchParams.get('type') == "api" || searchParams.get('type') == null) && (
                      <div className="select-workflow col-4">
                        <Link to={"new?type=api"}>
                          <img
                            src={Icons?.selectApi}
                            alt="image"
                            className="workflow-img"
                          />
                          <p className="card-heading">API Workflow</p>
                        </Link>
                      </div>
                    )
                  }
                  {
                    (searchParams.get('type') == "whatsapp" || searchParams.get('type') == null) && (
                      <div className="select-workflow col-4">
                        <Link to={"new?type=whatsapp"}>
                          <img
                            src={Icons?.selectWhatapp}
                            alt="image"
                            className="workflow-img"
                          />
                          <p className="card-heading">WhatsApp Workflow</p>
                        </Link>
                      </div>
                    )
                  }
                  {
                    (searchParams.get('type') == "web" || searchParams.get('type') == null) && (
                      <div className="select-workflow col-4">
                        <Link to={"new?type=web"}>
                          <img
                            src={Icons?.selectWebBased}
                            alt="image"
                            className="workflow-img"
                          />
                          <p className="card-heading">Web-based Workflow</p>
                        </Link>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="workSpace">
        <section className="row">
          <div className="col-lg-12 WorkflowAlign">
            <main>
              <div className="workSpaceTitle">
                <p className="title_txt">Workspace</p>
                <p className="sub_txt">Browse all the existing workflows here</p>
              </div>
              <main className="row">
                <InfiniteScroll
                  dataLength={workflows.length}
                  next={handleScroll}
                  hasMore={isMoreRecords || loading}
                  scrollThreshold={"200px"}
                  className="row workspace_wrapper"
                  loader={
                    <>
                      {
                        loading === true ? ([0, 1, 2, 3, 4, 5, 6, 7, 8].map((e, id) => (<Loader key={id} />))) : ""
                      }
                    </>
                  }
                >
                  <>
                    {
                      workflows && workflows?.length > 0 ?
                        (
                          <>
                            <div className="col-lg-4 col-xl-3 col-md-6 workspace_card_wrapper">
                              <div className="WorkSpaceDefault">
                                <div className="text_wrapper">
                                  <div className="text-center">
                                    <img
                                      src={Icons?.WorkSpaceEmty}
                                      alt="workspace"
                                      className="WorkspaceImg"
                                    />
                                  </div>
                                  <h5 className="text-center title_txt">
                                    {loading ? <Skeleton /> : "New Workflow"}
                                  </h5>
                                  <div className="text-center sub_txt">
                                    <p>
                                      {loading ? (
                                        <Skeleton count={2} />
                                      ) : (
                                        "Create new workflow according to your business needs"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <div className="text-center btn_wrapper">
                                    <CustomButton
                                      title="Create New Workflow"
                                      onClick={() => setModalShow(true)}
                                      isLeft={true}
                                      icon={PlusIcon}
                                    />
                                </div>
                              </div>
                            </div>
                            {workflows?.map((workflow, i) => (
                              <>
                                <div
                                  className="col-lg-4 col-xl-3 col-md-6 workspace_card_wrapper"
                                  key={i + 1}
                                  onClick={() => navigate(workflow?.id)}
                                >
                                  <div className="WorkSpaceDynamic">
                                    <div className="text-center">
                                      <div className="d-flex justify-content-center align-items-center ImgWorkflowsection">
                                        <div className="img_connectLogo">
                                          {
                                            workflow?.type === "web" ? 
                                            <>
                                              <img
                                              src={Icons?.webIcon}
                                              alt="webicon"
                                              />
                                              <div className="workflows-icon-div">
                                                <p className="mb-0 workflows-icon-web">web-based workflow</p>
                                              </div>
                                            </>:
                                            workflow?.type === "api" ?
                                            <>
                                            <img
                                              src={Icons?.apiIcon}
                                              alt="apiicon"
                                              />
                                               <div className="workflows-icon-div">
                                                <p className="mb-0 workflows-icon-api">api workflow</p>
                                              </div>
                                            </>:
                                            workflow?.type === "whatsapp" ?
                                            <>
                                            <img
                                              src={Icons?.whatsappIcon}
                                              alt="whatsappicon"
                                              />
                                               <div className="workflows-icon-div">
                                                <p className="mb-0 workflows-icon-whatsapp">whatsapp workflow</p>
                                              </div>
                                            </>:<>
                                            <img
                                              src={Icons?.nodeConnectLogoWorkflow}
                                              alt="headicon"
                                              />
                                            </>
                                          }
                                          
                                        </div>
                                      </div>
                                    </div>
                                    <section className="DynamicPartTwo">
                                      <h5>{workflow?.name}</h5>
                                      <div>
                                        <p>
                                          Lorem ipsum dolor sit amet consectetur.
                                          kfsf sifsfisf sifsf sfsfsf
                                        </p>
                                      </div>
                                      <section className="Dynamicflowbottom">
                                        <div>
                                          <button>{workflow?.status}</button>
                                        </div>
                                        <div className="Dynamicflowbottombuttons">
                                          <div>
                                            <CustomButton
                                              title="Play"
                                              isLeft={false}
                                              type="Outlined"
                                              icon={Icons?.playLogoArrow}
                                              onClick={e => {
                                                e.stopPropagation();
                                                navigate(`${workflow?.id}/preview`);
                                              }}
                                            />
                                          </div>
                                          <div className="options_div">
                                            <img src={Icons?.ThreeDots}alt="ThreeDots"/>
                                            <div className="dropdown_menu">
                                              <ul>
                                                <li onClick={
                                                  (e) => {
                                                    e.stopPropagation();
                                                    setWorkflowId(workflow?.id),
                                                    workflowName.current = workflow?.name;
                                                    setDeleteModal(true)
                                                  }
                                                }>
                                                  <img src={Icons?.Trash14}alt="delete"/> <span>Delete</span>
                                                </li>
                                                <li>
                                                  <img src={Icons?.ExportIcon}alt="delete"/> <span>Export</span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </section>
                                  </div>
                                </div>
                              </>
                              
                            ))
                            }
                          </>
                        )
                        :
                        (
                          <>
                            {loading === false && <div className="workSpaceEmpty">
                              <div className="icon_div">
                                <img src={Icons?.WorkSpaceEmty} alt="workspace" />
                              </div>
                              <h3 className="title_txt">
                                You don't have any work flows
                              </h3>
                              <p className="sub_txt">
                                With intuitive drag-and-drop workflow builder, Seamlessly integrate APIs, reduce development time, and launch faster.
                              </p>
                              <button
                                type="button"
                                onClick={() => setModalShow(true)}
                              >
                                Create new work flow
                              </button>
                            </div>
                            }
                          </>
                        )}
                  </>
                </InfiniteScroll>
              </main>
            </main>
          </div>
        </section>
      </div>
      {
        deleteModal &&
        <>
          <div>
            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={deleteModal}
              onHide={() => setDeleteModal(false)}

            >

              <Modal.Body className="confirm-modal-body">
                <h3 className="">Are you sure?</h3>
                <p>
                  Deleting this workflow will remove all the contents of {workflowName.current && workflowName.current}. Workflow once deleted won't be reverted.
                </p>
                <div className="confirm-btns">
                  <button className="conf-cancel" onClick={handleDeleteWorkflow}>Yes, Delete</button>
                  <button className="conf-conf" onClick={() => setDeleteModal(false)}>No, don't delete</button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      }
    </>
  );
};

const Loader = () => (
  <div className="col-lg-4 col-xl-3 col-md-6">
    <div className="workflow-builder-card-sekelton">
      <div className="top-skeleton-container">
        <Skeleton count={1} width={60} height={50} />
        <Skeleton count={1} width={50} height={3} />
        <Skeleton count={1} width={60} height={50} />
      </div>

      <div className="skeleton-card-title">
        <Skeleton count={1} width={70} height={10} />
      </div>
      <div className="skeleton-card-body">
        <Skeleton count={1} width={"100%"} height={10} />
        <Skeleton count={1} width={"100%"} height={10} />
        <Skeleton count={1} width={"100%"} height={10} />
      </div>
      <div className="bottom-skelton-container">
        <Skeleton width={70} height={30} />
        <Skeleton width={70} height={30} />
      </div>
    </div>
  </div>
);

export default WorkFlow;
