import React from "react";

export default function MenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00033 2.47586C7.89513 2.47586 7.80985 2.56114 7.80985 2.66634C7.80985 2.77154 7.89513 2.85682 8.00033 2.85682C8.10552 2.85682 8.1908 2.77154 8.1908 2.66634C8.1908 2.56114 8.10552 2.47586 8.00033 2.47586ZM6.66699 2.66634C6.66699 1.92996 7.26395 1.33301 8.00033 1.33301C8.7367 1.33301 9.33366 1.92996 9.33366 2.66634C9.33366 3.40272 8.7367 3.99967 8.00033 3.99967C7.26395 3.99967 6.66699 3.40272 6.66699 2.66634ZM8.00033 7.8092C7.89513 7.8092 7.80985 7.89448 7.80985 7.99967C7.80985 8.10487 7.89513 8.19015 8.00033 8.19015C8.10552 8.19015 8.1908 8.10487 8.1908 7.99967C8.1908 7.89448 8.10552 7.8092 8.00033 7.8092ZM6.66699 7.99967C6.66699 7.26329 7.26395 6.66634 8.00033 6.66634C8.7367 6.66634 9.33366 7.26329 9.33366 7.99967C9.33366 8.73605 8.7367 9.33301 8.00033 9.33301C7.26395 9.33301 6.66699 8.73605 6.66699 7.99967ZM8.00033 13.1425C7.89513 13.1425 7.80985 13.2278 7.80985 13.333C7.80985 13.4382 7.89513 13.5235 8.00033 13.5235C8.10552 13.5235 8.1908 13.4382 8.1908 13.333C8.1908 13.2278 8.10552 13.1425 8.00033 13.1425ZM6.66699 13.333C6.66699 12.5966 7.26395 11.9997 8.00033 11.9997C8.7367 11.9997 9.33366 12.5966 9.33366 13.333C9.33366 14.0694 8.7367 14.6663 8.00033 14.6663C7.26395 14.6663 6.66699 14.0694 6.66699 13.333Z"
        fill="#151515"
      />
    </svg>
  );
}
