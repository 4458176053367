import React, { useMemo } from "react";

import { Alignment, FullContainer, Style, Node, emptyProps, ButtonLoader } from "../common";
import { registerComponent } from "../components";
import { useEvents, useForm, useIsButtonLoading, useIsEditor, useIsForm } from "../../../src/stores/context";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import { useFonts, useTheme } from "../../../src/stores/selectors";
const type = "Button";

export const inputs = [
  {
    name: "Text",
    value: "text",
    type: "textInput",
    required: true,
    autoFocus: true,
    placeHolder: "Button Name",
    defaultValue: "Click Me",
  },
  {
    name: "Submit Button",
    value: "submit",
    type: "toggle",
    requried: true,
    defaultValue: true,
  },
  {
    name: "Icon URL",
    value: "icon",
    type: "textInput",
    required: true,
    autoFocus: false,
  },
  {
    name: "Loader",
    value: "loader",
    type: "toggle",
    autoFocus: false,
  },
  Style,
  {
    name: "Background Color",
    value: "bgcolor",
    type: "colorInput",
    required: true,
    autoFocus: false,
  },

  {
    name: "Color",
    value: "color",
    type: "colorInput",
    required: true,
    autoFocus: false,
  },
  {
    name: "Width",
    value: "width",
    type: "progressInput",
    required: true,
    autoFocus: false,
  },
  {
    name: "Height",
    value: "height",
    type: "progressInput",
    required: true,
    autoFocus: false,
  },
  {
    name: "Border Color",
    value: "bordercolor",
    type: "colorInput",
    autoFocus: false,
    required: true,
},
  {
    name: "Border Radius",
    value: "borderRad",
    type: "progressInput",
    required: true,
    defaultValue: 0,
  },
  {
    name: "Horizonal Padding",
    value: "hPadding",
    type: "progressInput",
    defaultValue: 0,
    required: true,
  },
  {
    name: "Vertical Padding",
    value: "vPadding",
    type: "progressInput",
    defaultValue: 0,
    required: true,
  },
  {
    name: "Margin top",
    value: "topmargin",
    type: "progressInput",
    defaultValue: 0,
    required: true,
  },

  {
    name: "Margin bottom",
    value: "bottommargin",
    type: "progressInput",
    defaultValue: 0,
    required: true,
  },
  
  {
    name: "Box Shadow",
    value: "boxShadow",
    type: "progressInput",
    defaultValue: 0,
    required: true,
  },
  Alignment,
  FullContainer,
];

export const Button = {
  name: "Button",
  type,
  props: emptyProps,
};

export const ButtonNode = () => {
  return (
    <Node type={type} >
      <div className="page-side-node br-right">
        <img src={PageBuilderAssets?.buttonIcon} className="node-img" />
        <p className="node-name mb-2">Button</p>
      </div>
    </Node>
  );
};



export const ButtonPlay = ({
  id,
  text = "Click Me",
  style = "subtitle2",
  hPadding = "16",
  vPadding = "10",
  alignment = "center",
  bgcolor = "",
  color = "",
  width = "100",
  height=45,
  topmargin,
  bottommargin,
  // boxShadow = "5px 5px 21px #6c6c6c, 5px 5px 21px #ffffff",
  icon,
  loader = false,
  borderRad = "4px",
  bordercolor="",
  submit
}) => {
  const editor = useIsEditor();
  const { buttonClick } = useEvents();
  const { isForm, formSubmitHandle} = useForm()
  const isLoading = useIsButtonLoading(id);
  const fonts = useFonts();
  const font = useMemo(() => fonts[style], [fonts, style]);
  const theme = useTheme();
  const defaultBgColor = theme === "dark" ? "#f5f5f5" : "#12201e";
  const borderColor = bordercolor || defaultColor;
  const defaultColor =theme === "dark" ? "#12201e" : "#f5f5f5";
  const finalColor = color || defaultColor;
  const finalBgcolor = bgcolor || defaultBgColor;

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (editor) { // Don't process click in editor mode
      return
    } // TODO handle Button Submit
    if (isForm && submit){
      return formSubmitHandle(id)
    }else{
      buttonClick(id, loader)
    }
  }

  return (
    <>
      <div style={{ textAlign: alignment }}>
        <button onClick={handleClick}
                type={submit? "submit": undefined}
          style={{
            fontWeight: 600,
            margin: "10px auto",
            color: finalColor,
            background: finalBgcolor,
            borderRadius: borderRad,
            border:`${theme === "light" ? `1px solid ${borderColor}` :`1px solid ${borderColor}` }`,
            marginTop:`${topmargin * 3}px`,
            marginBottom:`${bottommargin}px`,
            // boxShadow: `5px 5px ${Math.floor(
            //   boxShadow * 2
            // )}px #6c6c6c, 5px 5px ${Math.floor(boxShadow * 2)}px #fff`,
            width: `${width}%`,
            height:`${height}px`,
            padding: `${vPadding}px ${hPadding}px`,
            fontSize: font.size,
            position: "relative"
          }}
        >
          {icon && (
            <img src={icon} alt="Invalid Icon" className=" PBclrwhite PBsvg" />
          )}
          {isLoading ? <>
            <ButtonLoader color={finalColor}/>
          </> : <>{text}</>}
        </button>
      </div>
    </>
  );
};

registerComponent(type, {
  node: ButtonNode,
  play: ButtonPlay,
  item: Button,
  inputs,
  category: "Buttons",
});
