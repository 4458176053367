import { NodeHeader } from "../../Common";
import { Handle, Position } from "reactflow";
import React, { useState, useEffect } from "react";
import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader";
import { useFlowType, useNodeData } from "../../../../store/selector";
import { customCodeIcon } from "../../../../../assets/imageUrl";
import { crossClose } from "../../../../../assets/imageUrl";
import Icons from "../../../../../assets/imageUrl";
import CodeEditor from "../../../../components/codeEditor";
import Modal from 'react-bootstrap/Modal';
import { Select } from "../../../../components/select";
import { TextInput } from "../../../../components/inputs";

// array for languages
const codelanguages = [
    { name: "Javascript", value: "javascript" },
    { name: "Typescript", value: "typescript" },
    { name: "Python", value: "python" }
];


export const CodePanel = ({ id, type = "Code", vars = [] }) => {

    const [data, setData] = useNodeData(id);
    const [show, setShow] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [addVariable, setAddVariable] = useState("");
    const [showError, setShowError] = useState(false);
    const [flowType, _] = useFlowType();

    // Adding Variables
    const handleAddVariable = () => {
        if (addVariable.trim() !== "") {
            const existingVariables = data?.variables || [];
            if (existingVariables.includes(addVariable)) {
                setShowError(true);
            } else {
                const updatedVariables = [...existingVariables, addVariable];
                setAddVariable("");
                setShowError(false);
                setData({ ...data, variables: updatedVariables })
            }
        }
    };

    // Deleting Variables
    const handleDeleteTask = (index) => {
        const existingVariables = data?.variables || [];
        const updatedVariables = [...existingVariables];
        updatedVariables.splice(index, 1);
        setData({ ...data, variables: updatedVariables })
    };

    useEffect(() => setShowError(false), [data?.variables])

    const filteredLanguages = data?.environment === "FrontEnd"
        ? codelanguages.filter(lang => lang.value === "javascript")
        : codelanguages;


    return (
        <main>
            <SidePanelHeader nodeName="Custom Code" />
            {data?.edit === "disabled" ?  <ExecuteCode data={data} setData={setData}/> : <>
            <div className="px-3">
                <TextInput
                    name="Name"
                    type="text"
                    value={data?.name || ""}
                    maxLength={15}
                    setValue={(value) => setData({ ...data, name: value })}
                    placeholder={type + " Name"}
                    helpingText="custom code"
                    disabled={data?.edit === "disabled"}
                />
            </div>
            <section className="sideInnerParent px-3 ptb-10">
                <p className="idflow_np_text_bold">Code for</p>
                <div className="idflow_np_code_for">
                    {
                        flowType === "web" || data?.edit !== "enabled" ? <>
                            <div className="idflow_np_radios mb-2">
                                <div className="idflow_np_radio">
                                    <label className="idflow_np_radio_label">
                                        <input
                                            disabled={data?.edit === "disabled"}
                                            type="radio"
                                            name="frontend1"
                                            onChange={(e) => {
                                                setData({ ...data, environment: "FrontEnd" });
                                            }}
                                            checked={data?.environment === "FrontEnd"}
                                        />
                                        <div className="idflow_np_radio_checkmark"></div>
                                    </label>
                                    <label>Frontend</label>
                                </div>
                                <div className="idflow_np_radio">
                                    <label className="idflow_np_radio_label">
                                        <input type="radio"
                                            disabled={data?.edit === "disabled"}
                                            name="backend1"
                                            onChange={(e) => {
                                                setData({ ...data, environment: "BackEnd" });
                                            }}
                                            checked={data?.environment === "BackEnd"}
                                        />
                                        <div className="idflow_np_radio_checkmark"></div>
                                    </label>
                                    <label>Backend</label>
                                </div>
                            </div>
                        </> : <></>
                    }

                    <Select
                        disabled={data?.edit === "disabled"}
                        name="Language"
                        helpingText="Choose a programming language for your code."
                        value={data?.language}
                        setValue={(value) =>
                            setData({
                                ...data,
                                language: value
                            })
                        }
                        options={filteredLanguages}
                        className="mt-3"
                    />
                </div>
            </section>
            <section className="sideInnerParent px-3 ptb-20">
                <div>
                    <p className="idflow_np_text_bold">Code Editor</p>
                    <section className="idflow_np_editor_area">
                        <CodeEditor
                            disabled={data?.edit === "disabled"}
                            name="Small Editor"
                            mode={data?.language.toLowerCase()}
                            handleEditorChange={(newCode) => setData({ ...data, code: newCode })}
                            value={data?.code}
                        />
                        <div className="idflow_np_overlay_area">
                            <h6>Open custom code editor to ad your code.</h6>
                            <button onClick={() => setShowEditor(true)} disabled={data?.edit === "disabled"}><code>&lt;/&gt;</code>&nbsp;Open Editor</button>
                        </div>
                        <Modal show={showEditor} onHide={() => setShowEditor(false)} size="xl" centered>
                            <div className="modal-main-editor">
                                <section className="idflow_editor_head">
                                    <div className="idflow_popup-head">
                                        <h6>Code Editor</h6>
                                        <img src={Icons?.crossCloseWhite} alt="close" onClick={() => setShowEditor(false)} className="idflow_np_modal_close" />
                                    </div>
                                    {
                                        flowType === "web" ?
                                            <>
                                                <section className="idflow_np_editor_popup_head">
                                                    <div className="idflow_np_radios mb-2">
                                                        <div className="idflow_np_radio">
                                                            <label className="idflow_np_radio_label">
                                                                <input
                                                                    disabled={data?.edit === "disabled"}
                                                                    type="radio"
                                                                    name="frontend2"
                                                                    onChange={(e) => {
                                                                        setData({ ...data, environment: "FrontEnd" });
                                                                    }}
                                                                    checked={data?.environment === "FrontEnd"}
                                                                />
                                                                <div className="idflow_np_radio_checkmark"></div>
                                                            </label>
                                                            <label>Frontend</label>
                                                        </div>
                                                        <div className="idflow_np_radio">
                                                            <label className="idflow_np_radio_label">
                                                                <input type="radio"
                                                                    disabled={data?.edit === "disabled"}
                                                                    name="backend2"
                                                                    onChange={(e) => {
                                                                        setData({ ...data, environment: "BackEnd" });
                                                                    }}
                                                                    checked={data?.environment === "BackEnd"}
                                                                />
                                                                <div className="idflow_np_radio_checkmark"></div>
                                                            </label>
                                                            <label>Backend</label>
                                                        </div>
                                                    </div>
                                                    <div className="idflow_np_editor_select">
                                                        <select
                                                            disabled={data?.edit === "disabled"}
                                                            onChange={(e) => {
                                                                setData({
                                                                    ...data,
                                                                    language: e.target.value
                                                                })
                                                            }}
                                                        >
                                                            <option disabled>Select language</option>
                                                            {data?.environment === "FrontEnd" ? (
                                                                <option value="javascript" selected>
                                                                    JavaScript
                                                                </option>
                                                            ) : (
                                                                codelanguages?.map((lang, i) => (
                                                                    <option value={lang.value} key={i} selected={lang.value === data?.language}>
                                                                        {lang.name}
                                                                    </option>
                                                                ))
                                                            )}
                                                        </select>
                                                    </div>
                                                </section>
                                            </>
                                            : <>
                                                <div className="idflow_np_editor_select">
                                                    <select
                                                        disabled={data?.edit === "disabled"}
                                                        onChange={(e) => {
                                                            setData({
                                                                ...data,
                                                                language: e.target.value
                                                            })
                                                        }}
                                                    >
                                                        <option disabled>Select language</option>
                                                        {
                                                            codelanguages?.map((lang, i) => (
                                                                <option value={lang.value} key={i} selected={lang.value === data?.language}>
                                                                    {lang.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </>
                                    }

                                </section>
                                <section className="mt-3">
                                    <CodeEditor
                                        disabled={data?.edit === "disabled"}
                                        name="Big Editor"
                                        mode={data?.language.toLowerCase()}
                                        handleEditorChange={(newCode) => setData({ ...data, code: newCode })}
                                        value={data?.code}
                                    />
                                </section>
                            </div>
                        </Modal>
                    </section>
                </div>
            </section>
            <section className="sideInnerParent px-3 ptb-20 ">
                <div className="idflow_np_code_for">
                    <div className="idflow_np_add_variables">
                        <p><span className="idflow_np_astreik">&#10033;</span> Add variables into the code for system recognition and identification.</p>
                        <button onClick={() => setShow(true)} disabled={data?.edit === "disabled"}>Add variables</button>
                    </div>
                    <section>
                        <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
                            <div className="modal-main">
                                <div className="idflow_popup-head">
                                    <h5 className="mb-0">Add Variables</h5>
                                    <img src={crossClose} alt="close" onClick={() => setShow(false)} className="idflow_np_modal_close idflow_np_modal_close_white" />
                                </div>
                                <p className="idflow_np_popup_supporting_text pt-2">Add variables here for system recognition and reuse across other nodes.</p>
                                <section>
                                    <div class="RightLabel">
                                        <div class="nodePanel-labels">Variables</div>
                                        <div>
                                            <div className="RightLabelImg">
                                                <img src={Icons.InfoIcon} alt="IdflowInfoIcon" />
                                                <div className="RightTooltip ">
                                                    <div className="RightSubtool">
                                                        <p>Add your variables</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="labelInputFull idflow_np_add_variable_inp_area">
                                        <input type="text"
                                            onChange={(e) => {
                                                setAddVariable(e.target.value);
                                                setShowError(false)
                                            }}
                                            value={addVariable}
                                            autoFocus
                                        />
                                        <img src={Icons?.forwardIconWhite} className="idflow_np_forward_img" onClick={handleAddVariable} />
                                    </div>
                                    {showError && <p className="idflow_np_var_err">Variable taken. Please try another.</p>}
                                    {data && data?.variables?.length > 0 ?
                                        <>
                                            <div className="idflow_np_code_for mtb-20">
                                                <ul className="idflow_np_variables_ul">
                                                    {data?.variables.map((variable, index) => (
                                                        <li key={index} className="idflow_np_var_item">
                                                            <p>{variable}</p>
                                                            <img src={crossClose} alt="deleteIcon" onClick={() => handleDeleteTask(index)} className="idflow_np_modal_close" />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </> : <>
                                            <div className="idflow_np_code_for mtb-20">
                                                <ul className="idflow_np_variables_ul">
                                                    <p className="mb-0">Please add variables.</p>
                                                </ul>
                                            </div>
                                        </>
                                    }
                                </section>
                            </div>
                        </Modal>
                    </section>
                </div>
            </section>
            </>}
        </main>
    )

}

const CodeNode = ({ id, data, selected }) => {

    return (
        <section className="">
            <div style={{ backgroundColor: "#ECFDFF", border: selected ? `solid 2px${"#00AAFF"}` : "" }} className={`${"set_msgcard"} ${"commonHeader"}`}>
                <NodeHeader
                    name="Custom Code"
                    icon={customCodeIcon}
                    isStartNode={data?.isStartNode}
                ></NodeHeader>
                <div className="conditionNodeStyle">
                    <div >
                        <p>
                            Success
                        </p>
                        <Handle
                            id="success"
                            type="source"
                            position={Position.Right}
                            className="commonHandleStyle"
                        />
                    </div>
                    <div >
                        <p>
                            Failed
                        </p>
                        <Handle
                            id="failure"
                            type="source"
                            position={Position.Right}
                            className="commonHandleStyle"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

const ExecuteCode = ({data, setData}) =>{
    const outputs = data?.outputs || [];
    const inputs = data?.inputs || [];
    const updateIO = (value, index, type) =>{
        const io = data?.[type] || []
        io[index].value = value;
        setData({...data, [type]: io })
    }
    
    return (
    <section className="execute-code-container px-3 ptb-20">
        {inputs.length > 0 ?
            <div>
                <label htmlFor="" className="execute-code-labels">Inputs:</label>
              {inputs.map((item, index) => (
                <InputComponent item={item} updateIO={updateIO} index={index} type={"inputs"}/>
              ))}
            </div>
          : ""
        }
        {outputs.length > 0 ? (
          <div>
            <label htmlFor="" className="execute-code-labels">Outputs:</label>
            {outputs.map((item, index) => {
                return (
                    <InputComponent item={item} updateIO={updateIO} index={index} type={"outputs"}/>
                )
              })
            }
          </div>
        ) : ""}
    </section>
    )
}

const InputComponent = ({item, updateIO, index, type})=>{
    return (
        <div key={item?.id}>
            <label className="nodePanel-labels">{item?.name}</label>
            <div className="labelInputFull">
                <input
                type="text"
                value={item?.value}
                onChange={(e) =>
                    updateIO(e.target.value, index, type)}
                />
            </div>
        </div>
    )
}

export default CodeNode;