import { METHODS, commonRequest, commonRequestImageFile } from "./common";

const Resource = (resource_name) => {

    const path = (id) => `/${resource_name}/` + id

    return {
        getAll: async (skip = 0, limit = 100, filter = undefined) => {
            return await commonRequest(METHODS.get, path(`?skip=${skip}&limit=${limit}`) + (filter ? `&filter=${filter}` : ""), {})
        },
        get: async (id) => {
            return await commonRequest(METHODS.get, path(id), {})
        },
        create: async (resource) => {
            return await commonRequest(METHODS.post, path(''), resource)
        },
        update: async (resource) => {
            return await commonRequest(METHODS.put, path(resource.id || resource._id), resource)
        },
        delete: async (id) => {
            return await commonRequest(METHODS.delete, path(id), {})
        },
        getImage: async (resource) => {
            return await commonRequestImageFile(METHODS.post, path(""), resource)
        }
    }
}

export default Resource;