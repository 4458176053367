import React, {useEffect, useMemo} from "react";
import {useForm, useIsEditor} from "../../../src/stores/context";
import {emptyProps, FullContainer, Node, Style} from "../common";
import {registerComponent} from "../components";
import {useFonts, useTheme} from "../../../src/stores/selectors";
import {nameInput} from "./input";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import * as Yup from "yup";

export const type = "Radio";

export const inputs = [
    nameInput,
    {
        name: "Unique Value",
        value: "value",
        type: "textInput",
        required: true,
        autoFocus: true,
    },
    {
        name: "Text",
        value: "text",
        type: "textInput",
        required: true,
        autoFocus: true,
    },

    {
        name: "Color",
        value: "color",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
    {
        name: "Radio Button Size",
        value: "width",
        type: "progressInput",
        required: true,
        autoFocus: false,
    },
    Style,
    {
        name: "Required",
        value: "required",
        type: "toggle",
        defaultValue: true,
    },

    {
        name: "Default Checked",
        value: "checked",
        type: "toggle",
        defaultValue: true,
    },

    FullContainer,
];

export const Checkbox = {
    name: "radio",
    type,
    props: emptyProps,
};

export const RadioNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.radioIcon} className="node-img"/>
                <p className="node-name mb-2">Radio Button</p>
            </div>
        </Node>
    );
};

export const RadioPlay = ({
                              id,
                              name, value,
                              inputType = "radio",
                              required = false,
                              text = 'Your Consent Message',
                              checked = false,
                              style = "subtitle3",
                              color = "",
                              width = "20",
                              height = "20"
                          }) => {
    const editor = useIsEditor();
    const fonts = useFonts();
    const {isForm, formik, registerValidator} = useForm();
    const font = useMemo(() => fonts[style], [fonts, style]);
    const theme = useTheme();
    const defaultColor = theme === "dark" ? "#f5f5f5" : "#212427";
    const finalTextColor = color || defaultColor;

    useEffect(() => {
        if (!editor && isForm) {
            registerValidator(name, Yup.string())
        }
    },[])

    const handleOnChange = isForm ? formik?.handleChange : () => undefined
    const handleBlur = isForm ? formik?.handleBlur : () => undefined

    return (
        <>
            <div className="PBmainheadform">
                <div className="d-flex align-items-center">
                    <input
                        type={inputType}
                        name={name}
                        id={id}
                        required={required}
                        disabled={editor}
                        value={value}
                        className="PBcheckbox"
                        onChange={handleOnChange}
                        onBlur={handleBlur}
                        checked={isForm && !editor ? value === formik?.values[name] : false}
                        style={{width: `${width}px`, height: `${width}px`}}

                    />
                    <p className="mb-0 PBcheckText" style={{
                        fontSize: font.size,
                        color: finalTextColor
                    }}>{text}</p>
                    {required && <span className="PB-required-star"> *</span>}
                </div>
            </div>
        </>
    );
}

registerComponent(type, {
    item: Checkbox,
    play: RadioPlay,
    node: RadioNode,
    inputs,
    isInput: true,
    category: "Inputs",
});

