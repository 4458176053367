import * as Slider from '@radix-ui/react-slider';
import { useEffect, useRef, useState } from "react";
import { useBrandSettings } from "../../../WorkFlowBuilder/store/selector";
import Upload_icon from "../../../assets/Upload_icon.svg";
import dark_logo from "../../../assets/dark_logo.svg";
import dark_theme from "../../../assets/dark_theme.svg";
import light_logo from "../../../assets/light_logo.svg";
import light_theme from "../../../assets/light_theme.svg";
import MadeInIdFlowDark from "../../../assets/made-in-idflow-dark.svg";
import MadeInIdFlowLight from "../../../assets/made-in-idflow-light.svg";
import CheckCircle from '../../../assets/solar_check-circle-bold.svg';
import CustomButton from '../../core/Button';
import Loader from './SkeletonLoader/Loader';
import ColorCode from './colorCode';

import { BASE_URL } from '../../../api/common';
import Resource from '../../../api/resource';
import "./index.css";
import TextEditor from './textEditor';

const colorsTextArray = [
  { title: "Primary color", value: "primary" },
  { title: "Secondary color", value: "secondary" },
]

const colorsButtonArray = [
  ...colorsTextArray,
  { title: "Text primary color", value: "textPrimary" },
  { title: "Text secondary color", value: "textSecondary" },
];

const colorInputArray = [
  { title: "Background color", value: "background" },
  { title: "Label color", value: "label" },
  { title: "Value color", value: "value" },
  { title: "Placeholder color", value: "placeHolder" },
  { title: "Border Line color", value: "border" },
  { title: "Focus Shadow color", value: "focusBorder" },
  { title: "Focus color", value: "focus" },

]

const positionOptions = [
  { value: "Top Right Corner", label: "Top Right Corner" },
  { value: "Top Left Corner", label: "Top Left Corner" },
  { value: "Bottom Right Corner", label: "Bottom Right Corner" },
  { value: "Bottom Left Corner", label: "Bottom Left Corner" }
]


const ProgressInput = ({ title, value, setValue }) => {
  return (
    <div className='progressInput_wrapper'>
      <p className="slider_label">{title}</p> {/* // TODO: apply font family */}
      <div className="SliderMain">
        <Slider.Root className="SliderRoot" value={[value]} min={0} max={100} step={1} onValueChange={value => setValue(value[0])}>
          <Slider.Track className="SliderTrack  bg-dark">
            <Slider.Range className="SliderRange" />
          </Slider.Track>
          <Slider.Thumb className="SliderThumb ms-1 bg-dark" aria-label="Volume" />
        </Slider.Root>
        <span className='slider-cus'>{value} px</span>
      </div>
    </div>
  )
}


const BrandSetting = () => {

  const [brand, setBrand] = useBrandSettings();
  console.log(brand, "=======>")
  const [activeTheme, setActiveTheme] = [brand.theme, theme => setBrand({ ...brand, theme: theme })]
  const [lightLogo, setLightLogo] = [brand.logo?.white, white => setBrand({ ...brand, logo: { ...brand.logo, white } })];
  const [darkLogo, setDarkLogo] = [brand.logo?.black, black => setBrand({ ...brand, logo: { ...brand.logo, black } })];
  const [waterMarkTheme, setWaterMarkTheme] = [brand.waterMark?.theme, theme => setBrand({ ...brand, waterMark: { ...brand.wwaterMark, theme: theme } })];
  const [loading, setLoading] = useState(false);

  const [activeLoader, setActiveLoader] = [brand.animation?.loader, loader => setBrand({ ...brand, animation: { ...brand.animation, loader: loader } })];

  const [uploadFileName, setUploadFileName] = useState('')

  const [sliderBradius, setSliderBradius] = useState(0);
  const borderRadiusSlider = useRef(0);

  const handleChangeLight = async (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      let resp = await base64ToUrl(file)
      setLightLogo(resp)
    }
  };


  const base64ToUrl = async (image) => {
    let formData = new FormData()
    formData.append("file", image);
    try {
        const response = await Resource("files").getImage(formData);
        return `${BASE_URL}v1/files/${response.id}`
    } catch (e) {
      console.log(e, '-->')
    }
    return;
  };

  const handleChangeDark = async (event) => {
    if (event.target.files?.length > 0) {
      const file = event.target.files[0];
      let resp = await base64ToUrl(file)
      setDarkLogo(resp)
    }
  };

  const handleSlider = (value) => {
    setSliderBradius(value);
  }

  useEffect(() => {
    borderRadiusSlider.current = parseInt(sliderBradius.toString())
  }, [sliderBradius])


  const handleAnimation = async (event) => {
    let file = event.target.files[0]
    if (file) {
      let resp = await base64ToUrl(file)
      setBrand({ ...brand, animation: { ...brand.animation, image: resp } })
    } else {
      setUploadFileName("")
    }

  }
  return (
    <div className="brand_setting">
      {loading === true ? <Loader /> :
        <div className='brand_setting_wrapper'>
          <section className="g-setting-heading mx-0">
            <div className='text_section'>
              <h5 className="g-title">Brand settings</h5>
              <p className="g-desc">Apply your unique business branding to maintain a familiar and recognizable experience for your users.</p>
            </div>
          </section>

          {/* Theme Section */}
          <section className="theme_box">
            <div className='row theme_box_row'>
              <div className="col-lg-3 theme_context">
                <h5 className="heading">Theme</h5>
                <p className="desp">Choose a theme and personalize your product's visual style</p>
              </div>
              <div className='select_theme_wrapper col-lg-8'>
                <div className='theme_wrapper'>
                  <p className="theme_name">Light</p>
                  <div className="light_theme" onClick={() => setActiveTheme("light")}>
                    <div className='img_wrapper'>
                      <img src={light_theme} alt="light-thmee" />
                    </div>
                    <div className="bottom_light_theme">
                      <p className="active_lighttext m-0">Light theme {activeTheme === "light" && "(Active)"}</p>
                      {activeTheme === "light" && <img src={CheckCircle} alt="check circle" />}
                    </div>
                  </div>
                </div>
                <div className='theme_wrapper'>
                  <p className="theme_name">Dark</p>
                  <div className="dark_theme" onClick={() => setActiveTheme("dark")}>
                    <div className='img_wrapper'>
                      <img src={dark_theme} alt="dark-thmee" />
                    </div>
                    <div className="bottom_dark_theme">
                      <p className="active_darktext m-0">Dark theme {activeTheme === "dark" && "(Active)"}</p>
                      {activeTheme === "dark" && <img src={CheckCircle} alt="check circle" />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Logo Section */}
          <section className="logo_box">
            <div className='row logo_box_row'>
              <div className="col-lg-3 theme_context">
                <h5 className="heading">Logo</h5>
                <p className="desp">Upload a transparent .png or .svg image for your logo.</p>
              </div>
              <div className='col-lg-8 logo_category'>
                <div className='upload_logo_wrapper'>
                  <p className="category_name">Primary</p>
                  <p className="desp">For use on light backgrounds</p>
                  <div className='img_wrapper'>
                    {
                      lightLogo?.length > 0 ? <img src={lightLogo} alt="idflow light logo" /> : <img src={light_logo} alt="idflow light logo" />
                    }
                  </div>
                  <div className="upload_divs">
                    <label className="upload_btn">
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleChangeLight}
                      />
                      <img src={Upload_icon} />
                      <span className='col-auto'>Upload logo</span>
                    </label>
                  </div>
                </div>
                <div className='upload_logo_wrapper'>
                  <p className="category_name">Secondary</p>
                  <p className="desp">For use on dark backgrounds</p>
                  <div className='img_wrapper'>
                    {
                      darkLogo?.length > 0 ? <img src={darkLogo} alt="idflow dark logo" /> : <img src={dark_logo} alt="idflow dark logo" />
                    }
                  </div>
                  <div className="upload_divs">
                    <label className="upload_btn">
                      <input
                        type="file"
                        hidden
                        onChange={handleChangeDark}
                        accept="image/*"
                      />
                      <img src={Upload_icon} />
                      <span className='col-auto'>Upload logo</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Color pallete */}
          <section className="color_box">
            <div className='row color_row'>
              <div className="col-lg-3 color_context">
                <h5 className="heading">Color palette</h5>
                <p className="desp">
                  This color palette will be available on the overall site.
                </p>
              </div>
              <div className="col-lg-8 color_pallet_wrapper">
                <div className="row">
                  <div className='element_category'>
                    <div className='element_wrapper'>
                      <p className='element_name'>Buttons</p>
                      <ColorCode
                        colorsArray={colorsButtonArray}
                        colors={brand.colors?.button}
                        setColors={button => setBrand({ ...brand, colors: { ...brand.colors, button } })}
                      />
                      <div className='slider_wrapper'>
                        <ProgressInput
                          title="Border Radius"
                          value={brand?.colors?.button?.borderRadius}
                          setValue={borderRadius => setBrand({ ...brand, colors: { ...brand.colors, button: { ...brand.colors.button, borderRadius } } })}
                        />
                        <ProgressInput
                          title="Box Shadow"
                          value={brand?.colors?.button?.boxShadow}
                          setValue={boxShadow => setBrand({ ...brand, colors: { ...brand.colors, button: { ...brand.colors.button, boxShadow } } })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='element_category'>
                    <div className='element_wrapper'>
                      <p className="element_name">Inputs</p> {/* // TODO: apply font family */}
                      <ColorCode
                        colorsArray={colorInputArray}
                        colors={brand.colors?.input}
                        setColors={input => setBrand({ ...brand, colors: { ...brand.colors, input } })}
                      />
                      <div className='slider_wrapper'>
                        <ProgressInput
                          title="Border Radius"
                          value={brand?.colors?.input?.borderRadius}
                          setValue={borderRadius => setBrand({ ...brand, colors: { ...brand.colors, input: { ...brand.colors?.input, borderRadius } } })}
                        />
                        <ProgressInput
                          title="Box Shadow"
                          value={brand?.colors?.input?.boxShadow}
                          setValue={boxShadow => setBrand({ ...brand, colors: { ...brand.colors, input: { ...brand.colors?.input, boxShadow } } })}
                        />
                        <ProgressInput
                          title="Focus Shadow"
                          value={brand?.colors?.input?.focusShadow}
                          setValue={focusShadow => setBrand({ ...brand, colors: { ...brand.colors, input: { ...brand.colors?.input, focusShadow } } })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='element_category'>
                    <div className='element_wrapper'>
                      <p className="element_name">Text</p> {/* // TODO: apply font family */}
                      <ColorCode colorsArray={colorsTextArray} colors={brand.colors?.text} setColors={text => setBrand({ ...brand, colors: { ...brand.colors, text } })} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Typography */}
          <TextEditor />

          {/* Watermark */}
          <section className="waterflowMark-sec">
            <div className="context_wrapper">
              <h5 className="lang-title">ID flow Watermark</h5>
              <p className="desp">Tailor your watermark variations and placement according to your preferences.</p>
            </div>
            <div className="card_wrapper">
              <div className="water_mark_card" onClick={() => setWaterMarkTheme("blue")}>
                <div className="img_wrapper">
                  <img src={MadeInIdFlowDark} alt="MadeInIdFlowDark" />
                </div>
                <div className="theme_selection">
                  <div className="theme_name">Blue Theme {waterMarkTheme === "blue" && "(Active)"}</div>
                  {waterMarkTheme === "blue" && <img src={CheckCircle} alt="check circle" />}
                </div>
              </div>
              <div className="water_mark_card" onClick={() => setWaterMarkTheme("light")}>
                <div className="img_wrapper">
                  <img src={MadeInIdFlowLight} alt="MadeInIdFlowLight" />
                </div>
                <div className="theme_selection">
                  <div className="theme_name">Light Theme {waterMarkTheme === "light" && "(Active)"}</div>
                  {waterMarkTheme === "light" && <img src={CheckCircle} alt="check circle" />}
                </div>
              </div>
              <div className="water_mark_card" onClick={() => setWaterMarkTheme("dark")}>
                <div className="img_wrapper">
                  <img src={MadeInIdFlowDark} alt="MadeInIdFlowDark" />
                </div>
                <div className="theme_selection">
                  <div className="theme_name">Dark Theme {waterMarkTheme === "dark" && "(Active)"}</div>
                  {waterMarkTheme === "dark" && <img src={CheckCircle} alt="check circle" />}
                </div>
              </div>
            </div>
            <div className="water_mark_position">
              <label>Position of Watermark</label>
              <select
                onChange={(e) =>
                  setBrand({
                    ...brand,
                    waterMark: { ...brand.waterMark, position: e.target.value },
                  })
                }
              >
                {Array.isArray(positionOptions) && positionOptions?.map((position, i) => (
                  <option
                    value={position?.value}
                    key={i}
                    selected={position?.value === brand.waterMark?.position}
                  >
                    {position?.label}
                  </option>
                ))}
              </select>
            </div>
          </section>

          {/* Loader */}
          <section className="loader_animation-sec">
            <div className="context_wrapper">
              <h5 className="lang-title">Loader Animation</h5>
              <p className="desp">Choose a loader animation from our list or import your own animation.</p>
            </div>
            <div className="loader_category row">
              <div className="col-lg-6 left_sec">
                <div className="card_wrapper">
                  <div className="loader_card" onClick={() => setActiveLoader("one")}>
                    <div className="loader_div"></div>
                    <div className="loader_selection">
                      <div className="theme_name">Loader One {activeLoader === "one" && "(Active)"}</div>
                      {activeLoader === "one" && <img src={CheckCircle} alt="check circle" />}
                    </div>
                  </div>
                  <div className="loader_card" onClick={() => setActiveLoader("two")}>
                    <div className="loader_div"></div>
                    <div className="loader_selection">
                      <div className="theme_name">Loader Two {activeLoader === "two" && "(Active)"}</div>
                      {activeLoader === "two" && <img src={CheckCircle} alt="check circle" />}
                    </div>
                  </div>
                  <div className="loader_card" onClick={() => setActiveLoader("three")}>
                    <div className="loader_div"></div>
                    <div className="loader_selection">
                      <div className="theme_name">Loader Three {activeLoader === "three" && "(Active)"}</div>
                      {activeLoader === "three" && <img src={CheckCircle} alt="check circle" />}
                    </div>
                  </div>
                  <div className="loader_card" onClick={() => setActiveLoader("four")}>
                    <div className="loader_div"></div>
                    <div className="loader_selection">
                      <div className="theme_name">Loader Four {activeLoader === "four" && "(Active)"}</div>
                      {activeLoader === "four" && <img src={CheckCircle} alt="check circle" />}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 right_sec">
                <h6 className="upload_label">Upload Animation</h6>
                <div className="upload_loader">
                  <label className="upload_label">
                    <p className="label_text">
                      {uploadFileName ? uploadFileName : "Please upload or drag and drop your loader animation here"}
                    </p>
                    <input type="file" className="upload_input" accept="" onChange={(e) => handleAnimation(e)} />
                    <div className="btn_wrapper">
                      <CustomButton
                        title="Upload Animation"
                        isLeft={true}
                        icon={Upload_icon}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </section>

        </div>
      }
    </div>
  );
};

export default BrandSetting;
