import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AccountSetting from "../assets/AccountSetting.svg";
import BrandSetting from "../assets/BrandSetting.svg";
import PortalSetting from "../assets/PortalSetting.svg";

const Items = [
  {
    name: "Brand settings",
    icon: BrandSetting,
    link: "brand",
  },
  {
    name: "Portal settings",
    icon: PortalSetting,
    link: "portal",
  },
  // {
  //   name: "Account settings",
  //   icon: AccountSetting,
  //   link: "account",
  // },
];


const Leftbar = () => {
  return (
    <div className="setting-sidebar-wrapper">
      <div className="setting-sidebar">
        {Items.length !== 0 &&
          Items.map((pic, index) => (
            <div key={index}>
              <NavLink
                to={pic?.link}
                className={({ isActive, isPending }) =>
                  "sideBar-links " + (isPending ? "pending " : "") + (isActive ? "sidebar-active" : "")
                }
              >
                <img src={pic.icon} className="sidebar-icon" alt={pic.link} />
                <p className="setting_names">{pic.name}</p>
              </NavLink>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Leftbar;
