import React, { useState } from "react";
import LeftComponent from "../LeftComponent";
import FontFamilyComponent from "../fontFamilyComponent";
import FontSizeComponent from "../fontSizeComponent";
import FontWeightComponent from "../fontWeight";
import "./index.css";
import { useBrandSettings } from "../../../../WorkFlowBuilder/store/selector";
import MinusIcon from "../../../../assets/minus_icon";
import PlusIcon from "../../../../assets/plus_icon";
import CustomSelect from "../../../core/CustomSelect";

const translations = {
  H1: {
    title: "H1",
    description: "Lorem ipsum dolor sit amet consectetur."
  },
  H2:{
    title: "H2",
    description: "Lorem ipsum dolor sit amet consectetur."
  },
  H3:{
    title: "H3",
    description: "Lorem ipsum dolor sit amet consectetur."
  },
  subtitle1:{
    title: "Subtitle 1",
    description: "Subtitle 1",
  },
  subtitle2: {
    title: "Subtitle 2",
    description: "Subtitle 2"
  },
  subtitle3: {
    title: "Subtitle 3",
    description: "Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at."
  },
  body: {
    title: "Body",
    description: "Lorem ipsum dolor sit amet consectetur. Sed enim nunc tincidunt amet interdum consectetur elementum. Potenti magna mattis vitae praesent cum. Ac feugiat ipsum morbi massa consectetur sed risus tristique. Sed aenean dui diam semper in at. Egestas cum egestas magna bibendum turpis eget sed. Egestas elit eget nisi justo tincidunt.Id eu sit ut fringilla bibendum tristique etiam. ",
  }
}




const fontSizes = [
  {value: 100, label:100},
  {value: 200, label:200},
  {value: 300, label:300},
  {value: 400, label:400},
  {value: 500, label:500},
  {value: 600, label:600},
  {value: 700, label:700},
];
const TextEditor = () => {
  const [brand, setBrand] = useBrandSettings();
  const [fonts, setFonts] = [brand.fonts, fonts => setBrand({...brand, fonts: fonts})]

  const [selected, setSelected] = useState("H1");

  const ChangeFontSize = (type) => {
    let currentFont = {...fonts[selected]};
    if (type === "inc"){

      currentFont.size++;
    }else{
      currentFont.size--;
    }
    setFonts({...fonts, [selected]: currentFont})
  };

  const selectedFont = fonts[selected];

  const fontOptions = [
    { value:"Pretandard",label:"Pretandard"},
    { value:"Poppins",label:"Poppins"},
    {value:"Arial", label:"Arial"},
    {value:"Times New Roman", label:" Times New Roman"},
    {value:"Monospace", label:"Monospace"}
  ]


  const [typoFont, setTypoFont] = useState({
    primaryFont:"",
    secondaryFont:""
  })

  const fontFamilies = [
    {value: typoFont?.primaryFont, label:'Primary Font'},
    {value: typoFont?.secondaryFont , label:'Secondary Font'}
  ] 

  return (
    <div className="editor_component">
      <div className="editor_text">
        <h5 className="heading">Typography</h5>
        <p className="desp">
          Select the standard fonts for headings and paragraphs across your flow.
        </p>
      </div>
      <div className="font_selection">
        <div className="selection_wrapper">
          <CustomSelect label="Primary font" options={fontOptions} onChange={(e) => {
            setTypoFont({ ...typoFont, primaryFont: e.value });
          }} />
        </div>
        <div className="selection_wrapper">
          <CustomSelect label="Secondary font" options={fontOptions} onChange={(e) => {
            setTypoFont({ ...typoFont, secondaryFont: e.value });
          }}/>
        </div>

      </div>
      <div className="editor_box row">
        <div className="left_comp col-lg-2">
          <div className="">
            <div>
              {Object.keys(fonts).map((font, index) => {
                return (
                  <div
                    onClick={() => setSelected(font)}
                    key={index}
                    style={{
                      fontSize: fonts[font].size,
                      fontWeight: fonts[font].weight,
                      height: "52px",
                      width: "100%",
                      padding: "5px 16px",
                      marginBottom: "10px",
                      border: font === selected ? "1px solid #F7F8F9" : "",
                      borderRadius: font === selected ? "6px" : "",
                      backgroundColor: font === selected ? "#F7F8F9" : "white",
                      boxShadow: selected === font && "0px 1px 2px 0px rgba(31, 30, 36, 0.10)",
                      display:"flex",
                      alignItems:"center",
                      cursor:"pointer"
                    }}
                  >
                    {translations[font].title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="right_comp col-lg-10">
          <div className="top_comp">
            <div className="font_components">
              <div className="fontSize_Select">
                <div className="fontSize_comp1">
                  <p className="label_text">Font Size</p>
                  <div className="signs_box">
                    <MinusIcon onClick={() => ChangeFontSize("dec")}  />
                    <div className="both_border">{selectedFont.size}</div>
                    <PlusIcon onClick={() => ChangeFontSize("inc")} />
                  </div>
                </div>
              </div>
              <div className="fontFamily_Select">
                <CustomSelect
                  label="Font family"
                  options={fontFamilies}
                  onChange={e => {
                    setFonts({...fonts, [selected]: {...selectedFont, family: e.value}})
                  }}
                />
              </div>
              <div className="fontWeight_Select">
                <CustomSelect
                  label="Font weight"
                  options={fontSizes}
                  onChange={e => {
                    setFonts({...fonts, [selected]: {...selectedFont, weight: e.value}})
                  }}
                />
              </div>
            </div>
          </div>
          <div className="bottom_comp">
            <div className="inner_bottom position-relative">
              {
                Object.keys(fonts).map((font) =>
                  <div className="font_priview">
                    <h1 key={translations[font].title} style={{
                      fontWeight: fonts[font].weight,
                      fontSize: fonts[font].size,
                      fontFamily: fonts[font].family,
                      lineHeight: font.lineHeight && "48px",
                      marginBottom: "16px",
                      paddingLeft:"25px",
                      borderLeft: "4px solid transparent"
                    }} className={`${selected === font && "typoBefore"}`}>
                      {translations[font].description}
                    </h1>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
