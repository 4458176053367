import { create } from "zustand";
import useFlow from "./flowStore";
import { getWasm } from "../../utils/wasm";
import { generateId } from "../../utils/store";


export const useErrors = create((set, get) => ({
    nodes: {},
    errors: {},
    validate: async () => {
        let { get_errors_workflow } = await getWasm();

        let flow = useFlow.getState();

        flow._id = flow.id || generateId(10);

        let json_flow = JSON.stringify(flow);

        try {
            let { serdeErrors, flowErrors } = get_errors_workflow(json_flow);
            set({ nodes: serdeErrors, errors: flowErrors })
        } catch (e) {
            console.error(e);
            let { serde_error, msg } = JSON.parse(e);
            if (!!serde_error) {
                console.error("Found serde Error ", msg);
                return
            }
        }
    },
    validateNode: async (nodeId) => {
        console.log("Running Node Validations for ", nodeId);

        let node = useFlow.getState().nodes.find(node => node.id === nodeId);

        if (!node) {
            console.error("Node is not found to validate");
            return
        }
        let { validate_node } = await getWasm();
        node = JSON.stringify(node);
        try {
            const isFine = validate_node(node);
        } catch (e) {
            // console.error(e);

            let error = JSON.parse(e);

            let { serde_error, msg } = error;
            if (!!serde_error) {
                console.error("Found serde Error ", msg);
                return
            }
            let nodes = get().nodes;
            nodes[nodeId] = error;
            set({ nodes })

        }
    }
}));


export const useNodeErrors = nodeId => {
    return useErrors(errors => (errors.nodes|| [])[nodeId]);
}

export const useValidator = () => {
    return useErrors(errors => errors.validate)
}

export const useNodeValidator = nodeId =>{
    const nodeValidator = useErrors(errors => errors.validateNode)
    return async () => await nodeValidator(nodeId);
}

