import * as Separator from '@radix-ui/react-separator';
import "../index.css";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ProgressInput = () =>{
  return <>
    <p className="desc m-0"> <Skeleton width={100} height={15}/></p> 
    <div className="SliderMain">
       <Skeleton width={150} height={20}/>
    </div>
  </>
}
const Loader = () => {
  return (
    <> 
    <div className="g-main">
    <section className="g-setting-heading mx-0">
      <div>
          <Skeleton width={100} height={15}/>
          <Skeleton width={200} height={10}/>
      </div>
    </section>

    <div className="theme_box">
      <div style={{ display: "flex" }}>
        <div className="theme_text">
          <Skeleton width={100} height={15}/>
          <p className="desp">
           <Skeleton width={200} height={10}/>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            width: "70%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <p className="text">
              <Skeleton width={100} height={15}/>
            </p>

            <div className="light_theme">
              <Skeleton width={140} height={110}/>
              <div className="bottom_light_theme">
                <p className="active_lighttext m-0">
              <Skeleton width={100} height={15}/>
                </p>
              <Skeleton circle={true} width={20} height={20}/>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <p className="text">  
            <Skeleton width={100} height={15}/>
            </p>

            <div className="light_theme">
            <Skeleton width={140} height={110}/>
              <div className="bottom_light_theme">
                <p className="active_lighttext m-0"> 
                <Skeleton width={100} height={15}/></p>
                <Skeleton circle={true} width={20} height={20}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="logo_box">
      <div style={{ display: "flex" }}>
        <div className="theme_text">
          <p className="heading m-0">
          <Skeleton width={100} height={15}/>
          </p>
          <p className="desp">
          <Skeleton width={200} height={10}/>
          </p>
        </div>
        <div
          style={{
            display: "flex",
           width: "70%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <p className="text"> <Skeleton width={100} height={15}/></p>
            <p className="desp"> <Skeleton width={150} height={10}/></p>
            <div>
               <Skeleton width={200} height={100}/>
              <div style={{marginTop:"0.5rem"}}>
                <Skeleton width={150} height={25}/>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              margin: "20px",
            }}
          >
            <p className="text"><Skeleton width={100} height={15}/></p>
            <p className="desp"><Skeleton width={150} height={10}/></p>
            <div>
              <Skeleton width={200} height={100}/>
              <div style={{marginTop:"0.5rem"}}>
                <Skeleton width={150} height={25}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="color_box row gap-5 mx-0">
        <div className="col-lg-5 m-0" style={{padding: "20px"}}>
          <p className="heading m-0"><Skeleton width={100} height={15}/></p>
          <p className="desp">
          <Skeleton width={200} height={10}/>
          </p>
        </div>
        <div
          className="col-lg-6 m-0 w-50 ps-3"
        >
          <div className="heading1">
            <div className='row'>
                <p className="color__sub-heading"><Skeleton width={100} height={15}/></p> {/* // TODO: apply font family */}
                <ColorCode  />
                <br />

              <div className='row gap-3'>
                <div className="col-lg-6">
                <ProgressInput  />

                </div>
                <div className="col-lg-5 ps-1">
                  <ProgressInput  />
                </div>
              </div>
            </div>
            <Separator.Root
              className="SeparatorRoot"
              decorative
              orientation="horizontal"
              style={{ margin: '15px 0px' }}
            />
            <p className="color__sub-heading"><Skeleton width={100} height={15}/></p>
            <ColorCode/>
            <div className='row'>
              <div className='row gap-3'>
                <div className="col-lg-6">
                <ProgressInput  />
                </div>
                <div className="col-lg-5 ps-1">
                <ProgressInput  />
                <ProgressInput  />
                </div>
              </div>
            </div>
            <Separator.Root
              className="SeparatorRoot"
              decorative
              orientation="horizontal"
              style={{ margin: '15px 0px' }}
            />
            <p className="color__sub-heading"> <Skeleton width={100} height={15}/> </p> 
            <ColorCode/>
          </div>
        </div>
    </div>
    <TextEditor />
    </div>
    </>
     
  );
};

export default Loader;

const ColorCode = () => {
  return (
    <div className="colorGrid">
      {[0,1,2,3]?.map((index) => (
          <div className="colorpicker_main" key={index}>
            <div className="desp m-0"><Skeleton width={100} height={10}/></div>
              <span className="color_code"> <Skeleton width={110} height={20}/></span>
          </div>
      ))}
    </div>
  );
};

const TextEditor = () => {
  return (
    <div className="editor_component row mx-0">
      <div>
        <div className="theme_text">
          <p className="heading">
            <Skeleton width={100} height={15}/>
          </p>
          <p className="desp">
          <Skeleton width={200} height={10}/>
          </p>
        </div>
        <div className="editor_box">
          <div className="left_comp">
            <div className="div1">
              <div>
                {[0,1,2,3,4,5,6].map((font, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        height: "52px",
                        width: "90%",
                        paddingLeft: "10px",
                        marginBottom: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Skeleton width={100} height={15}/>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="right_comp">
            <div className="top_comp">
              <div className="font_components">
                <div className="fontSize_div">
                  <div className="fontSize_comp1 gap-3">
                    <p className="font_size m-0"> <Skeleton width={50} height={20}/></p>
                    <Skeleton width={120} height={30}/>
                  </div>
                </div>
                <div className="FontFamily_div">
                  <div className="FontFamily_comp1 gap-3">
                    <p className="font_size m-0"><Skeleton width={50} height={20}/></p>
                    <Skeleton width={120} height={30}/>
                  </div>
                </div>
                <div className="fontweight_div">
                  <div className="comp1 gap-3">
                    <p className="font_size m-0"><Skeleton width={50} height={20}/></p>
                    <Skeleton width={120} height={30}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom_comp">
              <div className="inner_bottom position-relative">
                {
                  [0,1,2,3,4].map((font) =>
                    <h1 key={font} style={{
                      lineHeight:  "48px",
                      marginBottom: "3px",
                      marginTop: "18px",
                      paddingLeft: "20px"
                    }} >
                     <Skeleton width={'100%'} height={20}/>
                    </h1>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
