import { commonRequestV1, commonRequestV1File } from "./common";

const path = (nodeId) =>{
  return nodeId ? `/nodes/${nodeId}` : `/nodes`;
}

export const getNodes = (
  limit = 10,
  skip = 0,
  search = "",
  sortOrder = "desc",
  sortBy = "created_at",
  filter = ""
) => {
  return commonRequestV1(
    "get",
    path(
      `?limit=${limit}&skip=${skip}&search=${search}&filter=${filter}&sortOrder=${sortOrder}&sortBy=${sortBy}`
    ),
    {}
  );
};

export const getNode = (nodeId) => {
  return commonRequestV1("get", path(nodeId), {});
};

export const createNode = (node) => {
  return commonRequestV1("post", path(""), node);
};

export const updateNode = (node) => {
  return commonRequestV1("put", path(node.id), node);
};

export const deleteNode = (nodeId) => {
  return commonRequestV1("delete", path(nodeId), {});
};

export const imageUpload = (imageName) => {
  return commonRequestV1File("post", `/files`, imageName);
};
