import ses from "./provider-logos/amazon ses.png";
import apns from "./provider-logos/apns.png";
import sns from "./provider-logos/aws-sns.png";
import discord from "./provider-logos/discord.png";
import expo from "./provider-logos/expo.png";
import fcm from "./provider-logos/fcm.png";
import infobip from "./provider-logos/infobip.png";
import mailersend from "./provider-logos/mailersend.png";
import mailgun from "./provider-logos/mailgun.png";
import mailjet from "./provider-logos/mailjet.png";
import mandrill from "./provider-logos/mandrill.png";
import netcore from "./provider-logos/netcore.png";
import nexmo from "./provider-logos/nexmo.png";
import outlook from "./provider-logos/outlook.png";
import postmark from "./provider-logos/postmark.png";
import resend from "./provider-logos/resend.png";
import sendgrid from "./provider-logos/sendgrid.png";
import sendinblue from "./provider-logos/sendinblue.png";
import slack from "./provider-logos/slack.png";
import sms77 from "./provider-logos/sms77.png";
import sparkpost from "./provider-logos/sparkpost.png";
import teams from "./provider-logos/teams.png";
import telynx from "./provider-logos/telynx.png";
import twilio from "./provider-logos/twilio.png";
import tick from "./provider-logos/icons8-tick.gif"

export const Ses = ses;
export const Apns = apns;
export const Sns = sns;
export const Discord = discord;
export const Expo = expo;
export const Fcm = fcm;
export const Infobip = infobip;
export const Mailersend = mailersend;
export const Mailgun = mailgun;
export const Mailjet = mailjet;
export const Mandrill = mandrill;
export const Netcore = netcore;
export const Nexmo = nexmo;
export const Outlook = outlook;
export const Postmark = postmark;
export const Resend = resend;
export const SendGrid = sendgrid;
export const Sendinblue = sendinblue;
export const Slack = slack;
export const Sms77 = sms77;
export const SparkPost = sparkpost;
export const Teams = teams;
export const Telnyx = telynx;
export const Twilio = twilio;
export const Tick = tick;
