
import MessageNode, { MessagePanel } from "../Message/Message";
import { parseInt } from "lodash";
import { BsQuestionCircleFill } from "react-icons/bs";
import { FallBack } from "../../Common";
import { NodeFooter } from "../../Common";
import Icons from "../../../../../assets/imageUrl";
import { validationIcon } from "../../../../../assets/imageUrl";
import { useState,useEffect } from "react";
import { useNodeData } from "../../../../store/selector";
const valids = ["Email", "Number", "String"];

const Validate = ({ validation, setValidation }) => {
  return (
    <>
      <div className="right_section_separate">
        <div >
          <label htmlFor="url" className="side_label">
            Validation
          </label>
          <div className="labelInputFull">

        <select
          className=" mt-2"
          onChange={(e) =>
            setValidation({ ...validation, type: e.target.value })
          }>
          <option>Select type</option>
          {valids.map((type) => {
            return <option selected={type === validation.type}>{type}</option>;
          })}
        </select>
          </div>
        </div>
        
      </div>

      {valids.indexOf(validation.type) !== -1 ? (
        <>
          <div className="right_section_separate">
            <FallBack
              data={validation.fallBack}
              update={(value) =>
                setValidation({ ...validation, fallBack: value })
              }
            />
          </div>

          <div className="right_section_separate">
            <div>
              <label htmlFor="url" className="la">
                Fails count
              </label>
            </div>
            <div className="labelInputFull">
              <input
                type="number"
                name=""
                id="url"
                value={validation.failsCount}
                className="right_section_input mt-2 form-control"
                onChange={(e) =>
                  setValidation({
                    ...validation,
                    failsCount: parseInt(e.target.value),
                  })
                }
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {validation.type === "String" ? (
        <div className="right_section_separate">
          <div >
            <label htmlFor="url" className="nodePanel-labels">
              Regex
            </label>
          </div>
          <div className="labelInputFull">
            <input
              type="text"
              name=""
              id="url"
              className="right_section_input mt-2 form-control"
              value={validation.regex}
              onChange={(e) =>
                setValidation({ ...validation, regex: e.target.value })
              }
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {validation.type === "Number" ? (
        <>
          <div className="right_section_separate mb-4">
            <div className="d-flex num_gap">
              <div className="">
                <div>
                  <label htmlFor="url" className="side_label">
                    Min length
                  </label>
                </div>
                <div>
                  <input
                    type="number"
                    name=""
                    id="url"
                    className="num_right_section mt-2 form-control"
                    value={validation.minLength}
                    onChange={(e) =>
                      setValidation({
                        ...validation,
                        minLength: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="">
                <div>
                  <label htmlFor="url" className="side_label">
                    Max length
                  </label>
                </div>
                <div>
                  <input
                    type="number"
                    name=""
                    id="url"
                    className="num_right_section mt-2 form-control"
                    value={validation.maxLength}
                    onChange={(e) =>
                      setValidation({
                        ...validation,
                        maxLength: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="d-flex num_gap">
              <div>
                <div>
                  <label htmlFor="url" className="side_label">
                    Min value
                  </label>
                </div>
                <div>
                  <input
                    type="number"
                    name=""
                    id="url"
                    className="num_right_section mt-2 form-control"
                    value={validation.minValue}
                    onChange={(e) =>
                      setValidation({
                        ...validation,
                        minValue: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <div>
                  <label htmlFor="url" className="side_label">
                    Max value
                  </label>
                </div>
                <div>
                  <input
                    type="number"
                    name=""
                    id="url"
                    className="num_right_section mt-2 form-control"
                    value={validation.maxValue}
                    onChange={(e) =>
                      setValidation({
                        ...validation,
                        maxValue: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const QuestionPanel = ({  id}) => {
  const [data, setData] = useNodeData(id);
  
  const updateValidation = (validation) => {
    setData({ ...data, validation: validation });
  };

  if (!data.validation) {
    updateValidation({});
  }

  return (
    <MessagePanel
      id={id}
      data={data}
      message={false}
      type={"Question"}
      children={
        <Validate
          validation={data.validation || []}
          setValidation={updateValidation}
        />
      }
    />
  );
};

export default function QuestionNode({ data, selected }) {

  const [accessSelected,setAccessSelected] = useState(selected);

  useEffect(()=>{
    setAccessSelected(selected)
  },[selected])
  const messageNode = (
    <MessageNode
      data={data}
      name="Question"
      selected={selected}
      icon={Icons.questionNodeLogo}
      includeFooter={false}
      accessSelected={accessSelected}
      style={{backgroundColor:"#e1ffe6",border: selected ?  `solid 2px${"#9ce8a9"}`:""}}
      children={
        <>
          {data.validation && (
            <div >
              
<p>
<img style={{marginRight:"5px"}} src={validationIcon} alt=""/>
              {data.validation.type ? (
                data.validation.type + " Validation"
              ) : (
                <></>
              )}
            </p>
            <NodeFooter/>
            </div>
            
          )}

          {/* {data.variable && <p>{data.variable}</p>} */}
        </>
      }></MessageNode>
  );

  return <>{messageNode}</>;
}
