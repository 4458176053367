import React, { useEffect, useState } from "react";
import { commonInputs, NodeHeader } from "../Common";
import { JsonEditor } from "jsoneditor-react";
import { Handle, Position} from "reactflow";
import SidePanelHeader from "../../../../Components/sidePanelHeader/SidePanelHeader";
import { useNodeData } from "../../../store/selector";
import { webHookColor } from "../../../../assets/imageUrl";
import * as Yup from "yup";

const nodeNameWebhook = "Webhook";

export const inputs = [
    ...commonInputs,
    {
        name: "Request Fields",
        value: "fields",
        defaultValue: {},
        type: "jsonInput",
        helpingText:'Please give a name to the field ',
        props:{
            required: true
        }
    },
    {
        name: "Response Fields",
        value: "responseFields",
        defaultValue: null,
        type: "jsonInput",
        helpingText:'Please give a name to the field ',
    }
];


export const WebhookPanel = ({ id, type = "Webhook", flowId }) => {
    const [data, setData] = useNodeData(id);
   
    const [tickTrue, setTickTrue] = useState(false)

    useEffect(() => {
        if (tickTrue) {
            setTimeout(() => setTickTrue(false), 1000);
        }
    }, [tickTrue])


    return (
        <div className="right_panel_workflow">
            <SidePanelHeader nodeName={nodeNameWebhook} />

         
        </div>
    );
};

export function Webhook({ id, data, selected }) {
    const [accessSelected, setAccessSelected] = useState(selected);

    useEffect(() => {
        setAccessSelected(selected)
    }, [selected])
    return (
        <div

            className={
                "App "

                // +
                // (data.isStartNode ? "start_node" : "") +
                // (!data.isStartNode && selected ? "selected_node" : "")

            }
        >
            <div
                style={{ backgroundColor: "#E8F4FF", border: selected ? `solid 2px${"#00AAFF"}` : "" }}
                className={`${"set_msgcard"} ${"commonHeader"}`}>
                <NodeHeader
                    name={nodeNameWebhook}
                    icon={webHookColor}
                    onDelete={data.delete}
                    accessSelected={accessSelected}
                    noHandle
                />

                <div className="webHookNodeStyle">
                    <div>
                                <p>{data.name || "Post"}</p>
                                {/* <p>
                                    {data.url.length > MAX_CHARS
                                        ? data.url.substring(0, MAX_CHARS) + "..."
                                        : data.url}
                                </p> */}
                        <Handle
                            type="source"
                            position={Position.Right}
                            className="commonHandleStyle"
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}