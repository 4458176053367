import { generateId } from "../../utils/store";
import { AddButton, DeleteButton } from "../CommonButtons";
import { Components } from "../NodePanel";
import Icons from "../../assets/imageUrl";
import {useEffect} from "react";



export const ListItems = ({
    id,
    value=[],
    name,
    setValue=() => {},
    children, // helpingText = "helpingText",
    addButton=true,
    deleteButton=true,
    type,
    errors
}) =>{
    const [items, setItems] = [value, setValue];


    const deleteItem = id =>{
        setItems(items.filter(item => item.id !== id))
    }

    const updateItemField = (id, field, value) =>{
        setItems(
            items.map(item =>{
                if (item.id == id){
                    item[field] = value
                }
                return item
            })
        )
    }

    const updateItem = (id, item) =>{
        setItems(items.map(oldItem => {
            if (oldItem.id === item.id){
                return item
            }

            return oldItem
        }))
    }

    const addItem = () =>{
        setItems([...items, {id: generateId(), 
        ...children.reduce((obj, child) => {
            obj[child.value] = child.defaultValue
            return obj
        }, {})
        }])
    }

    

    return <div>
        <ul className="commonBackGray SectionCondition mb-0">
        <label className="nodePanel-labels RightSubHeading">
                {name}
            </label>
            {errors&& errors.errors? errors.errors.map(error => <p className="errorRightPanel position-static">{error}, </p>): undefined}

            {
                (items || []).map((item, index) => {
                    return <>
                        <div className="RightListGroup">
                            {!!deleteButton && <DeleteButton
                                callFunction={() => deleteItem(item.id)}
                                comId={item}/>}
                            <Components
                                inputs={children}
                                data={item}
                                setData={item => updateItem(item.id, item)}
                                type={type}
                                id={id}
                                key={id}
                                errors={errors?.items && errors?.items[`${index}`]}
                            />
                        </div>
                    </>
                })
            }

                <div className="addConditions">
              {!!addButton && <AddButton
                callFunction={addItem}
                opName='Add'
                />}
            </div>
        </ul>

        
    </div>
}