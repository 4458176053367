import {
  Handle,
  Position,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
import { getMeasurements, NodeHeader, StartNode } from "../Common";
import { TbMathSymbols } from "react-icons/tb";
import { React,useState,useEffect } from "react";
import SidePanelHeader from "../../../../Components/sidePanelHeader/SidePanelHeader";
import { useNodeData } from "../../../store/selector";
import Icons from "../../../../assets/imageUrl";
import { AWSColor } from "../../../../assets/imageUrl";
import {TextInput} from "../../../components/inputs";
import {Select} from "../../../components/select";
export const AwsS3Panel = ({id, type = "AwsS3", vars = [] }) => {
    const [data, setData] = useNodeData(id);
  const options = [
      {name: "Select", value:"" },
    {name: "Download Object", value:"GetObject" },
    {name: "Upload Object", value:"PutObject" },
  ];
  return (
    <>
      <div className="">
        <SidePanelHeader nodeName="AwsS3" />
        <section className="sideInnerParent px-3 ptb-20">
          <div >
            <TextInput
            name={type + " Name"}
            type="text"
            value={data?.name || ""}
            maxLength={15}
            setValue={(value) => setData({ ...data, name: value })}
            placeholder={type + " Name"}
            helpingText="Please give a name to the AWS S3 field"
            />

          </div>

          <div>
            <TextInput
                name="Region"
                type="text"
                value={data.region || ""}
                setValue={(e) =>
                    setData({ ...data, region: e.target.value })
                }
                placeholder="Region"
                helpingText="Please give a name to the AWS S3 field"
            />

          </div>

          <div>
            <TextInput
                name="Bucket"
                type="text"
                value={data.bucket || ""}

                setValue={(value) =>
                    setData({ ...data, bucket: value })
                }
                placeholder="Bucket"
                helpingText="Please give a name to the AWS S3 field"
            />

          </div>
          <div>
            <TextInput
                name="AWS Access Key"
                type="text"
                value={data.accessKey || ""}

                setValue={(value) =>
                    setData({ ...data, accessKey: value })
                }
                placeholder="AWS Access Key"
                helpingText="Please give a name to the AWS S3 field"
            />
          </div>
          <div>
            <TextInput
                name="AWS Secret Key"
                type="text"
                value={data.secretKey || ""}
                setValue={(value) =>
                    setData({ ...data, secretKey: value })
                }
                placeholder="AWS Secret Key"
                helpingText="Please give a name to the AWS S3 field"
            />
          </div>
          <div>
            <Select
                name="Operation"
                helpingText = "Please give a name to the AWS S3 field"
                value={data?.operation?.type}
                setValue={(value) =>
                    setData({
                      ...data,
                      operation: { ...data.operation, type: value },
                    })
                }
                options={options}
            />
          </div>
          {data?.operation?.type == "PutObject" && (
            <>
              <div>
                <TextInput
                    name="Object Key"
                    type="text"
                    value={data?.operation?.objectKey || ""}
                    setValue={(value) =>
                        setData({
                          ...data,
                          operation: {
                            ...data.operation,
                            objectKey: value,
                          },
                        })
                    }
                    placeholder="Object Key"
                    helpingText="Please give a name to the AWS S3 field"
                />

              </div>
              <div>
                <TextInput
                    name="Input"
                    type="text"
                    value={data?.operation?.input || ""}
                    setValue={(value) =>
                        setData({
                          ...data,
                          operation: { ...data.operation, input:value },
                        })
                    }
                    placeholder="Input"
                    helpingText="Please give a name to the AWS S3 field"
                />

              </div>
            </>
          )}
          {data?.operation?.type == "GetObject" && (
            <>
              <div>
                <TextInput
                    name="Object Key"
                    type="text"
                    value={data?.operation?.objectKey || ""}
                    setValue={(value) =>
                        setData({
                          ...data,
                          operation: {
                            ...data.operation,
                            objectKey: value,
                          },
                        })
                    }
                    placeholder="Object Key"
                    helpingText="Please give a name to the AWS S3 field"
                />

              </div>

              <div>
                <TextInput
                    name="Output"
                    type="text"
                    value={data?.operation?.output || ""}
                    setValue={(value) =>
                        setData({
                          ...data,
                          operation: {
                            ...data.operation,
                            output: value,
                          },
                        })
                    }
                    placeholder="Output"
                    helpingText="Please give a name to the AWS S3 field"
                />

              </div>
            </>
          )}
        </section>
      </div>
    </>
  );
};

const AwsS3 = ({ id, data, selected }) => {
  const [accessSelected, setAccessSelected] = useState(selected);

  useEffect(() => {
    setAccessSelected(selected)
  }, [selected])
  return (
    <>
      <div
        style={{
          backgroundColor: "#ffecec",
          border: selected ? `solid 2px${"#FF9900"}` : "",
        }}
        className={"set_msgcard " + "commonHeader"}
      >
        <NodeHeader
          name={"Aws S3"}
          icon={AWSColor}
          onDelete={data.delete}
          isStartNode={data.isStartNode}
          accessSelected={accessSelected}
        />

        <div className="conditionNodeStyle">
          <div>
            <p>AwsS3 Node</p>
            <Handle
              id="yes"
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AwsS3;
