import React from "react";

const CreateIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 14 15"
      fill="#ffff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8.99799H8V14.998H6V8.99799H0V6.99799H6V0.997986H8V6.99799H14V8.99799Z"
        fill="#ffff"
      />
    </svg>
  );
};

export default CreateIcon;
