import React from 'react'

const minus_icon = ({ onClick}) => {
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={{ cursor: "pointer"}}>
<path d="M19 12.998H5V10.998H19V12.998Z" fill="#212429"/>
</svg>
  )
}

export default minus_icon