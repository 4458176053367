import { commonRequestV1 } from "./common";

const path = (workflowId) => "/workflows/" + workflowId;

export const getWorkflows = (
  limit = 10,
  skip = 0,
  search = "",
  sortOrder = "desc",
  sortBy = "created_at",
  filter = "",
  type=""
) => {

  if (type?.length > 0 ) { 
    return commonRequestV1(
      "get",
      path(
        `?limit=${limit}&skip=${skip}&search=${search}&filter=${filter}&sortOrder=${sortOrder}&sortBy=${sortBy}&type=${type}`
      ),
      {}
    );
  } else {
    return commonRequestV1(
      "get",
      path(
        `?limit=${limit}&skip=${skip}&search=${search}&filter=${filter}&sortOrder=${sortOrder}&sortBy=${sortBy}`
      ),
      {}
    );
  }
};

export const getWorkflow = (workflowId) => {
  return commonRequestV1("get", path(workflowId), {});
};

export const createWorkflow = (workflow) => {
  return commonRequestV1("post", path(""), workflow);
};

export const updateWorkflow = (workflow) => {
  return commonRequestV1("put", path(workflow._id), workflow);
};

export const deleteWorkflow = (workflowId) => {
  return commonRequestV1("delete", path(workflowId), {});
};

export const searchWorkflows = (search) => {
  return commonRequestV1("get", path(`?search=${search}`), {});
};

export const filterWorkflows = (filter) => {
  return commonRequestV1("get", path(`?filter=${filter}`), {});
};
