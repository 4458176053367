import React from "react";

export default function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.63742 1.48345C7.83769 1.28318 8.16239 1.28318 8.36266 1.48345L11.7815 4.90226C11.9817 5.10253 11.9817 5.42723 11.7815 5.62749C11.5812 5.82776 11.2565 5.82776 11.0562 5.62749L8.51286 3.08413V10.0512C8.51286 10.3344 8.28326 10.564 8.00004 10.564C7.71682 10.564 7.48722 10.3344 7.48722 10.0512V3.08413L4.94386 5.62749C4.74359 5.82776 4.41889 5.82776 4.21862 5.62749C4.01835 5.42723 4.01835 5.10253 4.21862 4.90226L7.63742 1.48345ZM1.84619 9.53838C2.12942 9.53838 2.35902 9.76798 2.35902 10.0512V12.7862C2.35902 13.0129 2.44906 13.2303 2.60935 13.3906C2.76964 13.5509 2.98704 13.6409 3.21372 13.6409H12.7864C13.013 13.6409 13.2304 13.5509 13.3907 13.3906C13.551 13.2303 13.6411 13.0129 13.6411 12.7862V10.0512C13.6411 9.76798 13.8707 9.53838 14.1539 9.53838C14.4371 9.53838 14.6667 9.76798 14.6667 10.0512V12.7862C14.6667 13.2849 14.4686 13.7632 14.116 14.1158C13.7633 14.4685 13.2851 14.6666 12.7864 14.6666H3.21372C2.71502 14.6666 2.23675 14.4685 1.88411 14.1158C1.53148 13.7632 1.33337 13.2849 1.33337 12.7862V10.0512C1.33337 9.76798 1.56297 9.53838 1.84619 9.53838Z"
        fill="white"
      />
    </svg>
  );
}
