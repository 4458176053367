import { parseInt } from "lodash";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { Handle, Position } from "reactflow";
import { useNodeData, useRemoveNode } from "../../../../store/selector";
import { NodeHeader, VariablePick } from "../../Common";

import SidePanelHeader from "../../../../../Components/sidePanelHeader/SidePanelHeader";
import Icons from "../../../../../assets/imageUrl";
import EditIcon from '../../../../../assets/node-icons/penModelImg.svg';
import TrashIcon from '../../../../../assets/trash14.svg';
import InfoIcon from '../../../../../assets/userScreenSvg/SystemInfo.svg';


// export const inputs = [
//   ...commonInputs,
//   {
//     name: "Inputs",
//     value: "inputs",
//     defaultValue: [],
//     type: "listItems",
//     props:{
//       deleteButton: false,
//       addButton: false,
//       children: [
//         {
//           name: "Label",
//           value: "name",
//           defaultValue: "",
//           type: "label",
//         },
//         {
//           name: "",
//           value: "value",
//           defaultValue: "",
//           type: "textInput",
//         },
//       ]
//     }
//   },
//   {
//     name: "Outputs",
//     value: "outputs",
//     defaultValue: [],
//     type: "listItems",
//     props:{
//       deleteButton: false,
//       addButton: false,
//       children: [
//         {
//           name: "Label",
//           value: "status",
//           defaultValue: "",
//           type: "label",
//           props:{
//             formatter: value => `Status:- ${value}`
//           }
//         },
//         {
//           name: "",
//           value: "outputs",
//           defaultValue: [],
//           type: "listItems",
//           props:{
//             deleteButton: false,
//             addButton: false,
//             children: [
//               {
//                 name: "Label",
//                 value: "name",
//                 defaultValue: "",
//                 type: "label",
//               },
//               {
//                 name: "",
//                 value: "value",
//                 defaultValue: "",
//                 type: "textInput",
//               },
//             ]
//           }
//         }
//       ]
//     }
//   }
// ]

export const ExecuteAPIPanel = ({ id, type, vars }) => {

  const [data, setData] = useNodeData(id);
  const navigate = useNavigate();
  const removeNode = useRemoveNode(id);

  let inputs = data.inputs || [];
  let outputs = data.outputs || [];

  const handleView = (nodeId) => { 
    if (nodeId) navigate(`/home/nodes/${nodeId}`)
  }

  const handleEdit = (nodeId) => { 
    if (nodeId) navigate(`/home/nodes/edit/${nodeId}`)
  }

  const handleDeleteNode = () => {
    removeNode();
    // ! call api to delete node
    
  }

  return (
    <div className="">
      <SidePanelHeader nodeName={data?.nodeName} />
      <section className="sideInnerParent px-3 ptb-20">
        {inputs.length > 0 ?
          <>
            <div>
              <div className="d-inline-flex gap-2 align-items-center justify-content-start ms-auto w-100 me-1">
                <label htmlFor="" className="nodePanel-labels classDarkHeader me-1">Inputs:</label>
                {/*<img src={EditIcon} alt="edit" onClick={() => handleEdit(data?.nodeId)} className="ms-auto cursor-pointer p-2 border border-dark rounded" />*/}
                {/*<img src={InfoIcon} alt="view" onClick={() => handleView(data?.nodeId)} className="align-self-stretch cursor-pointer p-2 border border-dark rounded" />*/}
                {/*<img src={TrashIcon} alt="del" onClick={() => handleDeleteNode(data?.nodeId)} className="align-self-stretch cursor-pointer p-2 border border-dark rounded" />*/}
              </div>
              {inputs.map((item, index) => (
                <div key={item.id}>
                  <label className="nodePanel-labels">{item.name}</label>
                  <VariablePick input={item.value} setInput={(value) => setData({
                    ...data,
                    inputs: inputs.map((i) => {
                      if (i.id === item.id) {
                        return {
                          ...i,
                          value: value
                        };
                      }
                      return i;
                    })
                  })} vars={vars} />
                </div>

              ))}
            </div>

          </>
          : <></>

          // (
          //   <>
          //     <div className="d-flex justify-content-center">
          //       <img src={nodata} alt="nodata" className="w-80p" />
          //     </div>
          //     <p className="noDataNode">No Inputs found</p>
          //   </>
          // )

        }
        {outputs.length > 0 ? (
          <div>
            <label htmlFor="" className="nodePanel-labels classDarkHeader">Outputs:</label>
            {
              outputs.map((output, index) => {

                return (
                  <ul className="commonBackGray" key={index}>

                    {output.outputs.length > 0 ? (
                      output.outputs.map((item) => (
                        <li key={item.id}>
                          <div>
                            <label htmlFor="">Status :- {output.status}</label>
                          </div>
                          <div>
                            <label className="nodePanel-labels mt-3" htmlFor="">{item.name}</label>
                            <div className="labelInputFull">
                              <input
                                type="text"
                                value={item.value}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    outputs: data.outputs.map((o) => {
                                      if (o.id === output.id) {
                                        o = {
                                          ...o,
                                          outputs: o.outputs.map((i) => {
                                            if (i.id === item.id) {
                                              return {
                                                ...i,
                                                value: e.target.value
                                              };
                                            }
                                            return i;
                                          })
                                        };
                                      }
                                      return o;
                                    })
                                  })
                                }
                              />
                            </div>

                          </div>

                        </li>
                      ))
                    ) : (
                      <>
                      </>
                    )}
                  </ul>
                );
              })
            }
          </div>

        ) : (
          <>
          </>
        )}

      </section>
      {/* <div className="flow-name right_section_separate">
        <div className="d-flex DataExNode">
          <img src={nodeImg} alt="" className="nodeImage" />
          <p className="flow-name-head InputsTitle m-0 p-0 NodeCustomTitle">
            {node?.name}
          </p>
        </div>

        <div className="d-flex justify-content-between">
          <h3 className="InputsTitle">
            <i class="fa-regular fa-circle-dot DotIcon"></i> Inputs
          </h3>
          <h3 className="InputsTitle">
            <i class="fa-regular fa-circle-dot DotIcon"></i> Variables
          </h3>
        </div>

       
        <h3 className="InputsTitle">Outputs : </h3>
        
        <ul className="noList">
          
        </ul>
      </div> */}
    </div>
  );
};

const ExecuteAPINode = ({ id, data, selected }) => {
  
  return (
    <div
      style={{ backgroundColor: "#ffecec", border: selected ? `solid 2px${"#FF9900"}` : "" }}
      className={`${"set_msgcard"} ${"commonHeader"}`}
    >
      <NodeHeader
        name={data?.nodeName}
        icon={Icons.conditionNodeLogo}
        selected
      // isStartNode={data.isStartNode}
      ></NodeHeader>

      <div className="conditionNodeStyle">

        {(data.outputs || []).map((output, index) => (
          <div>
            <p>{output.status}
            </p>
            <Handle
              id={output.id}
              type="source"
              position={Position.Right}
              className="commonHandleStyle"
              style={{
                background: colorCode(parseInt(output.status))
              }}
            />

          </div>

        ))}
      </div>

    </div>

  );
};

const colorCode = (statusCode) => {
  switch (Math.floor(statusCode / 100)) {
    case 2:
      return "green";
    case 3:
      return "orange";
    case 4:
      return "red";
    case 5:
      return "pink";
    default:
      return "";
  }
};

export default ExecuteAPINode;
