import React from "react";
import { Outlet } from "react-router-dom";
import { useFullScreen } from "../WorkFlowBuilder/hooks";
import Leftbar from "./Sidebar";
import "./index.css";
const SettingLayout = () => {
  useFullScreen(false)
  return (
    <div className="setting-dashboard">
      <div className="main-body">
        <Leftbar />
        <div className="setting_outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingLayout;
