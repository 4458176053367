import { Alignment, Direction, emptyProps, Node } from "../common";
import { useComponent, useDropAccept, useIsEditor } from "../../../src/stores/context";
import { parseInt } from "lodash";
import PageBuilderAssets from "../../../src/assets/pageBuilderAssets";
import {
    allType,
    Components,
    getItem,
    getPlay,
    registerComponent,
} from "../components";

export const type = "Container";

export const inputs = [
    {
        name: "Position Active",
        value: "positionmode",
        type: "toggle",
        defaultValue: false
    },
    {
        name: "Center Div",
        value: "centerdiv",
        type: "toggle",
        defaultValue: false
    },
    {
        name: "Position",
        value: "position",
        type: "select",
        required: true,
        defaultValue: "top",
        options: [
            {
                name: "Top",
                value: "top",
            },
            {
                name: "Bottom",
                value: "bottom",
            },
            {
                name: "Left",
                value: "left",
            },
           {
                name: "Right",
                value: "right",
            }
        ],
    },
    {
        name: "Position Height",
        value: "positionHeight",
        type: "progressInput",
        min: -100,
        max: 100,
        defaultValue: 0,
        required: true,
    },
    {
        name: "Width",
        value: "width",
        type: "numberInput",
        required: true,
    },
    {
        name: "Height",
        value: "height",
        type: "progressInput",
        default:0,
        required: true,
    },
    {
        name: "Margin Top",
        value: "margintop",
        type: "progressInput",
        min: -100,
        max: 100,
        default: 0,
        required: true,
    },
    {
        name: "Margin Bottom",
        value: "marginbottom",
        type: "progressInput",
        min: -100,
        max: 100,
        default: 0,
        required: true,
    },
    {
        name: "Margin Left",
        value: "marginleft",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Margin Right",
        value: "marginright",
        type: "progressInput",
        default: 0,
        required: true,
    },
    {
        name: "Horizonal Padding",
        value: "hPadding",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Vertical Padding",
        value: "vPadding",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Border Radius Top Left",
        value: "radius_topleft",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Border Radius Top Right",
        value: "radius_topright",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Border Radius Bottom Left",
        value: "radius_bottomleft",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Border Radius Bottom Right",
        value: "radius_bottomright",
        type: "progressInput",
        defaultValue: 0,
        required: true,
    },
    {
        name: "Box Shadow Horizontal Length",
        value: "boxShadow_horizontal_length",
        type: "progressInput",
        min: -100,
        max: 100,
        defaultValue: 0,
        required: true,
    },
    {
        name: "Box Shadow Vertical Length",
        value: "boxShadow_vertical_length",
        type: "progressInput",
        min: -100,
        max: 100,
        defaultValue: 0,
        required: true,
    },
    {
        name: "Box Shadow Blur Radius",
        value: "boxShadow_blur_radius",
        type: "progressInput",
        min: -100,
        max: 100,
        defaultValue: 0,
        required: true,
    },
    {
        name: "Box Shadow Spread Radius",
        value: "boxShadow_spread_radius",
        type: "progressInput",
        min: -100,
        max: 100,
        defaultValue: 0,
        required: true,
    },
    {
        name: "Box Shadow Color",
        value: "boxShadow_color",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
   
    {
        name: "Background Color",
        value: "bgcolor",
        type: "colorInput",
        required: true,
        autoFocus: false,
    },
   
    Alignment,
    Direction,
];

export const Container = {
    name: "Container",
    type,
    props: emptyProps,
};

export const ContainerNode = () => {
    return (
        <Node type={type}>
            <div className="page-side-node br-right">
                <img src={PageBuilderAssets?.containerIcon} className="node-img" />
                <p className="node-name mb-2">Container</p>
            </div>
        </Node>
    );
};

export const ContainerInner = ({
    id,
    width = "",
    height = "",
    centerdiv=false,
    positionmode = false,
    position='top',
    positionHeight=0,
    alignment = "center",
    direction = "row",
    children,
    bgcolor,
    components,
    radius = 0,
    hPadding = '0',
    vPadding = '5',
    margintop=0,
    marginbottom=0,
    marginleft=0,
    marginright=0,
    boxShadow_horizontal_length=0,
    boxShadow_vertical_length=0,
    boxShadow_blur_radius=0,
    boxShadow_spread_radius=0,
    boxShadow_color='',
    radius_topleft=1,
    radius_topright=1,
    radius_bottomleft=1,
    radius_bottomright=1,
    

}) => {

    let isMain = id === "main";
    const editor = useIsEditor();
    
    return (
        <>
         
            <section
                style={{
                    // width: `${parseInt(width)}%`,
                    height: height === 0 ? "auto" :`${parseInt(height)}vh`,
                    border: editor ? "1px dotted #272626" : undefined,
                    padding: `${vPadding}px ${hPadding}px`,
                    margin: `${margintop}px ${marginright}px ${marginbottom}px ${marginleft}px`,
                    borderRadius: `${radius_topleft}px ${radius_topright}px ${radius_bottomleft}px ${radius_bottomright}px`,
                    boxShadow: `${boxShadow_horizontal_length}px ${boxShadow_vertical_length}px ${boxShadow_blur_radius}px ${boxShadow_spread_radius}px ${boxShadow_color}`,
                    background: bgcolor,
                    ...(height >= 10 ? { overflowY:'scroll'} : {}),
                    // overflowY:'scroll',
                    // margin: "0 auto",
                    display: "flex",
                    color:'red',
                    ...(centerdiv ? { position: 'absolute' } : {}),
                    ...(centerdiv ? { transform: 'translate(-50%, -50%)' } : {}),
                    ...(centerdiv ? { top: '50%' } : {}),
                    ...(centerdiv ? { left: '50%' } : {}),
                    // transform: centerdiv === true ? 'translate(-50%, -90%)' : '',
                    ...(positionmode ? { position: 'absolute' } : {}),
                    ...(positionmode ? { left: '0px' } : {}),
                    ...(positionmode ? { [position]:`${positionHeight}%`} : {}),
                    zIndex: +9,
                    // gap: '0.5rem',
                    gridGap: '0.5rem',
                    alignItems: 'center',
                    // gridAutoFlow: direction,
                    justifyContent: alignment,
                    flexDirection: direction,
                    width:'100%',
                }} className="PBpageContainer"
            >
                <Components components={components} id={id} />
                {children}
            </section>
            
        </>
    );
};

export const ContainerPlayEditor = (props) => {
    const { components } = useComponent(props.id);

    const { drop, isOver } = useDropAccept(props.id, allType(), getItem);

    return (
        <div ref={drop} style={{ background: isOver ? "green" : "" }}>
            <ContainerInner {...props} components={components} />
        </div>
    );
};

export const ContainerPlay = props => {
    const editor = useIsEditor();

    let Tag = editor ? ContainerPlayEditor : ContainerInner;

    return <Tag  {...props} />
}

registerComponent(type, {
    item: Container,
    play: ContainerPlay,
    node: ContainerNode,
    inputs,
    category: "Basic",
});