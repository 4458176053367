import React, { useMemo,useEffect,useState } from "react";
import MessageNode, { MessagePanel } from "../Message/Message";
import { Plus, getMeasurements, FallBack } from "../../Common";
import { generateId } from "../../../../../utils/store";
import { Handle, Position, useStoreApi } from "reactflow";
import { BsMenuButtonWideFill } from "react-icons/bs";
import { BsPlusLg } from "react-icons/bs";
import { FiMinus } from "react-icons/fi";
import { parseInt } from "lodash";
import Icons from "../../../../../assets/imageUrl";
import { useNodeData } from "../../../../store/selector";
import { AddButton,DeleteButton } from "../../../../CommonButtons";
const Buttons = ({ buttons, setButtons }) => {
  let totalButtons = buttons.length;

  return (
    <div>
      <ul className="commonBackGray">
            {buttons.map((button,i) => {
              return (
                <li key={button.id}>
                <div className="commonDeleteSection">
       <label >
          Button {i+1}
         </label>
      <DeleteButton callFunction={(button)=>{
        setButtons(buttons.filter((b) => b.id !== button.id))
      }} comId={button}/>
       </div>
       <div className="labelInputFull mt-3">
       <input
                      type="text"
                      name={button.text}
                      placeholder="Enter Text"
                      value={button.text}
                      maxLength={20}
                      onChange={(e) => {
                        setButtons(
                          buttons.map((b) => {
                            if (b.id === button.id) {
                              return {
                                ...b,
                                text: e.target.value,
                              };
                            }
                            return b;
                          })
                        );
                      }}
                      className="right_section_input nodePanel-inputs"
                    />
       </div>
                    
        
                </li>
              );
            })}
          </ul>
    
      <AddButton
                callFunction={()=>setButtons([...buttons, { id: generateId(), text: "" }])}
                opName='Add Button'
                />
         
          {/* <div hidden={buttons.length > 2} className=" ">
            {
              <BsPlusLg
                onClick={() =>
                  
                }
              />
            }
          </div> */}
       
       
          

    </div>
  );
};

export function InteractiveButtonsPanel({ id }) {
  const [data, setData] = useNodeData(id);
  

  const buttons = data.buttons || [];

  const setButtons = (buttons) => {
    setData({ ...data, buttons: buttons });
  };

  if (typeof buttons === "undefined") {
    setButtons([]);
  }
  return (
    <>
      <MessagePanel
        id={id}
        name="Interactive Buttons"
        data={data}
        type={"Buttons"}
        includeFooter={false}
        includeVariable={false}>
        <Buttons buttons={buttons} setButtons={setButtons} />
        <div className="right_section_separate">
          <FallBack
            data={data.fallBack}
            update={(value) => setData({ ...data, fallBack: value })}
          />
        </div>
      </MessagePanel>
    </>
  );
}

const headerHeight = 60;

export default function InteractiveButtons({ id, data, selected }) {
  const store = useStoreApi();

  const buttons = data.buttons || [];

  const [, height] = getMeasurements(id, store);

  let gap = (height - headerHeight) / buttons.length;

  const handlers = useMemo(
    () =>
      buttons.map((button, index) => {
        return (
          <Handle
            id={button.id}
            type="source"
            position={Position.Right}
            className="commonHandleStyle"
          />
        );
      }),
    [buttons]
  );
  const [accessSelected,setAccessSelected] = useState(selected);

  useEffect(()=>{
    setAccessSelected(selected)
  },[selected])
  return (
    <>
      <div>
        <MessageNode
          name="Buttons"
          data={data}
          selected={selected}
          icon={Icons.buttonNodeLogo}
          accessSelected={accessSelected}
          style={{backgroundColor:"#fff3e6",border: selected ?  `solid 2px ${"#ffb66a"}`:""}}
          children={
            buttons.length !== 0 && (
              <>
                {buttons.map((b) => {
                  return <div className="interactiveButtons">
                                       <button >{b.text}</button>
                    {handlers}
                  </div>
                })}
              </>
            )
          }
          includeFooter={false}></MessageNode>
      </div>
      
    </>
  );
}
