import { create } from "zustand";
import {defaultPage} from "../../default";

import _ from "lodash";
import { useParams } from "react-router-dom";
import { removeFunctions } from "./util";

export const usePage = create((set, get) =>({
  ...defaultPage(),

  setToDefault: () => set({ ...defaultPage() }),
  setPage: page => set({...page}),
  getPage: () => removeFunctions(_.cloneDeep(get())),

  isDefaultPage: () => {
    let thisPage = get().getPage();
    thisPage.id = undefined;
    let defaultPageClone = defaultPage();
    defaultPageClone.id = undefined;
    console.log(thisPage, defaultPageClone, _.isEqual(thisPage, defaultPageClone));
    return _.isEqual(thisPage, defaultPageClone)
  }

}));


export const usePageId = () =>{
  const params = useParams();
  return params.pageId;
}

export const useGetPage = () =>{
  return usePage(page => page.getPage)
}
