import { BASE_URL, commonRequest, commonRequestImageFile } from "./common";

const path = (fileId) => "/files/" + fileId
export const fullPath = (fileId) => BASE_URL + "v1" + path(fileId)

export const getFiles = (onSuccess) => {
    commonRequest('get', path(""), {}, onSuccess)
}


export const getFile = (fileId, onSuccess) => {
    commonRequest('get', path(fileId), {}, onSuccess)
};


export const createFile = (file, onSuccess) =>{
    commonRequestImageFile('post', path(""), file, onSuccess)
}

export const updateFile = (file, onSuccess) =>{
    commonRequest('post', path(file.id), file, onSuccess)
}


export const deleteFile = (fileId, onSuccess) =>{
    commonRequest('delete', path(fileId), {}, onSuccess)
}